import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = "/api/v1/balances";

type Payload = components["schemas"]["Balance"];
type Response = components["schemas"]["Balance"];
const postBalances = async (payload: Payload) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function useBalancesMutation(
  options: UseMutationOptions<any, AxiosError, any> = {}
) {
  return useMutation<Response, AxiosError, Payload>(
    postBalances,
    options
  );
}
