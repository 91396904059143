import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import { formValidateValues } from "constans/Constans";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

type inputProps = {
  name: string;
};

function InputSelectValidateValue({ name }: inputProps) {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <div data-tip={value !== "" ? value : undefined}>
          <Select
            size="small"
            sx={{ width: "100px" }}
            className="edit-form-status"
            value={value ?? ""}
            onChange={onChange}
          >
            <MenuItem value={""}>{t("None")}</MenuItem>
            {map(formValidateValues, (item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    />
  );
}

export default InputSelectValidateValue;
