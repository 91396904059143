import { PaletteMode } from "@mui/material";
import { ContentWidth } from "types/typesTheme";

type ThemeConfig = {
  mode: PaletteMode;
  templateName: string;
  routingLoader: boolean;
  disableRipple: boolean;
  navigationSize: string;
  menuTextTruncate: boolean;
  contentWidth: ContentWidth;
  responsiveFontSizes: boolean;
};

const themeConfig: ThemeConfig = {
  templateName: "Stowarzyszenie Inwestorów Indywidualnych",
  mode: "light",
  contentWidth: "boxed",
  routingLoader: true,
  menuTextTruncate: true,
  navigationSize: "280px",
  responsiveFontSizes: true,
  disableRipple: false,
};

export default themeConfig;
