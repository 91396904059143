import React from "react";
import { FormControlLabel, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import {
  JunctionProfilesType,
  TableCellCustomLong,
  TableCellCustomShort,
  TableCellCustomTitle,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";
import { Controller } from "react-hook-form";
import { map } from "lodash";
import {
  formattedDate,
  formatValueDateTimeOther,
  Status,
} from "helpers/Global";
import useGetCrmsQuery from "hooks/useGetCrmsQuery";
import CheckboxSetAllItems from "components/PageJunctionProfiles/CheckboxSetAllItems";
import CheckboxSetSingleItem from "components/PageJunctionProfiles/CheckboxSetSingleItem";
import Checkbox from "@mui/material/Checkbox";

function JunctionProfilesChangeHistoryDetails({
  control,
  duplicateUserData,
  mainUserData,
  resetAllGroupCheckboxes,
  setResetAllGroupCheckboxes,
  isLoading,
}: JunctionProfilesType) {
  const { t } = useTranslation();

  const {
    data: changeHistoryMainUser,
    isLoading: changeHistoryMainUserLoading,
  } = useGetCrmsQuery({
    query: {
      toUser: mainUserData?.id?.toString(),
      type: "30",
      "status[]": [Status.Status_0, Status.Status_1, Status.Status_3],
      "order[createdAt]": "desc",
    },
  });
  const {
    data: changeHistoryDuplicateUser,
    isLoading: changeHistoryDuplicateUserLoading,
  } = useGetCrmsQuery({
    query: {
      toUser: duplicateUserData?.id?.toString(),
      type: "30",
      "status[]": [Status.Status_0, Status.Status_1, Status.Status_3],
      "order[createdAt]": "desc",
    },
  });

  isLoading = changeHistoryDuplicateUserLoading || changeHistoryMainUserLoading;
  return (
    <Controller
      control={control}
      name="formHistory"
      render={({ field: { value, onChange } }) => (
        <TableBody>
          <TableRow sx={{ position: "relative" }}>
            <TableCellCustomTitle>
              {t("Change History")}
              {(changeHistoryDuplicateUser?.["hydra:totalItems"] ?? 0 > 0) && (
                <CheckboxSetAllItems
                  resetAllGroupCheckboxes={resetAllGroupCheckboxes}
                  setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
                  value={changeHistoryDuplicateUser?.["hydra:member"]}
                  onChange={onChange}
                />
              )}
            </TableCellCustomTitle>
          </TableRow>
          {map(changeHistoryMainUser?.["hydra:member"], (item) => {
            return (
              <TableRow key={item.id}>
                <TableCellCustomLong />
                <TableCellCustomShort />
                <TableCellCustomLong>
                  <p>
                    {item.typeCrm} {item.content}
                  </p>
                  <p>
                    {formattedDate(item.createdAt, formatValueDateTimeOther)}{" "}
                    {item.author}
                  </p>
                </TableCellCustomLong>
              </TableRow>
            );
          })}

          {map(changeHistoryDuplicateUser?.["hydra:member"], (item, index) => {
            return (
              <CheckboxSetSingleItem
                key={index}
                item={item}
                value={value}
                onChange={onChange}
              />
            );
          })}
          <TableRow>
            <TableCellCustomLong />
            <TableCellCustomShort>
              {t("Add In History Entry")}
            </TableCellCustomShort>
            <TableCellCustomLong>
              <Controller
                control={control}
                name="createCrm"
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    label={t("Profile Was Merged With Profile A UID", {
                      id: duplicateUserData?.id,
                    })}
                    control={
                      <Checkbox
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                      />
                    }
                  />
                )}
              />
            </TableCellCustomLong>
          </TableRow>
        </TableBody>
      )}
    />
  );
}

export default JunctionProfilesChangeHistoryDetails;
