import React from "react";
import { FormControlLabel, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import {
  JunctionProfilesType,
  TableCellCustomLong,
  TableCellCustomShort,
  TableCellCustomTitle,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";
import useGetTransactionsQuery from "hooks/useGetTransactionsQuery";
import { Controller } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import { concat, filter, includes, map } from "lodash";
import { formattedDate, formatValueDateTimeOther } from "helpers/Global";
import CheckboxSetAllItems from "components/PageJunctionProfiles/CheckboxSetAllItems";

function JunctionProfilesTransactionsDetails({
  control,
  duplicateUserData,
  mainUserData,
  isLoading,
  resetAllGroupCheckboxes,
  setResetAllGroupCheckboxes,
}: JunctionProfilesType) {
  const { t } = useTranslation();
  const {
    data: duplicateUserTransactions,
    isLoading: duplicateUserTransactionsLoading,
  } = useGetTransactionsQuery(duplicateUserData?.id, {
    query: {
      "order[verificationDate]": "desc",
    },
  });
  const { data: mainUserTransactions, isLoading: mainUserTransactionsLoading } =
    useGetTransactionsQuery(mainUserData?.id, {
      query: {
        "order[verificationDate]": "desc",
      },
    });

  isLoading = duplicateUserTransactionsLoading || mainUserTransactionsLoading;

  return (
    <Controller
      control={control}
      name="transactions"
      render={({ field: { value, onChange } }) => (
        <TableBody>
          <TableRow sx={{ position: "relative" }}>
            <TableCellCustomTitle>
              {t("Transactions")}
              {(duplicateUserTransactions?.["hydra:totalItems"] ?? 0 > 0) && (
                <CheckboxSetAllItems
                  resetAllGroupCheckboxes={resetAllGroupCheckboxes}
                  setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
                  value={duplicateUserTransactions?.["hydra:member"]}
                  onChange={onChange}
                />
              )}
            </TableCellCustomTitle>
          </TableRow>
          {map(mainUserTransactions?.["hydra:member"], (item) => {
            return (
              <TableRow key={item.id}>
                <TableCellCustomLong />
                <TableCellCustomShort />
                <TableCellCustomLong>
                  <p>
                    {item.description} {item.amountInBaseUnit}zł
                  </p>
                  <p>
                    {formattedDate(
                      item.verificationDate,
                      formatValueDateTimeOther
                    )}{" "}
                    {item.userEnteredByFullName}
                  </p>
                  <p>{item.comment}</p>
                </TableCellCustomLong>
              </TableRow>
            );
          })}
          {map(duplicateUserTransactions?.["hydra:member"], (item) => {
            return (
              <TableRow key={item.id}>
                <TableCellCustomLong sx={{ width: "40%" }}>
                  <p>
                    {item.description} {item.amountInBaseUnit}zł
                  </p>
                  <p>
                    {formattedDate(
                      item.verificationDate,
                      formatValueDateTimeOther
                    )}{" "}
                    {item.userEnteredByFullName}
                  </p>
                  <p>{item.comment}</p>
                </TableCellCustomLong>
                <TableCellCustomShort>
                  <p>{">> >> >>"}</p>
                </TableCellCustomShort>
                <TableCellCustomLong>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={item["@id"]}
                        checked={includes(value, item["@id"])}
                        onChange={() => {
                          onChange(
                            includes(value, item["@id"])
                              ? filter(value, (val) => val !== item["@id"])
                              : concat(value, item["@id"])
                          );
                        }}
                      />
                    }
                    label={t("Get From Left")}
                  />
                </TableCellCustomLong>
              </TableRow>
            );
          })}
        </TableBody>
      )}
    />
  );
}

export default JunctionProfilesTransactionsDetails;
