import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { FormInputs } from "pages/Users/Users";
import { useTheme } from "@mui/material/styles";

type Props = { control: Control<FormInputs> };

function Search({ control }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        display: onlySmallScreen ? "flex" : "unset",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <FormControl sx={{ width: 200 }}>
        <Controller
          control={control}
          name="searchValue"
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              onChange={onChange}
              name="searchAll"
              type="text"
              label={t("Search")}
            />
          )}
        />
      </FormControl>
      <Typography className="users-checkbox-text">{t("In")}</Typography>
      <FormControl sx={{ width: 150 }}>
        <Controller
          control={control}
          name="userParameters"
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              onChange={onChange}
              sx={{ width: 150, height: "56px" }}
              id="users-filters-select"
            >
              <MenuItem value="searchAll">{t("Everyone")}</MenuItem>
              <MenuItem value="name">{t("Full Name")}</MenuItem>
              <MenuItem value="email">{t("Email")}</MenuItem>
              <MenuItem value="city">{t("City")}</MenuItem>
              <MenuItem value="street">{t("Street")}</MenuItem>
              <MenuItem value="zipCode">{t("Zip Code")}</MenuItem>
              <MenuItem value="phone">{t("Phone")}</MenuItem>
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
}

export default Search;
