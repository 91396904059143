import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserInformationMutation from "hooks/useUserInformationMutation";
import { Controller, useForm, useWatch } from "react-hook-form";
import { operations } from "schema/schema";
import { Loader } from "components/Loader/Loader";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { useTheme } from "@mui/material/styles";
import useGetCountyQuery from "hooks/useGetCountyQuery";
import useGetVoivodeshipQuery from "hooks/useGetVoivodeshipQuery";
import useGetCountriesQuery from "hooks/useGetCountriesQuery";
import { map, orderBy } from "lodash";
import { Patterns } from "helpers/Global";
import ZipCodeFieldValidate from "components/ValidateFields/ZipCodeFieldValidate";
import CustomButton from "components/Buttons/CustomButton";

interface FormInputs {
  street: string;
  zipCode: string;
  city: string;
  contactCountryCode: string;
}
interface MainAddressTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
  refetch: () => void;
}

function MainAddress({ userInformationData, refetch }: MainAddressTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isEdit, setIsEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isZipCode, setZipCode] = useState(false);
  const { data: countriesList } = useGetCountriesQuery();
  const {
    mutate: changeAddressDetails,
    isLoading: changeContactDetailsLoading,
  } = useUserInformationMutation();
  const formParams = {
    street: userInformationData?.street,
    zipCode: userInformationData?.zipCode,
    city: userInformationData?.city,
    contactCountryCode: userInformationData?.contactCountryCode,
  };

  const {
    control: MainAddressControl,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FormInputs>({
    defaultValues: formParams,
  });
  const { zipCode, contactCountryCode, street, city } = useWatch({
    control: MainAddressControl,
  });
  const { data: county, refetch: refetchCounty } = useGetCountyQuery(
    {
      query: {
        ["districts.zipCodes.code"]: zipCode,
      },
    },
    {
      enabled: isZipCode,
      onSettled: () => setZipCode(false),
    }
  );
  const countyValue =
    county?.["hydra:member"][0] &&
    county?.["hydra:member"][0].name !== undefined
      ? county?.["hydra:member"][0].name
      : "-";
  const { data: voivodeship, refetch: refetchVoivodeship } =
    useGetVoivodeshipQuery(
      {
        query: {
          ["counties.districts.zipCodes.code"]: zipCode,
        },
      },
      {
        enabled: isZipCode,
        onSettled: () => setZipCode(false),
      }
    );
  const voivodeshipValue =
    voivodeship?.["hydra:member"][0] &&
    voivodeship?.["hydra:member"][0].name !== undefined
      ? voivodeship?.["hydra:member"][0].name
      : "-";

  const onSubmit = ({
    street,
    zipCode,
    city,
    contactCountryCode,
  }: FormInputs) => {
    changeAddressDetails(
      {
        payload: {
          street: street.trim(),
          zipCode: zipCode.replace(/\s+/g, ""),
          city: city.trim(),
          contactCountryCode: contactCountryCode.replace(/\s+/g, ""),
        },
        userId: userInformationData?.id,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setIsEdit(false);
          refetch();
          refetchCounty();
          refetchVoivodeship();
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
          setIsEdit(false);
          reset();
        },
      }
    );
  };

  useEffect(() => {
    reset(formParams);
    if (userInformationData?.zipCode) setZipCode(true);
  }, [userInformationData]);
  return (
    <div>
      {changeContactDetailsLoading && <Loader />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: onlySmallScreen ? "flex" : "inherit",
          flexDirection: "column",
        }}
      >
        <FormControl sx={{ width: 200, m: 2 }}>
          <Controller
            control={MainAddressControl}
            name="street"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                variant="standard"
                type="text"
                label={t("Street")}
                error={/^\d/.test(street || "") && isEdit}
                helperText={
                  /^\d/.test(street || "") && isEdit ? t("Invalid Value") : ""
                }
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  maxLength: 30,
                  pattern: Patterns.numericTextValues,
                }}
                InputProps={{
                  disabled: !isEdit,
                }}
              />
            )}
          />
        </FormControl>
        <FormControl sx={{ width: onlySmallScreen ? 200 : 100, m: 2 }}>
          <ZipCodeFieldValidate
            name="zipCode"
            label={t("Zip Code")}
            control={MainAddressControl}
            countryCode={contactCountryCode}
            editedValue={isEdit}
            variant="standard"
            isShrink
          />
        </FormControl>
        <FormControl sx={{ width: onlySmallScreen ? 200 : 150, m: 2 }}>
          <Controller
            control={MainAddressControl}
            name="city"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                variant="standard"
                type="text"
                label={t("City")}
                InputLabelProps={{ shrink: true }}
                error={/^\d/.test(city || "") && isEdit}
                helperText={
                  /^\d/.test(city || "") && isEdit ? t("Invalid Value") : ""
                }
                inputProps={{
                  maxLength: 20,
                }}
                InputProps={{
                  disabled: !isEdit,
                }}
              />
            )}
          />
        </FormControl>
        {(contactCountryCode === "000" || contactCountryCode === "616") && (
          <>
            <TextField
              value={countyValue}
              variant="standard"
              type="string"
              sx={{ width: 200, m: 2 }}
              label={t("County")}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true, disableUnderline: true }}
            />
            <TextField
              value={voivodeshipValue}
              variant="standard"
              type="string"
              sx={{ width: 200, m: 2 }}
              label={t("Voivodeship")}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true, disableUnderline: true }}
            />
          </>
        )}
        <FormControl
          sx={{ width: "200px", mx: 2, my: onlySmallScreen ? 4 : "unset" }}
        >
          <InputLabel id="departments-multiple-checkbox-label">
            {t("Country")}
          </InputLabel>
          <Controller
            control={MainAddressControl}
            name="contactCountryCode"
            render={({ field: { value, onChange } }) => (
              <Select
                value={value || ""}
                onChange={onChange}
                label={t("Country")}
                sx={{ width: 200 }}
                disabled={!isEdit}
                id="address-contact-country"
              >
                {map(
                  orderBy(countriesList, "translations.pol.common"),
                  (country: {
                    translations: { pol: { common: string } };
                    ccn3: string;
                  }) => (
                    <MenuItem
                      sx={{ whiteSpace: "initial", maxWidth: 300 }}
                      key={country.ccn3}
                      value={country.ccn3}
                    >
                      {country.translations.pol.common}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </FormControl>
        <Box sx={{ display: "inline-block" }}>
          <CustomButton
            text={!isEdit ? t("Edit") : t("Cancel")}
            style={{ m: 2 }}
            onClickFunction={() => {
              setIsEdit(!isEdit);
              if (isEdit && isDirty) reset();
            }}
          />
          {isEdit && (
            <CustomButton text={t("You Save")} style={{ m: 2 }} type="submit" />
          )}
        </Box>
      </form>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit User Parameters")}
        successMessage={t("Message Success Edit User Parameters")}
      />
    </div>
  );
}

export default MainAddress;
