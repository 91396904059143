import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller, useForm, useWatch } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import useAuthenticationUser from "hooks/useAuthenticationUser";
import usePostTransactionMutation from "hooks/usePostTransactionMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import useGetPaymentTypeQuery from "hooks/useGetPaymentTypeQuery";
import { Loader } from "components/Loader/Loader";
import { format, intervalToDuration } from "date-fns";
import { operations } from "schema/schema";
import { addTransactionType, dateFormat } from "constans/Constans";
import { find } from "lodash";
import { useQuery } from "react-query";
import { formatValueDatepicker, formatValuePayloadDate } from "helpers/Global";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormHelperText from "@mui/material/FormHelperText";

type Response =
  operations["api_apiv1payment_types_get_collection"]["responses"]["200"]["content"]["application/ld+json"]["hydra:member"];

interface FormInputs {
  id: number;
  paidTypeId: number;
  comment: string;
  verificationDate: string;
  amount: number;
  name: string;
  amountInBaseUnit: number;
  introducedId: number;
  mode: operations["api_apiv1p24_transactions_post"]["parameters"]["query"]["mode"];
}

type AddPaymentTableSubPageTypes = {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
};

function AddPaymentTableSubPage({
  userInformationData,
}: AddPaymentTableSubPageTypes) {
  const { t } = useTranslation();
  const { data } = useAuthenticationUser();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const loggedUserName = data?.user_fullName;
  const { refetch } = useQuery({
    queryKey: [
      "transactions",
      userInformationData?.id,
      {
        query: {
          "order[verificationDate]": "desc",
        },
      },
    ],
  });
  const { refetch: userDataRefetch } = useQuery({
    queryKey: ["UserInformation", userInformationData?.id],
  });
  const loggedUserId = data?.user_id;
  let date = dateFormat;
  const { mutate: postNewTransaction, isLoading: postNewTransactionLoading } =
    usePostTransactionMutation();
  const { data: paymentType } = useGetPaymentTypeQuery({
    query: {
      statusWithoutBit1And2: true,
      itemsPerPage: 100,
      "order[type]": "asc",
      "order[displayOrder]": "asc",
    },
  });
  const validationSchema = yup.object().shape({
    amount: yup.string().required(t("This Field is Required") as string),
  });
  const {
    control: AddPaymentControl,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      mode: undefined,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = ({
    comment,
    verificationDate,
    amount,
    paidTypeId,
    mode,
  }: FormInputs) => {
    !(
      verificationDate && verificationDate > (new Date() as unknown as string)
    ) &&
      postNewTransaction(
        {
          payload: {
            user: `/api/v1/users/${userInformationData?.id}`,
            paymentType: `/api/v1/payment_types/${paidTypeId}`,
            amountInCents: amount * 100,
            addedDate: format(new Date(), formatValuePayloadDate),
            verificationDate: verificationDate,
            userEnteredBy: `/api/v1/users/${loggedUserId}`,
            comment: comment,
          },
          mode: mode,
        },
        {
          onSuccess: () => {
            setOpenSuccess(true);
            refetch();
            userDataRefetch();
          },
          onError: () => {
            setOpenSuccess(false);
            setOpenError(true);
          },
        }
      );
  };
  const { paidTypeId, mode, verificationDate } = useWatch({
    control: AddPaymentControl,
  });

  const groupByTypeOfPayment = (data: Response) => {
    return Array.from(
      data?.reduce((acc, item) => {
        const key = item.type;
        if (acc.has(key)) {
          acc.get(key).push(item);
        } else {
          acc.set(key, [item]);
        }
        return acc;
      }, new Map())
    );
  };

  const setTypeGroup = (data: string) => {
    switch (data) {
      case "1":
        return <p>{t("Contributions")}</p>;
      case "2":
        return <p>{t("Points")}</p>;
      case "3":
        return <p>{t("Forms")}</p>;
    }
  };
  const compareExpirationDate = intervalToDuration({
    start: new Date(userInformationData?.contributionExpirationDate as string),
    end: new Date(date as string),
  });
  const noMoreThan = (year: number, months: number, days: number) => {
    let isNoMoreThan = false;
    if (
      compareExpirationDate.years === year &&
      compareExpirationDate.months === months &&
      compareExpirationDate.days &&
      compareExpirationDate.days <= days
    )
      isNoMoreThan = true;

    return isNoMoreThan;
  };
  useEffect(() => {
    if (paidTypeId) {
      const paidTypeData = find(paymentType?.["hydra:member"], {
        id: paidTypeId,
      });
      reset({
        amount: paidTypeData?.amountInBaseUnit,
        paidTypeId: paidTypeData?.id,
        verificationDate: format(new Date(), formatValuePayloadDate),
        mode:
          (paidTypeData?.type as unknown as string) === "2"
            ? addTransactionType.POINT
            : (paidTypeData?.type as unknown as string) === "3"
            ? addTransactionType.FORM
            : (!noMoreThan(0, 0, 30) &&
                userInformationData?.contributionExpirationDate &&
                date > userInformationData?.contributionExpirationDate) ||
              userInformationData?.contributionExpirationDate === null
            ? addTransactionType.DONT_KEEP_CONTINUITY
            : addTransactionType.KEEP_CONTINUITY,
      });
    }
  }, [reset, paidTypeId, paymentType]);

  return (
    <Grid item xs={12}>
      <Card>
        {postNewTransactionLoading && <Loader />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={{ m: 5, fontSize: "20px" }}>
            {t("Add Payment")}
          </Typography>
          <Box sx={{ m: 5 }}>
            <FormControl>
              <InputLabel id="edit-user-add-payment-select-label">
                {t("Paid Title")}
              </InputLabel>
              <Controller
                control={AddPaymentControl}
                name="paidTypeId"
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    sx={{ width: 250 }}
                    labelId="edit-user-add-payment-select-label"
                    label={t("Paid Title")}
                    id="multiple-checkbox"
                  >
                    {paymentType
                      ? groupByTypeOfPayment(paymentType?.["hydra:member"]).map(
                          ([type, payment]) => {
                            const subItems = payment.map(
                              (element: FormInputs) => (
                                <MenuItem
                                  key={element.id}
                                  value={element.id}
                                  sx={{ whiteSpace: "initial", maxWidth: 300 }}
                                >
                                  {element.name} ({element.amountInBaseUnit} zł)
                                </MenuItem>
                              )
                            );

                            return [
                              <ListSubheader>
                                {setTypeGroup(type)}
                              </ListSubheader>,
                              ...subItems,
                            ];
                          }
                        )
                      : undefined}
                  </Select>
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                control={AddPaymentControl}
                name="comment"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="standard"
                    type="string"
                    sx={{ width: 170, my: 2, mx: 5 }}
                    label={t("Comments")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </FormControl>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={t("Language Code") as string}
            >
              <FormControl>
                <Controller
                  control={AddPaymentControl}
                  name="verificationDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      disableFuture
                      label={t("Date Of Paid")}
                      inputFormat={formatValueDatepicker}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: "180px", mx: 2 }} />
                      )}
                    />
                  )}
                />

                {verificationDate &&
                  verificationDate > (new Date() as unknown as string) && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {t("Invalid Date")}
                    </FormHelperText>
                  )}
              </FormControl>
            </LocalizationProvider>
            <FormControl>
              <Controller
                control={AddPaymentControl}
                name="amount"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="standard"
                    type="number"
                    sx={{ width: 70, my: 2, mx: 5 }}
                    label={t("Amount")}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors?.amount)}
                    helperText={errors?.amount?.message}
                    InputProps={{
                      inputProps: {
                        type: "float",
                        min: 0,
                      },
                      endAdornment: (
                        <InputAdornment position="end">zł</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <TextField
              value={loggedUserName}
              variant="standard"
              type="string"
              sx={{
                width: 200,
                my: 2,
                mx: 5,
                borderBottom: "1px solid #bebdc7",
              }}
              label={t("Introduced")}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: { cursor: "default" } }}
            />
            <TextField
              value={date}
              variant="standard"
              type={date}
              sx={{
                my: 2,
                mx: 5,
                width: "150px",
                borderBottom: "1px solid #bebdc7",
              }}
              label={t("Date Of Entry")}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: { cursor: "default" } }}
            />
            {mode !== addTransactionType.POINT &&
              mode !== addTransactionType.FORM &&
              mode !== undefined &&
              userInformationData?.contributionExpirationDate !== null && (
                <FormControl>
                  <Controller
                    control={AddPaymentControl}
                    name="mode"
                    render={({ field }) => (
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        sx={{ flexDirection: "row" }}
                        {...field}
                      >
                        <>
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label={t("Keep Continuity")}
                          />
                          {(userInformationData?.contributionExpirationDate &&
                            date >
                              userInformationData?.contributionExpirationDate) ||
                            (userInformationData?.contributionExpirationDate ===
                              null &&
                              !noMoreThan(0, 0, 30) && (
                                <FormControlLabel
                                  value={1}
                                  control={<Radio />}
                                  label={t("Dont Keep Continuity")}
                                />
                              ))}
                        </>
                        {userInformationData?.contributionExpirationDate &&
                          date <
                            userInformationData?.contributionExpirationDate && (
                            <>
                              <FormControlLabel
                                value={2}
                                control={<Radio />}
                                label={t("Supplement For Whole Period")}
                              />

                              <FormControlLabel
                                value={3}
                                control={<Radio />}
                                label={t(
                                  "Supplement From Now To End Of Period"
                                )}
                              />
                            </>
                          )}
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              )}
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ my: 2, mx: 5 }}
            >
              {t("You Save")}
            </Button>
          </Box>
          <ChangeParametersModal
            openSuccess={openSuccess}
            openError={openError}
            setOpenSuccess={setOpenSuccess}
            setOpenError={setOpenError}
            errorMessage={t("Message Error Added Transaction")}
            successMessage={t("Message Success Added Transaction")}
          />
        </form>
      </Card>
    </Grid>
  );
}

export default AddPaymentTableSubPage;
