import React from "react";
import { FormControl, MenuItem, Select, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import {
  JunctionProfilesType,
  TableCellCustomLong,
  TableCellCustomShort,
  TableCellCustomTitle,
  TextFieldCustom,
  useStyles,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";
import useGetDepartmentsQuery from "hooks/useGetDepartmentsQuery";
import { find } from "lodash";
import { formattedDate } from "helpers/Global";

function JunctionProfilesMembershipDetails({
  control,
  duplicateUserData,
  isLoading,
}: JunctionProfilesType) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: departmentsData, isLoading: departmentsDataLoading } =
    useGetDepartmentsQuery({
      query: { status: 0 },
    });
  const duplicateUserDepartmentId = duplicateUserData?.department?.substring(
    20,
    22
  );
  const duplicateUserDepartment = find(departmentsData?.["hydra:member"], {
    id: duplicateUserDepartmentId
      ? parseInt(duplicateUserDepartmentId)
      : undefined,
  });
  isLoading = departmentsDataLoading;
  return (
    <TableBody>
      <TableRow>
        <TableCellCustomTitle>{t("Membership")}</TableCellCustomTitle>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom
            value={
              duplicateUserData?.declarationReceiptDate
                ? formattedDate(
                    duplicateUserData?.declarationReceiptDate,
                    "yyyy-MM-dd"
                  )
                : undefined
            }
          />
        </TableCellCustomLong>
        <TableCellCustomShort>
          {t("Declaration Receipt Date")}
        </TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="declarationReceiptDate"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom
            value={formattedDate(
              duplicateUserData?.declarationFulfilmentDate,
              "yyyy-MM-dd"
            )}
          />
        </TableCellCustomLong>
        <TableCellCustomShort>
          {t("Declaration Fulfilment Date")}
        </TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="declarationFulfilmentDate"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom
            value={formattedDate(
              duplicateUserData?.inMembershipSince,
              "yyyy-MM-dd"
            )}
          />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("In Membership Since")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="inMembershipSince"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>

      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom
            value={formattedDate(
              duplicateUserData?.inMembershipTo,
              "yyyy-MM-dd"
            )}
          />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Membership Paid To")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="inMembershipTo"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextField
            sx={{ width: "100%", input: { textAlign: "center" } }}
            value={duplicateUserDepartment?.name}
            size="small"
            InputProps={{
              readOnly: true,
            }}
          />
        </TableCellCustomLong>
        <TableCellCustomShort> {t("Department")} </TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="department"
              render={({ field: { value, onChange } }) => (
                <Select value={value} onChange={onChange} size="small">
                  {departmentsData?.["hydra:member"].map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom
            value={
              duplicateUserData?.cardNumber
                ? (duplicateUserData?.cardNumber as unknown as string)
                : undefined
            }
          />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Card Number")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="cardNumber"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
    </TableBody>
  );
}

export default JunctionProfilesMembershipDetails;
