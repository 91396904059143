import React from "react";
import { TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import {
  JunctionProfilesType,
  TableCellCustomLong,
  TableCellCustomShort,
  TableCellCustomTitle,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";
import { Controller } from "react-hook-form";
import { map } from "lodash";
import { formattedDate, formatValueDateTimeOther } from "helpers/Global";
import useGetCrmsQuery from "hooks/useGetCrmsQuery";
import CheckboxSetAllItems from "components/PageJunctionProfiles/CheckboxSetAllItems";
import CheckboxSetSingleItem from "components/PageJunctionProfiles/CheckboxSetSingleItem";

function JunctionProfilesCrmsDetails({
  control,
  duplicateUserData,
  mainUserData,
  resetAllGroupCheckboxes,
  setResetAllGroupCheckboxes,
  isLoading,
}: JunctionProfilesType) {
  const { t } = useTranslation();
  const { data: crmsDataMainUser, isLoading: crmsDataMainUserLoading } =
    useGetCrmsQuery({
      query: {
        toUser: mainUserData?.id as unknown as string,
        "notType[]": ["30"],
        "order[createdAt]": "desc",
      },
    });
  const {
    data: crmsDataDuplicateUser,
    isLoading: crmsDataDuplicateUserLoading,
  } = useGetCrmsQuery({
    query: {
      toUser: duplicateUserData?.id as unknown as string,
      "notType[]": ["30"],
      "order[createdAt]": "desc",
    },
  });
  isLoading = crmsDataMainUserLoading || crmsDataDuplicateUserLoading;
  return (
    <Controller
      control={control}
      name="crms"
      render={({ field: { value, onChange } }) => (
        <TableBody>
          <TableRow sx={{ position: "relative" }}>
            <TableCellCustomTitle>
              {t("Entries")}
              {(crmsDataDuplicateUser?.["hydra:totalItems"] ?? 0 > 0) && (
                <CheckboxSetAllItems
                  resetAllGroupCheckboxes={resetAllGroupCheckboxes}
                  setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
                  value={crmsDataDuplicateUser?.["hydra:member"]}
                  onChange={onChange}
                />
              )}
            </TableCellCustomTitle>
          </TableRow>
          {map(crmsDataMainUser?.["hydra:member"], (item) => {
            return (
              <TableRow key={item.id}>
                <TableCellCustomLong />
                <TableCellCustomShort />
                <TableCellCustomLong>
                  <p>
                    {item.typeCrm} {item.content}
                  </p>
                  <p>
                    {formattedDate(item.createdAt, formatValueDateTimeOther)}{" "}
                    {item.author}
                  </p>
                </TableCellCustomLong>
              </TableRow>
            );
          })}

          {map(crmsDataDuplicateUser?.["hydra:member"], (item, index) => {
            return (
              <CheckboxSetSingleItem
                key={index}
                item={item}
                value={value}
                onChange={onChange}
              />
            );
          })}
        </TableBody>
      )}
    />
  );
}

export default JunctionProfilesCrmsDetails;
