import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "/api/v1/users/membership_verification";
const queryKey = "membershipValidation";

type Response = operations["membershipVerification"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter = operations["membershipVerification"]["parameters"];

const getMembershipValidation = async (parameters: Parameter) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetMembershipValidationQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getMembershipValidation(parameters),
    options
  );
}
