import React, { useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import {
  Autocomplete,
  Card,
  Divider,
  FormControl,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useSettings } from "hooks/useSettings";
import { useTranslation } from "react-i18next";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import useUserInformationQuery from "hooks/useUserInformationQuery";
import JunctionsProfilesTable from "components/PageJunctionProfiles/JunctionsProfilesTable";
import { useTheme } from "@mui/material/styles";
import { Loader } from "components/Loader/Loader";
import useGetUserFilterParametersQuery from "hooks/useGetUserFilterParametersQuery";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { UserValue } from "types/types";
import CustomButton from "components/Buttons/CustomButton";

type InputValue = {
  fullName?: string;
  email?: string;
  cardNumber?: number;
  id?: number;
};

interface FormInputs {
  duplicateProfile: UserValue;
  mainProfile: UserValue;
}
function JunctionProfiles() {
  const { settings } = useSettings();
  const { t } = useTranslation();
  const [junctionProfilesEnabled, setJunctionProfilesEnabled] = useState(false);
  const [openErrorNotChosenUser, setOpenErrorNotChosenUser] = useState(false);
  const [searchFirstText, setSearchFirstText] = useState("");
  const [searchSecText, setSearchSecText] = useState("");
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    control: junctionProfilesControl,
    handleSubmit,
    setValue,
  } = useForm<FormInputs>();
  const { duplicateProfile, mainProfile } = useWatch({
    control: junctionProfilesControl,
  });

  const { data: firstUsersData } = useGetUserFilterParametersQuery(
    {
      query: {
        searchAll: searchFirstText,
        itemsPerPage: 50,
        "properties[]": ["fullName", "email", "id"],
      },
    },
    {
      enabled: searchFirstText.length > 1,
    }
  );
  const { data: secUsersData } = useGetUserFilterParametersQuery(
    {
      query: {
        searchAll: searchSecText,
        itemsPerPage: 50,
        "properties[]": ["fullName", "email", "id"],
      },
    },
    {
      enabled: searchSecText.length > 1,
    }
  );

  const replaceUsers = () => {
    const tmp = duplicateProfile;
    // @ts-ignore
    setValue("duplicateProfile", mainProfile);
    // @ts-ignore
    setValue("mainProfile", tmp);
    setJunctionProfilesEnabled(true);
  };
  const { data: duplicateUserData, isLoading: duplicateUserDataLoading } =
    useUserInformationQuery(duplicateProfile?.id, {
      enabled: junctionProfilesEnabled,
      cacheTime: 0,
      onSettled: () => {
        setJunctionProfilesEnabled(false);
        setSearchFirstText("");
      },
    });
  const { data: mainUserData, isLoading: mainUserDataLoading } =
    useUserInformationQuery(mainProfile?.id, {
      enabled: junctionProfilesEnabled,
      cacheTime: 0,
      onSettled: () => {
        setJunctionProfilesEnabled(false);
        setSearchSecText("");
      },
    });
  const onSubmit = () => {
    if (duplicateProfile?.id !== undefined && mainProfile?.id !== undefined) {
      setJunctionProfilesEnabled(true);
    } else setOpenErrorNotChosenUser(true);
  };
  const showValues = (value: InputValue | undefined) => {
    return `${value?.fullName} <${value?.email}> UID: ${value?.id}`;
  };

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
        overflow: "unset",
      }}
    >
      <CardHeader title={t("Junction Profiles")} />
      {(duplicateUserDataLoading || mainUserDataLoading) && <Loader />}
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          sx={{
            width: onlySmallScreen ? 250 : 300,
            m: 2,
          }}
        >
          <Controller
            control={junctionProfilesControl}
            name="duplicateProfile"
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                noOptionsText={t("None")}
                options={firstUsersData?.["hydra:member"] ?? []}
                onChange={(event, item) => onChange(item)}
                getOptionLabel={(value) => showValues(value)}
                // @ts-ignore
                onInputChange={(_, val: InputValue) => (value = val)}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      duplicateProfile?.id !== undefined
                        ? showValues(duplicateProfile)
                        : ""
                    }
                  >
                    <TextField
                      label={t("Duplicate Profile")}
                      variant="outlined"
                      onChange={(e) => setSearchFirstText(e.target.value)}
                      {...params}
                    />
                  </Tooltip>
                )}
              />
            )}
          />
        </FormControl>
        <FormControl sx={{ width: onlySmallScreen ? 250 : 300, m: 2 }}>
          <Controller
            control={junctionProfilesControl}
            name="mainProfile"
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                noOptionsText={t("None")}
                options={secUsersData?.["hydra:member"] ?? []}
                onChange={(event, item) => onChange(item)}
                getOptionLabel={(value) => showValues(value)}
                // @ts-ignore
                onInputChange={(_, val: InputValue) => (value = val)}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      mainProfile?.id !== undefined
                        ? showValues(mainProfile)
                        : ""
                    }
                  >
                    <TextField
                      label={t("Main Profile")}
                      variant="outlined"
                      onChange={(e) => setSearchSecText(e.target.value)}
                      {...params}
                    />
                  </Tooltip>
                )}
              />
            )}
          />
        </FormControl>
        <CustomButton text={t("Check")} style={{ m: 2 }} type="submit" />
      </form>
      {mainUserData && duplicateUserData && (
        <JunctionsProfilesTable
          replaceUsers={replaceUsers}
          duplicateUserData={duplicateUserData}
          mainUserData={mainUserData}
        />
      )}
      <ChangeParametersModal
        setOpenErrorNotChosenUser={setOpenErrorNotChosenUser}
        openErrorNotChosenUser={openErrorNotChosenUser}
        errorNotChosenUserMessage={t("Message Error Not Chosen User")}
      />
    </Card>
  );
}

export default JunctionProfiles;
