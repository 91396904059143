import React, { useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import { Card, Divider, FormControl, useMediaQuery } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import { useTranslation } from "react-i18next";
import useGetCheckingProfilesQuery from "hooks/useGetCheckingProfilesQuery";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import ResultsOfVerification from "components/PageCheckingProfiles/ResultsOfVerification";
import { useTheme } from "@mui/material/styles";
import CustomButton from "components/Buttons/CustomButton";

interface FormInputs {
  emailList: string;
}

function CheckingProfiles() {
  const { settings } = useSettings();
  const { t } = useTranslation();
  const [sendEmailList, setSendEmailList] = useState<string[]>();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showResultsOfVerification, setShowResultsOfVerification] =
    useState(false);
  const {
    mutate: checkingProfiles,
    data: resultsOfVerificationData,
    isLoading: checkingProfilesLoading,
  } = useGetCheckingProfilesQuery();
  const { control: CheckingProfilesControl, handleSubmit } =
    useForm<FormInputs>();
  const onSubmit = ({ emailList }: FormInputs) => {
    const inputEmails = emailList
      .split(/[,|/ \n]/)
      .map((item) => item.trim())
      .filter((email) => email.includes("@"));
    checkingProfiles({
      emails: inputEmails,
    });
    setSendEmailList(inputEmails);
  };

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
      }}
    >
      <CardHeader title={t("Checking Of Profiles")} />
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          sx={{ width: onlySmallScreen ? "100%" : 300, px: 5, my: 5 }}
        >
          <Controller
            control={CheckingProfilesControl}
            name="emailList"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                variant="outlined"
                multiline
                type="text"
                label={t("Email Addresses")}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </FormControl>
        <CustomButton
          onClickFunction={() => setShowResultsOfVerification(true)}
          text={t("Check")}
          style={{ my: 6, mx: 5 }}
          type="submit"
        />
        <ResultsOfVerification
          showResultsOfVerification={showResultsOfVerification}
          setShowResultsOfVerification={setShowResultsOfVerification}
          resultsOfVerificationData={resultsOfVerificationData}
          inputEmails={sendEmailList}
          checkingProfilesLoading={checkingProfilesLoading}
        />
      </form>
    </Card>
  );
}

export default CheckingProfiles;
