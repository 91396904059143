import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { paths } from "schema/schema";
import { UserIdType } from "types/types";

const path = "api/v1/user_paragraphs_permissions/";
const queryKey = "editPermissions";

type Response =
  paths["/api/v1/user_paragraphs_permissions/{userId}"]["get"]["responses"]["200"]["content"]["application/json"];
type UserId = UserIdType;
type Parameters = any;

const getEditPermissions = async (parameters: any, userId: UserId) => {
  const { data } = await getApiClient().get(`${path}${userId}`, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetEditPermissionsQuery(
  userId: UserId,
  parameters: Parameters,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, userId, parameters],
    () => getEditPermissions(parameters, userId),
    options
  );
}
