import React from "react";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import TableBasic from "components/TableBasic/TableBasic";
import ArrowTableHeader from "./Arrow";
import ActionsButton from "./ActionsButton";
import { find } from "lodash";
import dayjs from "dayjs";
import { headerSortTable } from "components/TableBasic/HeaderSortTable";
import UseHasAccess from "hooks/useHasAccess";
import { Role } from "helpers/Global";

interface UsersListType {
  isLoading: boolean;
  rows: Array<object> | undefined;
  sortOptions: GridSortModel;
  setSortOptions: React.Dispatch<React.SetStateAction<GridSortModel>>;
  pageNumber: number;
  setFilterEnabled: (value: boolean) => void;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  itemsPerPage: number;
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  totalItems: number | undefined;
}

function UsersList({
  rows,
  isLoading,
  sortOptions,
  setSortOptions,
  setFilterEnabled,
  setPageNumber,
  setItemsPerPage,
  totalItems,
}: UsersListType) {
  const { t } = useTranslation();
  const hasAccess = UseHasAccess([Role.ROLE_SUPER_USER]).length > 0;
  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "cardNumber",
      minWidth: 80,
      renderHeader: () =>
        (
          <ArrowTableHeader
            onClick={() => {
              headerSortTable(sortOptions, setSortOptions, "cardNumber");
            }}
            direction={find(sortOptions, { field: "cardNumber" })?.sort || null}
          >
            {t("Card Number")}
          </ArrowTableHeader>
        ) as unknown as string,
      sortable: false,
    },
    {
      flex: 0.5,
      minWidth: 170,
      field: "fullName",
      renderHeader: () =>
        (
          <ArrowTableHeader
            onClick={() => {
              headerSortTable(sortOptions, setSortOptions, "fullName");
            }}
            direction={find(sortOptions, { field: "fullName" })?.sort || null}
          >
            {t("First Name and Last Name")}
          </ArrowTableHeader>
        ) as unknown as string,
      sortable: false,
    },
    {
      flex: 0.5,
      minWidth: 250,
      hide: !hasAccess,
      field: "address",
      renderHeader: () =>
        hasAccess &&
        ((
          <ArrowTableHeader
            onClick={() => {
              headerSortTable(sortOptions, setSortOptions, "address");
            }}
            direction={find(sortOptions, { field: "address" })?.sort || null}
          >
            {t("Address")}
          </ArrowTableHeader>
        ) as unknown as string),
      sortable: false,
      renderCell: (params) => {
        if (hasAccess) {
          return <span>{params.value}</span>;
        }
        return null;
      },
    },
    {
      flex: 0.15,
      minWidth: 250,
      hide: !hasAccess,
      field: "mainEmail",
      renderHeader: () =>
        hasAccess &&
        ((
          <ArrowTableHeader
            onClick={() => {
              headerSortTable(sortOptions, setSortOptions, "mainEmail");
            }}
            direction={find(sortOptions, { field: "mainEmail" })?.sort || null}
          >
            {t("Email")}
          </ArrowTableHeader>
        ) as unknown as string),
      sortable: false,
      renderCell: (params) => {
        if (hasAccess) {
          return <span>{params.value}</span>;
        }
        return null;
      },
    },
    {
      flex: 0.25,
      minWidth: 150,
      field: "mainPhone",
      hide: !hasAccess,
      renderHeader: () =>
        hasAccess &&
        ((
          <ArrowTableHeader
            onClick={() => {
              headerSortTable(sortOptions, setSortOptions, "mainPhone");
            }}
            direction={find(sortOptions, { field: "mainPhone" })?.sort || null}
          >
            {t("Phone")}
          </ArrowTableHeader>
        ) as unknown as string),
      sortable: false,
      renderCell: (params) => {
        if (hasAccess) {
          return <span>{params.value}</span>;
        }
        return null;
      },
    },
    {
      flex: 0.25,
      minWidth: 150,
      hide: !hasAccess,
      field: "birthDate",
      renderHeader: () =>
        hasAccess &&
        ((
          <ArrowTableHeader
            onClick={() => {
              headerSortTable(sortOptions, setSortOptions, "birthDate");
            }}
            direction={find(sortOptions, { field: "birthDate" })?.sort || null}
          >
            {t("Birth Date")}
          </ArrowTableHeader>
        ) as unknown as string),
      sortable: false,
      renderCell: (params) => {
        if (hasAccess) {
          return (
            <span>
              {params.value ? dayjs(params.value).format("YYYY-MM-DD") : ""}
            </span>
          );
        }
        return null;
      },
    },
    {
      flex: 0.1,
      minWidth: 90,
      hide: !hasAccess,
      sortable: false,
      disableColumnMenu: true,
      field: "actions",
      headerName: t("Actions") as string,
      renderCell: ({ row }) =>
        hasAccess && (
          <ActionsButton setFilterEnabled={setFilterEnabled} id={row.id} />
        ),
    },
  ];

  return (
    <Grid item xs={12}>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={isLoading}
        rows={rows}
        buttonTxt={t("Found Users")}
        title={t("Found Users")}
        setItemsPerPage={setItemsPerPage}
        totalItems={totalItems}
        setPageNumber={setPageNumber}
        queryFunction={setFilterEnabled}
      />
    </Grid>
  );
}

export default UsersList;
