import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import EditPermissionsSelect from "components/PageEditPermissions/EditPermissionsSelect/EditPermissionsSelect";
import useGetEditPermissionsQuery from "hooks/useGetEditPermissonsQuery";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/material";
import { Loader } from "components/Loader/Loader";
import { paths } from "schema/schema";
import { EditPermissionsTypes } from "types/types";

interface Props {
  module: EditPermissionsTypes;
  menu: EditPermissionsTypes;
  websiteData: any;
  selectedUserId: number;
  filterByLevelPermissions: string | undefined;
  filterByNamePermissionsValue: string | undefined;
  level: string | undefined;
  positionId: number | undefined;
  setParentUserPermissionsEnabled: (value: boolean) => void;
  setIsTreeOpen: (value: boolean) => void;
}
type RowsDataTypes =
  paths["/api/v1/user_paragraphs_permissions/{userId}"]["get"]["responses"]["200"]["content"]["application/json"];

function EditPermissionsParagraphs({
  module,
  menu,
  selectedUserId,
  setIsTreeOpen,
  filterByLevelPermissions,
  filterByNamePermissionsValue,
  setParentUserPermissionsEnabled,
  websiteData,
  level,
  positionId,
}: Props) {
  const [userPermissionsEnabled, setUserPermissionsEnabled] = useState(false);
  const [page, setPage] = useState(1);
  const { data: paragraphLevelData, isLoading: paragraphsLevelDataLoading } =
    useGetEditPermissionsQuery(
      selectedUserId,
      {
        path: {
          id: selectedUserId,
        },
        query: {
          level: level,
          positionId: positionId,
          page: page,

          "name[value]": filterByNamePermissionsValue
            ? filterByNamePermissionsValue
            : undefined,

          "name[level]": filterByNamePermissionsValue
            ? filterByLevelPermissions
            : undefined,
        },
      },
      {
        enabled: userPermissionsEnabled,
        onSettled: () => {
          setUserPermissionsEnabled(false);
        },
      }
    );
  const handleChange = (e: React.ChangeEvent<unknown>, p: number) => {
    setPage(p);
    setUserPermissionsEnabled(true);
  };

  return (
    <>
      {paragraphsLevelDataLoading && <Loader />}
      {paragraphLevelData && !paragraphsLevelDataLoading
        ? paragraphLevelData?.children?.items?.map(
            (paragraphs: RowsDataTypes) => (
              <div
                className={"edit-permissions-paragraphs-level"}
                key={paragraphs.id}
                style={{
                  padding: "5px 0",
                  minWidth: "900px",
                }}
              >
                {paragraphs.name && (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <Divider
                      className={"edit-permissions-divider"}
                      sx={{
                        borderColor: "rgba(58, 53, 65, 0.52)",
                        position: "absolute",
                        width: "100%",
                        margin: 0,
                        right: 0,
                        top: "-3px",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <EditPermissionsSelect
                        id={paragraphs.id}
                        setParentUserPermissionsEnabled={
                          setParentUserPermissionsEnabled
                        }
                        setIsTreeOpen={setIsTreeOpen}
                        permission={
                          paragraphs.permission !== null
                            ? paragraphs.permission
                            : module.permission !== null
                            ? module.permission
                            : menu.permission
                            ? menu.permission
                            : websiteData.permission
                        }
                        inherit={paragraphs.permission === null}
                        selectedUserId={selectedUserId}
                        level={"PARAGRAPH"}
                      />
                      <p
                        className="edit-permissions-paragraphs-level-description"
                        style={{
                          padding: "5px 0 5px 5px",
                          display: "inline-block",
                        }}
                      >
                        {paragraphs.name}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )
          )
        : undefined}
      {module.childrenCount &&
      module.childrenCount > 30 &&
      !paragraphsLevelDataLoading ? (
        <Box p="5">
          <Pagination
            className="edit-permissions-pagination"
            count={paragraphLevelData?.children?.pages}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </Box>
      ) : undefined}
    </>
  );
}

export default EditPermissionsParagraphs;
