import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { map } from "lodash";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { Droppable, Draggable } from "react-beautiful-dnd";
import CustomButton from "components/Buttons/CustomButton";
import { childrenRefType } from "types/types";

interface Props {
  questionIndex: number;
  childrenRef: childrenRefType;
}

export default ({ questionIndex, childrenRef }: Props) => {
  const { t } = useTranslation();
  const setReorder = React.useCallback(
    (
      index: string | number,
      reorderCallback: (from: number, to: number) => void
    ) => {
      childrenRef.current[index] = reorderCallback;
    },
    [childrenRef]
  );
  const {
    register,
    control,
    setValue,
    formState: { isDirty },
  } = useFormContext();
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `questions[${questionIndex}].answers`,
  });
  useEffect(() => {
    fields.map((answers, index) =>
      setValue(
        `questions[${questionIndex}].answers[${index}].position`,
        index + 1
      )
    );
    setReorder(`child-${questionIndex}`, (from: number, to: number) => {
      move(from, to);
      const updatedFields = [...fields];
      const [removed] = updatedFields.splice(from, 1);
      updatedFields.splice(to, 0, removed);
      updatedFields.forEach((field: any) => {
        field.position = +1;
      });
      fields.map((answers, index) =>
        setValue(
          `questions[${questionIndex}].answers[${index}].position`,
          index + 1
        )
      );
    });
  }, [questionIndex, setReorder, move, isDirty, fields]);

  return (
    <div>
      <Droppable droppableId={`child-${questionIndex}`} type="childContainer">
        {(provided) => (
          <ul ref={provided.innerRef} {...provided.droppableProps}>
            {map(fields, (item, index) => (
              <Draggable key={item?.id} draggableId={item?.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <label
                      htmlFor={`questions[${questionIndex}].answers[${index}].answerText`}
                    >
                      {t("Answer")} {index + 1}:
                    </label>
                    <input
                      className="edit-polls-textField"
                      type="text"
                      id={`questions[${questionIndex}].answers[${index}].answerText`}
                      {...register(
                        `questions[${questionIndex}].answers[${index}].answerText`
                      )}
                    />
                    <CustomButton
                      text={t("Remove Answer")}
                      style={{ m: 2 }}
                      size="small"
                      color="error"
                      onClickFunction={() => remove(index)}
                    />
                    <OpenWithIcon sx={{ opacity: "0.7" }} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <CustomButton
              text={t("Add Answer")}
              style={{ m: 2 }}
              size="small"
              color="success"
              onClickFunction={() =>
                append({ position: 0, id: undefined, answerText: "" })
              }
            />
          </ul>
        )}
      </Droppable>
    </div>
  );
};
