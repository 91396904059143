import { Controller, useFormContext } from "react-hook-form";
import React from "react";

type inputProps = {
  name: string;
};

export const InputTextValue = ({ name }: inputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <input
          className="edit-form-textField"
          type="text"
          id={name}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};
