
import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "/api/v1/newsletter_types";
const queryKey = "filteredNewsletterTypes";

type Response =
    operations["api_apiv1newsletter_types_get_collection"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter = operations["api_apiv1newsletter_types_get_collection"]["parameters"];

const getNewslettersTypesConfig = async (parameters: Parameter) => {
    const { data } = await getApiClient().get(path, {
        params: parameters?.query,
    });
    return data;
};

export default function useGetNewslettersTypesQuery(
    parameters: Parameter,
    options: UseQueryOptions<Response, AxiosError> = {}
) {
    return useQuery<Response, AxiosError>(
        [queryKey, parameters],
        () => getNewslettersTypesConfig(parameters),
        options
    );
}
