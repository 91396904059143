import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatValueDatepicker } from "helpers/Global";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Loader } from "components/Loader/Loader";
import { discountCodesOptions } from "constans/Constans";
import useGetPaymentTypeQuery from "hooks/useGetPaymentTypeQuery";
import usePutDiscountCodeMutation from "hooks/usePutDiscountCodeMutation";
import CustomButton from "components/Buttons/CustomButton";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
    },
  },
};
export const useStyles = makeStyles(() => ({
  textFieldCustom: {
    margin: " 15px 25px",
  },
}));

interface FormInputs {
  paymentType: string | null;
  dateFrom: string;
  dateTo: string | undefined;
  withoutExpiration?: boolean;
  options: string[];
  amount: number | null;
  text: string;
  quantity: string | null;
}
interface EditDiscountCodeTypes {
  discountRowData: any;
  refetch: () => void;
}

function EditDiscountCode({ discountRowData, refetch }: EditDiscountCodeTypes) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState();
  const { mutate: putDiscountMutation, isLoading: putDiscountCodeLoading } =
    usePutDiscountCodeMutation();
  const { data: paymentType } = useGetPaymentTypeQuery({
    query: {
      statusWithoutBit1And2: true,
      type: "1",
      itemsPerPage: 100,
      "order[type]": "asc",
      "order[displayOrder]": "asc",
    },
  });
  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t("Invalid Value") as string)
      .required(t("This Field is Required") as string),
    quantity: yup
      .number()
      .nullable()
      .typeError(t("Invalid Value") as string),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      options: Object.keys(discountRowData.options).filter(
        (key) => discountRowData.options[key]
      ),
      paymentType: discountRowData.paymentType,
      dateTo: discountRowData?.dateTo,
      withoutExpiration:
        discountRowData?.dateTo === "9999-12-31T00:00:00+00:00",
    },
  });
  const onSubmit = ({
    paymentType,
    dateFrom,
    dateTo,
    options,
    amount,
    withoutExpiration,
    text,
    quantity,
  }: FormInputs) => {
    const submittedOptions = options.reduce(
      (acc: Record<string, boolean>, option) => {
        acc[option] = true;
        return acc;
      },
      {}
    );
    putDiscountMutation(
      {
        payload: {
          paymentType: paymentType,
          dateFrom: dateFrom,
          dateTo: withoutExpiration ? "9999-12-31T00:00:00+00:00" : dateTo,
          options: submittedOptions,
          amount: amount,
          text: text,
          quantity: quantity ? parseInt(quantity) : null,
        },
        contributionDiscountCodeId: discountRowData.id,
      },
      {
        onSuccess: () => {
          setOpenError(false);
          setOpenSuccess(true);
          refetch();
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          setMessageError(error.response?.data["hydra:description"]);
        },
      }
    );
  };
  const options = discountCodesOptions.map((row) => ({
    name: row.name,
    value: row.value,
  }));
  const { withoutExpiration } = useWatch({ control: control });
  useEffect(() => {
    reset({
      paymentType: discountRowData?.paymentType,
      dateFrom: discountRowData?.dateFrom,
      dateTo: !withoutExpiration ? discountRowData?.dateTo : null,
      withoutExpiration:
        discountRowData?.dateTo === "9999-12-31T00:00:00+00:00",
      options: Object.keys(discountRowData.options).filter(
        (key) => discountRowData.options[key]
      ),
      amount: discountRowData?.amount,
      text: discountRowData?.text,
      quantity: discountRowData?.quantity,
    });
  }, [discountRowData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {putDiscountCodeLoading && <Loader />}
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <FormControl className={classes.textFieldCustom}>
          <InputLabel id="add-inscriptions-user-select-label">
            {t("Type")}
          </InputLabel>
          <Controller
            control={control}
            name="paymentType"
            render={({ field: { value, onChange } }) => (
              <Select
                label={t("Type")}
                value={value}
                sx={{ width: 250 }}
                onChange={onChange}
                MenuProps={MenuProps}
                labelId="add-inscriptions-user-select-label"
              >
                {paymentType?.["hydra:member"].map((getCrmsTypes) => (
                  <MenuItem key={getCrmsTypes.id} value={getCrmsTypes["@id"]}>
                    {getCrmsTypes.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="dateFrom"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  label={t("Date From")}
                  inputFormat={formatValueDatepicker}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                  )}
                />
              )}
            />
          </FormControl>
          {(discountRowData?.dateTo !== "9999-12-31T00:00:00+00:00" ||
            !withoutExpiration) && (
            <FormControl className={classes.textFieldCustom}>
              <Controller
                control={control}
                name="dateTo"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    disabled={withoutExpiration}
                    label={t("Expiration Date")}
                    inputFormat={formatValueDatepicker}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EventIcon />
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                    )}
                  />
                )}
              />
            </FormControl>
          )}
          <FormControl className={classes.textFieldCustom}>
            <Controller
              control={control}
              name="withoutExpiration"
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  label={t("Without Expiration")}
                  control={
                    <Checkbox
                      value={value}
                      onChange={onChange}
                      checked={!!value}
                    />
                  }
                />
              )}
            />
          </FormControl>
        </LocalizationProvider>
        <FormControl
          className={classes.textFieldCustom}
          sx={{ width: "220px" }}
        >
          <InputLabel id="discount-option-selectBox-label">
            {t("Option")}
          </InputLabel>
          <Controller
            control={control}
            name="options"
            render={({ field: { value, onChange } }) => (
              <Select
                multiple
                value={value}
                onChange={onChange}
                sx={{ m: 0, minWidth: 220 }}
                variant="standard"
                id="discount-option-select"
                labelId="discount-option-selectBox-label"
              >
                {options?.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="amount"
            render={({ field: { value, onChange } }) => (
              <>
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!errors.amount && value !== null}
                  variant="standard"
                  label={t("Price After Discount")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 7 }}
                />
                {errors.amount && value !== null && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.amount.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </FormControl>
        <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="text"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                variant="standard"
                label={t("Discount Text")}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  maxLength: 20,
                }}
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.textFieldCustom}>
          <Controller
            control={control}
            name="quantity"
            render={({ field: { value, onChange } }) => (
              <>
                <TextField
                  value={value}
                  onChange={onChange}
                  variant="standard"
                  error={!!errors.quantity && value !== null && value !== ""}
                  label={t("Counter Of Remaining Codes")}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 7 }}
                />
                {errors.quantity && value !== null && value !== "" && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.quantity.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </FormControl>
      </Box>
      <CustomButton
        text={t("You Save")}
        type="submit"
        style={{ mx: 5, mb: 5, mt: 5 }}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={
          messageError
            ? t("Discount Code Exist In Database")
            : t("Message Error Edit Discount")
        }
        successMessage={t("Message Success Edit Discount")}
      />
    </form>
  );
}

export default EditDiscountCode;
