import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { components, operations } from "schema/schema";

const path = "/api/v1/forms_extended/";
const queryKey = "formsExtended";

export type Response = components["schemas"]["Form.jsonld-form.extended"];
type Parameters = operations["api_apiv1forms_get_collection"]["parameters"];
type FormId =
  operations["api_apiv1forms_extended_id_get"]["parameters"]["path"]["id"];

const getFormsExtended = async (parameters: Parameters, id: FormId) => {
  const { data } = await getApiClient().get(`${path}${id}`, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetFormsExtendedQuery(
  id: FormId,
  parameters: Parameters,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, id, parameters],
    () => getFormsExtended(parameters, id),
    options
  );
}
