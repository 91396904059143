import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = `${API_PREFIX}/contribution_discount_codes`;

type Payload = components["schemas"]["ContributionDiscountCode.jsonld"];
type Response = components["schemas"]["ContributionDiscountCode.jsonld"];


const postDiscountCode = async (payload: Payload) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function usePostDiscountMutation(
  options: UseMutationOptions<Response, AxiosError, Payload> = {}
) {
  return useMutation<Response, AxiosError, Payload>(postDiscountCode, options);
}
