import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { components, operations } from "schema/schema";

const path = "/api/v1/polls_extended/";
const queryKey = "pollsExtended";

export type Response = components["schemas"]["Poll.jsonld-poll.extended"];

type Parameters = operations["api_apiv1polls_get_collection"]["parameters"];
type PollId =
  operations["api_apiv1polls_extended_id_get"]["parameters"]["path"]["id"];
const getPollsExtended = async (parameters: Parameters, id: PollId) => {
  const { data } = await getApiClient().get(`${path}${id}`, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetPollsExtendedQuery(
  id: PollId,
  parameters: Parameters,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, id, parameters],
    () => getPollsExtended(parameters, id),
    options
  );
}
