import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { components, operations } from "schema/schema";

const path = "/api/v1/forms_answers_extended/";
const queryKey = "formAnswers";

export type Response = components["schemas"]["FormAnswer.jsonld"];
type Parameters =
  operations["api_apiv1form_answers_get_collection"]["parameters"];
type FormId =
  operations["api_apiv1form_answers_id_get"]["parameters"]["path"]["id"];

const getAnswersForm = async (parameters: Parameters, id: FormId) => {
  const { data } = await getApiClient().get(`${path}${id}`, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetAnswersFormQuery(
  id: FormId,
  parameters: Parameters,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, id, parameters],
    () => getAnswersForm(parameters, id),
    options
  );
}
