import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";

type Props = {
  name: string;
  label?: string | null;
  isLabel?: boolean | undefined;
};

function InputCheckBoxValue({ name, label, isLabel }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) =>
        isLabel ? (
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                value={value}
                checked={!!value}
                onChange={(e) => onChange(e.target.checked)}
              />
            }
          />
        ) : (
          <Checkbox
            value={value}
            checked={!!value}
            onChange={(e) => onChange(e.target.checked)}
          />
        )
      }
    />
  );
}

export default InputCheckBoxValue;
