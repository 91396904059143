import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Cookies from "js-cookie";
import TextField from "@mui/material/TextField";
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Box, { BoxProps } from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import Alert from "@mui/material/Alert";
import { styled, useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import Typography, { TypographyProps } from "@mui/material/Typography";
import MuiFormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { yupResolver } from "@hookform/resolvers/yup";
import useLoginMutation from "hooks/useLoginMutation";
import { ErrorTypes, UserData } from "types/types";
import useWhoIAmQuery from "hooks/useWhoIAmQuery";
import { Loader } from "components/Loader/Loader";
import { routes } from "routing/routes";

const BlankLayoutWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  "& .content-center": {
    display: "flex",
    minHeight: "70vh",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(5),
  },
}));

const RightWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    maxWidth: 400,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: 450,
  },
}));

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("md")]: {
    maxWidth: 400,
  },
}));

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: "0.18px",
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down("md")]: { marginTop: theme.spacing(8) },
}));

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    "& .MuiFormControlLabel-label": {
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
    },
  })
);

const Login = (props: any) => {
  let navigate: NavigateFunction = useNavigate();
  const [message, setMessage] = useState<ErrorTypes>();
  const { t } = useTranslation();
  const theme = useTheme();
  const [rememberMe, setRememberMe] = useState(true);
  const [logoutFromOtherDevices, setLogoutFromOtherDevices] = useState(false);
  const hidden = useMediaQuery(theme.breakpoints.down("md"));
  const [showPassword, setShowPassword] = useState(false);
  const { mutate: login, isLoading } = useLoginMutation();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const rememberMeCookies = Cookies.get("rememberMe");
    const logoutFromOtherDevicesCookies = Cookies.get("logoutFromOtherDevices");
    setRememberMe(!(rememberMeCookies === "false"));
    setLogoutFromOtherDevices(logoutFromOtherDevicesCookies === "true");
  }, []);

  useWhoIAmQuery({
    onSuccess: (data) => {
      if (data?.user_id) {
        navigate(routes.HOME.path);
      }
    },
  });

  const handleRememberMeChange = () => {
    setRememberMe((current) => !current);
  };
  const handleLogoutFromOtherDevicesChange = () => {
    setLogoutFromOtherDevices((current) => !current);
  };
  const defaultValues = {
    password: "",
    email: "",
    remember_me: rememberMe,
    logout_from_other_devices: logoutFromOtherDevices,
  };

  const handleLogin = (data: UserData) => {
    const remember_me = rememberMe;
    const logout_from_other_devices = logoutFromOtherDevices;
    const { email, password } = data;
    login(
      { email, password, remember_me, logout_from_other_devices },
      {
        onSuccess: () => {
          props.setIsError(false);
          Cookies.set("rememberMe", JSON.stringify(rememberMe));
          Cookies.set(
            "logoutFromOtherDevices",
            JSON.stringify(logoutFromOtherDevices)
          );
          localStorage.setItem("amILogged", JSON.stringify(true));
          navigate(routes.HOME.path);
        },
        onError: (error) => {
          setMessage(error);
        },
      }
    );
  };

  const schema = yup.object().shape({
    email: yup.string().required(t("This Field is Required") as string),
    password: yup
      .string()
      .min(5, t("Password Is To Short") as string)
      .required(),
    remember_me: yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <BlankLayoutWrapper className="layout-wrapper">
      <Box
        className="content-center"
        sx={{ minHeight: "100vh", overflowX: "hidden", position: "relative" }}
      >
        <RightWrapper
          sx={!hidden ? { border: `1px solid ${theme.palette.divider}` } : {}}
        >
          <Box
            sx={{
              p: onlySmallScreen ? 0 : 7,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "background.paper",
            }}
          >
            <BoxWrapper>
              <Box sx={{ mb: 6 }}>
                <TypographyStyled variant="h5">
                  {t("Welcome in")}
                </TypographyStyled>
                <Typography variant="body2">{t("Login Please")}</Typography>
              </Box>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(handleLogin)}
              >
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        autoFocus
                        fullWidth
                        value={value}
                        onChange={onChange}
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    )}
                  />
                  {errors.email && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <OutlinedInput
                        value={value}
                        onChange={onChange}
                        placeholder={t("Password") as string}
                        id="auth-login-v2-password"
                        error={Boolean(errors.password)}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onMouseDown={(e) => e.preventDefault()}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <EyeOutline />
                              ) : (
                                <EyeOffOutline />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText sx={{ color: "error.main" }} id="">
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <Box
                  sx={{
                    mb: 4,
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    label={t("Remember Me")}
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": { color: "text.primary" },
                    }}
                  />
                  <FormControlLabel
                    label={t("Logout From Other Devices")}
                    control={
                      <Checkbox
                        checked={logoutFromOtherDevices}
                        onChange={handleLogoutFromOtherDevicesChange}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": { color: "text.primary" },
                    }}
                  />
                </Box>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mb: 3 }}
                >
                  {t("Login")}
                </Button>
                {isLoading && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Loader />
                  </Box>
                )}
                {message && (
                  <Alert severity="error">
                    {t("Email or Password is invalid")}
                  </Alert>
                )}
              </form>
            </BoxWrapper>
          </Box>
        </RightWrapper>
      </Box>
    </BlankLayoutWrapper>
  );
};

export default Login;
