import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useStyles } from "pages/EditSubscriptions/EditSubscriptions";
import useGetNewsletterSubscriberConfigQuery from "hooks/useGetNewsletterSubscriberConfigQuery";
import { map, reduce, toPairs } from "lodash";
import { validateEmail } from "helpers/Global";
import { Loader } from "components/Loader/Loader";
import { components } from "schema/schema";
import usePostNewsletterSubscriptionMutation from "hooks/usePostNewsletterSubscriptionMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import useGetNewslettersTypesQuery from "hooks/useGetNewslettersTypesQuery";
import CustomButton from "components/Buttons/CustomButton";

interface FormInputsUser {
  email: string;
  status: number;
}
interface FormInputsNewsletter {
  [arg: string]: boolean;
}
interface EditSubscriberTypes {
  setSuccessEditSubscription: (value: boolean) => void;
}

function EditSubscriber({ setSuccessEditSubscription }: EditSubscriberTypes) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [
    newsletterSubscriberConfigEnabled,
    setNewsletterSubscriberConfigEnabled,
  ] = useState(false);
  const { control } = useForm<FormInputsUser>({
    defaultValues: {
      email: "",
    },
  });
  const { email } = useWatch({ control });
  const {
    data: newsletterSubscriberConfigsData,
    isLoading: newsletterSubscriberConfigsDataLoading,
    refetch,
  } = useGetNewsletterSubscriberConfigQuery(
    {
      query: {
        "newsletterSubscriber.email": email,
      },
    },
    {
      enabled: newsletterSubscriberConfigEnabled,
      onSettled: () => {
        setNewsletterSubscriberConfigEnabled(false);
      },
    }
  );
  const {
    mutate: newSubscriptionConfig,
    isLoading: newSubscriptionConfigLoading,
  } = usePostNewsletterSubscriptionMutation();
  const { data: newsletterTypesData } = useGetNewslettersTypesQuery({
    query: {},
  });
  const [message, setMessage] = useState("");
  const {
    control: newsletterTypeControl,
    reset,
    handleSubmit,
  } = useForm<FormInputsNewsletter>();
  const onSubmit = (data: FormInputsNewsletter) => {
    newSubscriptionConfig(
      {
        subscriptions: [
          {
            email: email,
            subscriptions: map(toPairs(data), ([key, value]) => {
              return { newsletterType: key, value };
            }),
          },
        ],
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          refetch();
          setSuccessEditSubscription(true);
        },
        onError: (error: any) => {
          setMessage(error.response?.data["hydra:description"]);
          setOpenSuccess(false);
          setOpenError(true);
          reset();
        },
      }
    );
  };
  useEffect(() => {
    reset(
      reduce(
        newsletterTypesData?.["hydra:member"],
        (acc: FormInputsNewsletter, current) => {
          const newsletterTypeConfigStatus = newsletterSubscriberConfigsData?.[
            "hydra:member"
          ]?.find(
            (
              obj: components["schemas"]["NewsletterSubscriberConfig.jsonld"]
            ) => {
              return obj.newsletterType?.["@id"] === current["@id"];
            }
          );
          return (
            (acc[`${current["@id"]}`] =
              newsletterTypeConfigStatus?.currentConfigChange?.status || false),
            acc
          );
        },
        {}
      )
    );
  }, [newsletterTypesData, newsletterSubscriberConfigsData]);

  return (
    <>
      {newsletterSubscriberConfigsDataLoading && <Loader />}
      {newSubscriptionConfigLoading && <Loader />}
      <Typography sx={{ my: 5 }}>{t("Edit Subscriber")}</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl sx={{ my: 1, mr: 5 }}>
          <Controller
            control={control}
            name="email"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                size="small"
                onChange={onChange}
                variant="outlined"
                type="email"
                label={t("Email")}
                error={value !== "" && !validateEmail(value)}
                helperText={
                  value !== "" && !validateEmail(value)
                    ? t("Error Email Validate")
                    : ""
                }
              />
            )}
          />
        </FormControl>
        <CustomButton
          text={t("Download Subscriptions")}
          style={{ m: 2 }}
          onClickFunction={() => {
            if (email !== "") setNewsletterSubscriberConfigEnabled(true);
          }}
          size="small"
        />
        {newsletterSubscriberConfigsData && (
          <Table sx={{ maxWidth: "600px", my: 5 }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellShort}>
                  {t("Name-v2")}
                </TableCell>
                <TableCell className={classes.tableCellShort}>
                  {t("Date Of Last Status Change")}
                </TableCell>
                <TableCell className={classes.tableCellShort}>
                  {t("Status")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newsletterTypesData?.["hydra:member"].map((newsletterType) => {
                const newsletterTypeId = newsletterType["@id"];
                const subscriberConfig = newsletterSubscriberConfigsData?.[
                  "hydra:member"
                ].find(
                  (config) =>
                    config.newsletterType?.["@id"] === newsletterTypeId
                );

                return (
                  <TableRow key={newsletterType.id}>
                    <TableCell className={classes.tableCellShort}>
                      {newsletterType?.name}
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      {subscriberConfig?.currentConfigChange?.entryDate || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      <Controller
                        key={newsletterType.id}
                        name={`${newsletterTypeId}`}
                        control={newsletterTypeControl}
                        defaultValue={
                          subscriberConfig?.currentConfigChange?.status || false
                        }
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <CustomButton
              text={t("You Save")}
              size="small"
              style={{ m: 2 }}
              type="submit"
            />
          </Table>
        )}
        <ChangeParametersModal
          openSuccess={openSuccess}
          openError={openError}
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
          errorMessage={
            message
              ? t("User Not Found")
              : t("Message Error Edit User Parameters")
          }
          successMessage={t("Message Success Edit User Parameters")}
        />
      </form>
    </>
  );
}

export default EditSubscriber;
