import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = `${API_PREFIX}/crm_types`;

type Payload = components["schemas"]["CrmType.jsonld"];
type Response = components["schemas"]["CrmType.jsonld"];
const postCrmType = async (payload: Payload) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function usePostCrmTypeMutation(
  options: UseMutationOptions<any, AxiosError, any> = {}
) {
  return useMutation<Response, AxiosError, Payload>(postCrmType, options);
}
