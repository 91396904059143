import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";

const path = "/api/logout";

const postLogout = async () => {
  const { data } = await getApiClient().post(path);
  return data;
};

export default function useLogoutMutation(
  options: UseMutationOptions<void, AxiosError, void> = {}
) {
  return useMutation<void, AxiosError>(postLogout, options);
}
