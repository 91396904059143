import React from "react";
import { dateTimeFormat } from "constans/Constans";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

interface DeclarationContentTypes {
  profileStatus?: number | null;
  fullName?: string;
  activeStatus?: boolean;
  email?: string;
  statementTypeForMembershipVerification?: number | null;
  cardNumber?: number;
  verificationValue?: number | string;
  contributionPeriod?: number | null;
  currentContributionLevelExpirationDate?: string | null;
}

function getPaymentType(paidPayment: number | null | undefined): string {
  switch (paidPayment) {
    case 0:
      return "dożywotnio";
    case 1:
      return "na okres jednego roku";
    case 2:
      return "na okres 5 lat";
    case 3:
      return "na okres trzech miesięcy";
    default:
      return "";
  }
}

function DeclarationContent({
  profileStatus,
  fullName,
  activeStatus,
  email,
  statementTypeForMembershipVerification,
  cardNumber,
  verificationValue,
  contributionPeriod,
  currentContributionLevelExpirationDate,
}: DeclarationContentTypes) {
  const currentDate = dateTimeFormat;
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  verificationValue = Number(verificationValue);

  return (
    <div
      style={{
        margin: onlySmallScreen ? " 0 20px" : "unset",
      }}
    >
      <p style={{ fontSize: "11px" }}>
        * - ewentualny komunikat o warunkowym członkostwie oznacza dopełnienie
        wymaganych formalności po stronie aplikującego i jest stanem
        przejściowym, trwającym do momentu podjęcia przez Zarząd SII uchwały o
        przyjęciu aplikującego w poczet członków SII
      </p>
      <p style={{ fontSize: "11px" }}>
        - w wypadku weryfikacji za pomocą numeru członkowskiego rok wydania
        karty (o ile taki został podany) należy pominąć
      </p>
      {/* CONDITION 1 */}
      {statementTypeForMembershipVerification === 0 && (
        <p className="paragraphs-membership-verification">
          Biuro Zarządu Stowarzyszenia Inwestorów Indywidualnych niniejszym
          potwierdza, że
          <p style={{ textAlign: "center", margin: "10px 0" }}>
            <b>{fullName}</b>
          </p>
          według stanu na dzień {currentDate} jest w systemie informatycznym
          Stowarzyszenia Inwestorów Indywidualnych zarejestrowany warunkowo jako
          <b> {activeStatus ? "aktywny" : "nieaktywny"} </b> członek
          Stowarzyszenia
          {isNaN(verificationValue)
            ? " korzystający z adresu "
            : " o numerze członkowskim "}
          <b> {isNaN(verificationValue) ? email + " " : cardNumber + " "}</b>
          ze składką członkowską na poziomie
          <b> {profileStatus === 4 ? " rozszerzonym" : " podstawowym"} </b>
          opłaconą <b>{getPaymentType(contributionPeriod)}</b>.
        </p>
      )}
      {/* CONDITION 2 */}
      {statementTypeForMembershipVerification === 1 && (
        <p className="paragraphs-membership-verification">
          Biuro Zarządu Stowarzyszenia Inwestorów Indywidualnych niniejszym
          potwierdza, że
          <p style={{ textAlign: "center", margin: "10px 0" }}>
            <b>{fullName}</b>
          </p>
          według stanu na dzień {currentDate} jest w systemie informatycznym
          Stowarzyszenia Inwestorów Indywidualnych zarejestrowany jako
          <b> {activeStatus ? "aktywny" : "nieaktywny"} </b> członek
          Stowarzyszenia
          {isNaN(verificationValue)
            ? " korzystający z adresu "
            : " o numerze członkowskim "}
          <b> {isNaN(verificationValue) ? email + " " : cardNumber + " "}</b>
          któremu przysługują prawa członkowskie na poziomie
          <b>{profileStatus === 4 ? " rozszerzonym" : " podstawowym"}</b>.
          Składka członkowska jest opłacona
          {currentContributionLevelExpirationDate !==
            "9999-12-31T00:00:00+00:00" && " do dnia "}
          <b>
            {" "}
            {currentContributionLevelExpirationDate ===
            "9999-12-31T00:00:00+00:00"
              ? "dożywotnio"
              : dayjs(currentContributionLevelExpirationDate)?.format(
                  "YYYY-MM-DD"
                )}
          </b>
          .
        </p>
      )}
      {/* CONDITION 3 */}
      {statementTypeForMembershipVerification === 2 && (
        <p className="paragraphs-membership-verification">
          Biuro Zarządu Stowarzyszenia Inwestorów Indywidualnych informuje, że
          <p style={{ textAlign: "center", margin: "10px 0" }}>
            <b>{fullName}</b>
          </p>
          według stanu na dzień {currentDate} <b>nie został zweryfikowany </b>
          jako aktywny członek Stowarzyszenia Inwestorów Indywidualnych.
        </p>
      )}
    </div>
  );
}

export default DeclarationContent;
