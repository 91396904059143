import React from "react";
import { FormControlLabel, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import {
  JunctionProfilesType,
  TableCellCustomLong,
  TableCellCustomShort,
  TableCellCustomTitle,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";
import { Controller } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import { concat, filter, includes, map } from "lodash";
import { formattedDate, formatValueDateTimeOther } from "helpers/Global";
import useBalancesQuery from "hooks/useBalancesQuery";
import CheckboxSetAllItems from "components/PageJunctionProfiles/CheckboxSetAllItems";

function JunctionProfilesPointsDetails({
  control,
  duplicateUserData,
  resetAllGroupCheckboxes,
  setResetAllGroupCheckboxes,
  mainUserData,
  isLoading,
}: JunctionProfilesType) {
  const { t } = useTranslation();
  const { data: mainUserPoints, isLoading: mainUserPointsLoading } =
    useBalancesQuery({
      query: {
        user: mainUserData?.id as unknown as string,
        "order[operationDate]": "desc",
        typeBalance: "INCOME",
      },
    });
  const { data: duplicateUserPoints, isLoading: duplicateUserPointsLoading } =
    useBalancesQuery({
      query: {
        user: duplicateUserData?.id as unknown as string,
        "order[operationDate]": "desc",
        typeBalance: "INCOME",
      },
    });
  isLoading = mainUserPointsLoading || duplicateUserPointsLoading;
  return (
    <Controller
      control={control}
      name="balances"
      render={({ field: { value, onChange } }) => (
        <TableBody>
          <TableRow sx={{ position: "relative" }}>
            <TableCellCustomTitle>
              {t("Points")}
              {(duplicateUserPoints?.["hydra:totalItems"] ?? 0 > 0) && (
                <CheckboxSetAllItems
                  resetAllGroupCheckboxes={resetAllGroupCheckboxes}
                  setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
                  value={duplicateUserPoints?.["hydra:member"]}
                  onChange={onChange}
                />
              )}
            </TableCellCustomTitle>
          </TableRow>
          {map(mainUserPoints?.["hydra:member"], (item) => {
            return (
              <TableRow key={item.id}>
                <TableCellCustomLong />
                <TableCellCustomShort />
                <TableCellCustomLong>
                  <p>
                    {item.paymentTitle} {item.points}pkt
                  </p>
                  <p>
                    {formattedDate(
                      item.operationDate,
                      formatValueDateTimeOther
                    )}{" "}
                    {item.introduced}
                  </p>
                </TableCellCustomLong>
              </TableRow>
            );
          })}

          {map(duplicateUserPoints?.["hydra:member"], (item) => {
            return (
              <TableRow key={item.id}>
                <TableCellCustomLong sx={{ width: "40%" }}>
                  <p>
                    {item.paymentTitle} {item.points}pkt
                  </p>
                  <p>
                    {formattedDate(
                      item.operationDate,
                      formatValueDateTimeOther
                    )}{" "}
                    {item.introduced}
                  </p>
                </TableCellCustomLong>
                <TableCellCustomShort>
                  <p>{">> >> >>"}</p>
                </TableCellCustomShort>
                <TableCellCustomLong>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={item["@id"]}
                        checked={includes(value, item["@id"])}
                        onChange={() => {
                          onChange(
                            includes(value, item["@id"])
                              ? filter(value, (val) => val !== item["@id"])
                              : concat(value, item["@id"])
                          );
                        }}
                      />
                    }
                    label={t("Get From Left")}
                  />
                </TableCellCustomLong>
              </TableRow>
            );
          })}
        </TableBody>
      )}
    />
  );
}

export default JunctionProfilesPointsDetails;
