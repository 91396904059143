import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import useUserInformationQuery from "hooks/useUserInformationQuery";
import { useParams } from "react-router-dom";
import ContactDetails from "components/PageEditUser/ContactDetails";
import MainAddress from "components/PageEditUser/MainAddress";
import CorrespondenceAddress from "components/PageEditUser/CorrespondenceAddress";
import OthersDetails from "components/PageEditUser/OthersDetails";
import Membership from "components/PageEditUser/Membership";
import Transactions from "components/PageEditUser/Transactions/Transactions";
import Points from "components/PageEditUser/Points/Points";
import Permissions from "components/PageEditUser/Permissions";
import Subscriptions from "components/PageEditUser/Subscriptions";
import LoginParameters from "components/PageEditUser/LoginParameters";
import UserValuesTable from "components/PageEditUser/UserValuesTable";
import { Loader } from "components/Loader/Loader";
import useTotalPointsQuery from "hooks/useTotalPointsQuery";
import useGetTransactionsQuery from "hooks/useGetTransactionsQuery";
import CustomButton from "components/Buttons/CustomButton";

const useStyles = makeStyles(() => ({
  box: {
    maxWidth: 600,
    display: "flex",
    flexDirection: "row",
    margin: "1.25rem",
  },
}));

function EditUser() {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const { id } = useParams<{ id: string | undefined }>();
  const [isPointsOpened, setIsPointsOpened] = useState(false);
  const [isTransactionsOpened, setIsTransactionsOpened] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const onlyExtraLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    data: userInformationData,
    isLoading: userInformationDataLoading,
    refetch,
  } = useUserInformationQuery(id ? parseInt(id) : undefined);
  const { data: totalPointsData } = useTotalPointsQuery(
    userInformationData?.id,
    { enabled: !!userInformationData?.id }
  );
  const { data: userTransactions } = useGetTransactionsQuery(
    userInformationData?.id,
    {
      query: {
        "order[verificationDate]": "desc",
      },
    },
    { enabled: !!userInformationData?.id }
  );
  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
      }}
    >
      {userInformationDataLoading && <Loader />}
      <CardHeader title={userInformationData?.fullName} />
      <Divider />
      <Grid
        container
        spacing={6}
        item
        sx={{
          m: onlyMediumScreen ? 0 : 4,
          justifyContent: "center",
          width: onlySmallScreen ? "100%" : "unset",
          pl: onlyMediumScreen ? 0 : "unset",
        }}
      >
        <Grid
          item
          sm={6}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card sx={{ minHeight: onlySmallScreen ? "unset" : "400px" }}>
            <Typography sx={{ ml: 5, mt: 5 }}>
              {t("Contact Details")}
            </Typography>
            <Box
              sx={{
                minHeight: 410,
                justifyContent: onlySmallScreen ? "center" : "unset",
              }}
              className={classes.box}
            >
              <ContactDetails
                refetch={refetch}
                userInformationData={userInformationData}
              />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          sm={6}
          md={3}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card>
            <Typography sx={{ ml: 5, mt: 5 }}>{t("Permissions")}</Typography>
            <Box
              sx={{
                minHeight: onlySmallScreen ? "unset" : "410px",
                justifyContent: onlySmallScreen ? "center" : "unset",
              }}
              className={classes.box}
            >
              <Permissions
                refetch={refetch}
                userInformationData={userInformationData}
              />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          sm={6}
          md={3}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card>
            <Typography sx={{ ml: 5, mt: 5 }}>{t("Subscriptions")}</Typography>
            <Box
              sx={{
                minHeight: onlySmallScreen ? "unset" : 410,
                justifyContent: onlySmallScreen ? "center" : "unset",
              }}
              className={classes.box}
            >
              <Subscriptions userInformationData={userInformationData} />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card>
            <Typography sx={{ ml: 5, mt: 5 }}>{t("Address")}</Typography>
            <Box
              className={classes.box}
              sx={{ justifyContent: onlySmallScreen ? "center" : "unset" }}
            >
              <MainAddress
                refetch={refetch}
                userInformationData={userInformationData}
              />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card>
            <Typography sx={{ ml: 5, mt: 5 }}>
              {t("Correspondence Address")}
            </Typography>
            <Box
              className={classes.box}
              sx={{ justifyContent: onlySmallScreen ? "center" : "unset" }}
            >
              <CorrespondenceAddress
                refetch={refetch}
                userInformationData={userInformationData}
              />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card sx={{ minHeight: onlySmallScreen ? "unset" : "350px" }}>
            <Typography sx={{ ml: 5, mt: 5 }}>{t("Others Details")}</Typography>
            <Box
              className={classes.box}
              sx={{ justifyContent: onlySmallScreen ? "center" : "unset" }}
            >
              <OthersDetails
                refetch={refetch}
                userInformationData={userInformationData}
              />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          sm={6}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card sx={{ minHeight: onlySmallScreen ? "unset" : "350px" }}>
            <Typography sx={{ ml: 5, mt: 5 }}>{t("Membership")}</Typography>
            <Box
              className={classes.box}
              sx={{ justifyContent: onlySmallScreen ? "center" : "unset" }}
            >
              <Membership
                refetch={refetch}
                userInformationData={userInformationData}
              />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          sm={12}
          xl={6}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card
            sx={{
              minHeight: onlyExtraLargeScreen ? "unset" : "350px",
            }}
          >
            <Typography sx={{ ml: 5, mt: 5 }}>
              {t("Login Parameters")}
            </Typography>
            <Box
              sx={{
                justifyContent: onlySmallScreen ? "center" : "unset",
                m: 5,
              }}
            >
              <LoginParameters userInformationData={userInformationData} />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          xl={3}
          md={6}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card sx={{ minHeight: onlySmallScreen ? "unset" : "350px" }}>
            <Typography sx={{ ml: 5, mt: 5 }}>{t("Points")}</Typography>
            <Box
              sx={{
                maxWidth: 600,
                m: 5,
                mb: 0,
              }}
            >
              <Points totalPointsData={totalPointsData} />
              <CustomButton
                text={!isPointsOpened ? t("Show Details") : t("Close")}
                style={{ m: 2 }}
                onClickFunction={() => {
                  setIsTransactionsOpened(false);
                  setIsPointsOpened(!isPointsOpened);
                }}
              />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          xl={3}
          md={6}
          sx={{
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card sx={{ minHeight: onlySmallScreen ? "unset" : "350px" }}>
            <Typography sx={{ ml: 5, mt: 5 }}>
              {t("Paid Transactions")}
            </Typography>
            <Box
              sx={{
                maxWidth: 600,
                m: 5,
              }}
            >
              <Transactions userTransactions={userTransactions} />
              <CustomButton
                text={!isTransactionsOpened ? t("Show Details") : t("Close")}
                style={{ m: 2 }}
                onClickFunction={() => {
                  setIsPointsOpened(false);
                  setIsTransactionsOpened(!isTransactionsOpened);
                }}
              />
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          sm={12}
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            width: onlySmallScreen ? "100%" : "unset",
            mr: onlySmallScreen ? 5 : "unset",
            mb: onlySmallScreen ? 5 : "unset",
            pr: 1,
          }}
        >
          <Card sx={{ minHeight: "600px", minWidth: "800px" }}>
            <UserValuesTable
              userInformationData={userInformationData}
              isTransactionsOpened={isTransactionsOpened}
              isPointsOpened={isPointsOpened}
              refetch={refetch}
            />
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
}

export default EditUser;
