import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import useSetPermissionsToSelectedUsersMutation from "hooks/useSetPermissionsToSelectedUsersMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";

interface selectedRowsDataTypes {
  id: number | undefined;
  email: string;
  firstName: string;
  lastName: string;
}

interface RedactorUsersListTypes {
  allRedactorUsers: any;
  allRedactorUsersLoading: boolean;
  selectedUserId: number;
}

function RedactorUsersList({
  allRedactorUsers,
  allRedactorUsersLoading,
  selectedUserId,
}: RedactorUsersListTypes) {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "email",
      minWidth: 200,
      headerName: t("Email") as string,
    },
    {
      flex: 0.5,
      minWidth: 120,
      field: "firstName",
      headerName: t("First Name") as string,
    },
    {
      flex: 0.5,
      minWidth: 120,
      field: "lastName",
      headerName: t("Last Name") as string,
    },
  ];
  const [selectedElements, setSelectedElements] = useState<any[]>([]);
  const { mutate: copyEditPermissions } =
    useSetPermissionsToSelectedUsersMutation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [openErrorNotChosenUser, setOpenErrorNotChosenUser] = useState(false);

  const assignPermissionsToSelectedUsers = () => {
    const userIdTo = selectedElements;
    const userIdFrom = selectedUserId;

    if (userIdFrom && userIdTo && userIdTo.length !== 0) {
      copyEditPermissions(
        { 0: { userIdTo, userIdFrom } },
        {
          onSuccess: () => {
            setOpenErrorNotChosenUser(false);
            setOpenError(false);
            setOpenSuccess(true);
          },
          onError: () => {
            setOpenSuccess(false);
            setOpenError(true);
            setMessage("Message Error Copy Permission");
          },
        }
      );
    } else {
      setOpenSuccess(false);
      setMessage("Message Error Not Chosen User");
      setOpenErrorNotChosenUser(true);
    }
  };

  const onRowsSelectionHandler = (ids: selectedRowsDataTypes[]) => {
    const selectedRowsData = ids.map((id: selectedRowsDataTypes) =>
      allRedactorUsers?.["hydra:member"].find(
        (row: { id: selectedRowsDataTypes }) => row.id === id
      )
    );
    const result = selectedRowsData.map(({ id }) => id);

    setSelectedElements(result);
  };

  return (
    <>
      <TableBasic
        paginationMode="client"
        columns={columns}
        loading={allRedactorUsersLoading}
        onCheckboxClick={assignPermissionsToSelectedUsers}
        onCheckboxSelect={onRowsSelectionHandler}
        rows={allRedactorUsers?.["hydra:member"]}
        title={t("Users")}
        buttonTxt={t("Assign permissions to selected users")}
      />
      <ChangeParametersModal
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        setOpenErrorNotChosenUser={setOpenErrorNotChosenUser}
        openSuccess={openSuccess}
        openError={openError}
        openErrorNotChosenUser={openErrorNotChosenUser}
        errorMessage={t("Message Error Copy Permission")}
        errorNotChosenUserMessage={t("Message Error Not Chosen User")}
        successMessage={t("Message Success Copy Permission")}
      />
    </>
  );
}

export default RedactorUsersList;
