import React, { useState } from "react";
import {
  TableBody,
  Table,
  Card,
  TableHead,
  TableCell,
  MenuItem,
  Select,
  useMediaQuery,
  Button,
  FormControl,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import CardHeader from "@mui/material/CardHeader";
import useGetCrmTypesQuery from "hooks/useGetCrmTypesQuery";
import { map } from "lodash";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import TableRowValues from "components/PageContactTypes/TableRowValues";
import usePostCrmTypeMutation from "hooks/usePostCrmTypeMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Loader } from "components/Loader/Loader";

interface FormInputs {
  newTypeValue: number;
  newTypeName: string;
}

function ContactTypes() {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [addCrmType, setAddCrmType] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const { mutate: postCrmTypes, isLoading: changePostTypesLoading } =
    usePostCrmTypeMutation();
  const { data: getCrmsTypes, refetch } = useGetCrmTypesQuery(
    {
      query: { "orderBy[order]": "asc", status: "0", pagination: false },
    },
    {
      onSettled: () => {
        setSuccessDelete(false);
      },
    }
  );
  if (successDelete) {
    refetch();
  }
  const formParams = {
    newTypeValue: 0,
    newTypeName: "",
  };
  const {
    control: AddCrmControl,
    handleSubmit,
    reset,
  } = useForm<FormInputs>({
    defaultValues: formParams,
  });
  const onSubmit = ({ newTypeValue, newTypeName }: FormInputs) => {
    if (newTypeName !== "") {
      postCrmTypes(
        {
          name: newTypeName,
          kindValue: newTypeValue,
        },
        {
          onSuccess: () => {
            setOpenSuccess(true);
            setSuccessDelete(true);
            setAddCrmType(false);
            reset();
          },
          onError: () => {
            setOpenSuccess(false);
            setOpenError(true);
          },
        }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {changePostTypesLoading && <Loader />}
      <Card sx={{ minWidth: "600px", maxWidth: "1000px", overflow: "hidden" }}>
        <CardHeader title={t("Contact Types")} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Contact Type")}</TableCell>
              <TableCell>{t("Type")}</TableCell>
              <TableCell>{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(getCrmsTypes?.["hydra:member"], (value) => (
              <TableRowValues
                data={value}
                setSuccessDelete={setSuccessDelete}
              />
            ))}
            {addCrmType && (
              <TableRow>
                <TableCell sx={{ position: "relative" }}>
                  <Controller
                    control={AddCrmControl}
                    name="newTypeName"
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        sx={{
                          width: onlySmallScreen ? "100%" : 320,
                        }}
                        size="small"
                        label={t("Name-v2")}
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ position: "relative" }}>
                  <FormControl>
                    <Controller
                      control={AddCrmControl}
                      name="newTypeValue"
                      render={({ field: { value, onChange } }) => (
                        <Select
                          sx={{ width: onlySmallScreen ? 150 : 200 }}
                          defaultValue={0}
                          size="small"
                          id="multiple-checkbox"
                          value={value}
                          onChange={onChange}
                        >
                          <MenuItem value={0}>{t("Normal")}</MenuItem>
                          <MenuItem value={1}>{t("Discount")}</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className="contact-types-buttons-wrapper">
                  <>
                    <Button
                      className="contact-types-button"
                      sx={{
                        left: "10px",
                      }}
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setAddCrmType(false);
                        reset();
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      className="contact-types-button"
                      sx={{
                        left: "100px",
                      }}
                      size="small"
                      type="submit"
                      variant="outlined"
                      color="secondary"
                    >
                      {t("You Save")}
                    </Button>
                  </>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setAddCrmType(true);
                  }}
                >
                  {t("Add")}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ChangeParametersModal
          openSuccess={openSuccess}
          openError={openError}
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
          errorMessage={t("Message Error Added Entry")}
          successMessage={t("Message Success Added Entry")}
        />
      </Card>
    </form>
  );
}

export default ContactTypes;
