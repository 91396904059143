import React from "react";
import { FormControl, Select, MenuItem, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import {
  JunctionProfilesType,
  TableCellCustomLong,
  TableCellCustomShort,
  TableCellCustomTitle,
  TextFieldCustom,
  useStyles,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";
import { formattedDate } from "helpers/Global";

function JunctionProfilesContactDetails({
  control,
  duplicateUserData,
}: JunctionProfilesType) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <TableBody>
      <TableRow>
        <TableCellCustomTitle>{t("Contact Details")}</TableCellCustomTitle>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.firstName} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("First Name")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.lastName} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Last Name")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.email} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Email")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="email"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.emailSii} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Email Sii")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="emailSii"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.landlinePhone} />
        </TableCellCustomLong>
        <TableCellCustomShort>
          {t("Landline Phone Number")}
        </TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="landlinePhone"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.mobilePhone} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Mobile Phone Number")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="mobilePhone"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.additionalPhone} />
        </TableCellCustomLong>
        <TableCellCustomShort>
          {t("Additional Phone Number")}
        </TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="additionalPhone"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomTitle>{t("Address")}</TableCellCustomTitle>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.zipCode} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Zip Code")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="zipCode"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.city} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("City")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="city"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.street} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Street")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="street"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomTitle>
          {t("Correspondence Address")}
        </TableCellCustomTitle>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.zipCodeCorrespondence} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Zip Code")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="zipCodeCorrespondence"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.cityCorrespondence} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("City")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="cityCorrespondence"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.streetCorrespondence} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Street")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="streetCorrespondence"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomTitle>{t("Others Details")}</TableCellCustomTitle>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom
            value={formattedDate(duplicateUserData?.birthDate, "yyyy-MM-dd")}
          />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Birth Date")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="birthDate"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  type="text"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom value={duplicateUserData?.nationality} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Nationality")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="nationality"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  size="small"
                  className={classes.junctionProfilesTextFieldClass}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextFieldCustom
            value={
              duplicateUserData?.genderValue === 1
                ? t("Woman")
                : duplicateUserData?.genderValue === 2
                ? t("Man")
                : t("Undefined")
            }
          />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Gender")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="genderValue"
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value === 0 ? 3 : value || " "}
                  onChange={onChange}
                  size="small"
                  id="gender-select"
                  labelId="user-gender-selectbox-label"
                >
                  <MenuItem value={3}>{t("Undefined")}</MenuItem>
                  <MenuItem value={1}>{t("Woman")}</MenuItem>
                  <MenuItem value={2}>{t("Man")}</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
    </TableBody>
  );
}

export default JunctionProfilesContactDetails;
