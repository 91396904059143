import React from "react";
import { FormControl, FormControlLabel, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import TableRow from "@mui/material/TableRow";
import {
  JunctionProfilesType,
  TableCellCustomLong,
  TableCellCustomShort,
  TableCellCustomTitle,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";
import { concat, filter, includes, map } from "lodash";
import { roles as rolesObjects } from "constans/Constans";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { formattedDate, formatValueDateTimeOther } from "helpers/Global";

function JunctionProfilesPermissionsDetails({
  control,
  duplicateUserData,
  mainUserData,
}: JunctionProfilesType) {
  const { t } = useTranslation();
  const roleValue = (nameValue: string) => {
    return nameValue === "ROLE_REDACTOR_LEVEL"
      ? `ROLE_REDACTOR_LEVEL_${mainUserData?.redactorRoleLevel}`
      : nameValue;
  };

  return (
    <TableBody>
      <TableRow>
        <TableCellCustomTitle>{t("Permissions")}</TableCellCustomTitle>
      </TableRow>
      {map(rolesObjects, ({ name, nameValue }) => {
        if (nameValue !== "ROLE_SUPER_USER") {
          return (
            <TableRow key={nameValue}>
              <TableCellCustomLong>
                <Checkbox
                  checked={includes(
                    duplicateUserData?.roles,
                    nameValue === "ROLE_REDACTOR_LEVEL"
                      ? `ROLE_REDACTOR_LEVEL_${duplicateUserData?.redactorRoleLevel}`
                      : nameValue
                  )}
                />
              </TableCellCustomLong>
              <TableCellCustomShort>{name}</TableCellCustomShort>
              <TableCellCustomLong>
                <FormControl>
                  <Controller
                    control={control}
                    name="userRoles"
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        onChange={() => {
                          onChange(
                            includes(value, roleValue(nameValue))
                              ? filter(
                                  value,
                                  (val) => val !== roleValue(nameValue)
                                )
                              : concat(value, roleValue(nameValue))
                          );
                        }}
                        checked={includes(value, roleValue(nameValue))}
                      />
                    )}
                  />
                </FormControl>
              </TableCellCustomLong>
            </TableRow>
          );
        }
      })}
      <TableRow>
        <TableCellCustomLong>
          <Checkbox checked={duplicateUserData?.active} />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Active-v2")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl>
            <Controller
              control={control}
              name="isActive"
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  onChange={(e) => onChange(e.target.checked)}
                  checked={!!value}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextField
            size="small"
            multiline
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: "100%" }}
            value={
              duplicateUserData?.password
                ? `Hash: ${duplicateUserData?.password}`
                : undefined
            }
          />
          <TextField
            value={
              duplicateUserData?.passwordSetDate
                ? formattedDate(
                    duplicateUserData?.passwordSetDate,
                    formatValueDateTimeOther
                  )
                : t("Password Has Not Been Changed")
            }
            variant="standard"
            sx={{ width: 200, m: 2 }}
            label={t("Password Changed Date")}
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: true, disableUnderline: true }}
          />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Password")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl>
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange } }) => (
                <FormControlLabel
                  control={
                    <Checkbox onChange={(e) => onChange(e.target.checked)} />
                  }
                  label={t("Get From Left")}
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
    </TableBody>
  );
}

export default JunctionProfilesPermissionsDetails;
