import { createContext, useState, ReactNode } from "react";
import { PaletteMode } from "@mui/material";
import themeConfig from "config/themeConfig";
import { Skin, ThemeColor, ContentWidth, AppBar } from "types/typesTheme";

export type Settings = {
  mode: PaletteMode;
  appBar?: AppBar;
  appBarBlur?: boolean;
  skin?: Skin;
  themeColor: ThemeColor;
  contentWidth: ContentWidth;
};

export type SettingsContextValue = {
  settings: Settings;
  saveSettings: (updatedSettings: Settings) => void;
};

const initialSettings: Settings = {
  themeColor: "primary",
  mode: themeConfig.mode,
  contentWidth: themeConfig.contentWidth,
};

export const SettingsContext = createContext<SettingsContextValue>({
  saveSettings: () => null,
  settings: initialSettings,
});

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [settings, setSettings] = useState<Settings>({ ...initialSettings });
  const saveSettings = (updatedSettings: Settings) => {
    setSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider value={{ settings, saveSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;
