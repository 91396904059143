import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components, operations } from "schema/schema";

const path = "/api/v1/polls";

type Payload = components["schemas"]["Poll.jsonld-poll.extended"];
type Response = operations["api_apiv1polls_id_put"]["responses"];

type Params = {
  payload: Payload;
};

const postPoll = async ({ payload }: Params) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function usePostPollMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(postPoll, options);
}
