import Box from "@mui/material/Box";
import { Settings } from "config/settingsTheme";
import UserDropdown from "components/AppBar/UserDropdown/UserDropdown";
import ModeToggler from "components/AppBar/ModeToggler/ModeToggler";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "mdi-material-ui/Menu";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FontSizeToggler from "components/AppBar/FontSizeToggler/FontSizeToggler";

interface Props {
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
  const { settings, saveSettings, toggleNavVisibility } = props;
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        className="actions-left"
        sx={{ mr: 2, display: "flex", alignItems: "center" }}
      >
        {hidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75 }}
            onClick={toggleNavVisibility}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
      </Box>
      <Box
        className="actions-left"
        sx={{ mr: 2, display: "flex", alignItems: "center" }}
      >
        <FontSizeToggler />
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <UserDropdown />
      </Box>
    </Box>
  );
};

export default AppBarContent;
