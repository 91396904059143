import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormInputs } from "pages/Users/Users";
import { roles } from "constans/Constans";
import { useTranslation } from "react-i18next";
import { Control, Controller } from "react-hook-form";

type Props = { control: Control<FormInputs> };

function Roles({ control }: Props) {
  const { t } = useTranslation();

  return (
    <FormControl sx={{ width: 250 }}>
      <InputLabel id="users-roles-multiple-checkbox-label">
        {t("Get Roles Of User")}
      </InputLabel>
      <Controller
        control={control}
        name="roleName"
        render={({ field: { value, onChange } }) => (
          <Select
            multiple
            label={t("Get Roles Of User")}
            value={value}
            sx={{ width: 250 }}
            onChange={onChange}
            id="users-roles-multiple-checkbox"
            labelId="users-roles-multiple-checkbox-label"
          >
            {roles.map((role) => (
              <MenuItem key={role.id} id={role.name} value={role.nameValue}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}

export default Roles;
