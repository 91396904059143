import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { Box, Divider, useMediaQuery } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import PersonalData from "components/PageAddUser/PersonalData";

function EditUser() {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const theme = useTheme();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
      }}
    >
      <CardHeader title={t("Add User")} />
      <Divider />
      <Grid
        container
        spacing={6}
        item
        sx={{
          m: onlyMediumScreen ? 0 : 4,
          justifyContent: "center",
          width: onlySmallScreen ? "100%" : "unset",
          pl: onlyMediumScreen ? 0 : "unset",
        }}
      >
        <Box
          sx={{
            maxWidth: 800,
            display: "flex",
            flexDirection: "row",
            justifyContent: onlySmallScreen ? "center" : "unset",
            m: 5,
          }}
        >
          <PersonalData />
        </Box>
      </Grid>
    </Card>
  );
}

export default EditUser;
