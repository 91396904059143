import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { Box, Divider, useMediaQuery } from "@mui/material";
import { useSettings } from "hooks/useSettings";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import VerificationForm from "components/PageMembershipVerification/VerificationForm";
import logo from "images/logo-sii.png";
import backgroundMap from "images/world-map.png";

function MembershipVerification() {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const theme = useTheme();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
        height: "100%",
      }}
    >
      <CardHeader
        sx={{ textAlign: "center" }}
        title={t("Membership Verification")}
      />
      <Divider />
      <Grid
        container
        spacing={6}
        item
        sx={{
          my: 1,
          alignItems: "center",
          flexDirection: "column",
          width: onlySmallScreen ? "100%" : "unset",
          pl: onlyMediumScreen ? 0 : "unset",
          m: onlySmallScreen ? 0 : "unset",
        }}
      >
        <img src={logo} alt="logo" style={{ width: "200px" }} />
        <Box
          sx={{
            position: "relative",
            width: onlySmallScreen ? "100%" : "650px",
          }}
        >
          <img
            src={backgroundMap}
            alt="logo"
            style={{
              width: onlySmallScreen ? "100%" : "650px",
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0.3,
            }}
          />
          <VerificationForm />
        </Box>
      </Grid>
    </Card>
  );
}

export default MembershipVerification;
