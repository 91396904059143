import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  sectionTitle: {
    textAlign: "center",
    marginTop: "1.5rem",
    fontWeight: "bold",
  },
  formControlStyles: {
    width: "100%",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    paddingLeft: "1.5rem",
    flexDirection: "row",
  },
  "@media (max-width: 600px)": {
    formControlStyles: {
      paddingLeft: "0.15rem",
    },
  },
}));
