import React, { useState, SyntheticEvent, Fragment } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CogOutline from "mdi-material-ui/CogOutline";
import LogoutVariant from "mdi-material-ui/LogoutVariant";
import { useTranslation } from "react-i18next";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import useLogoutMutation from "hooks/useLogoutMutation";
import { routes } from "routing/routes";
import { Loader } from "components/Loader/Loader";
import useAuthenticationUser from "hooks/useAuthenticationUser";
import { ErrorTypes } from "types/types";
import { generatePath } from "react-router";

const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { t } = useTranslation();
  const [message, setMessage] = useState<ErrorTypes>();
  let navigate: NavigateFunction = useNavigate();
  const { mutate: logout, isLoading } = useLogoutMutation();
  const { data } = useAuthenticationUser();
  const userEmail = data?.user_identifier;
  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const styles = {
    py: 2,
    px: 4,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      fontSize: "1.375rem",
      color: "text.secondary",
    },
  };

  const handleLogout = () => {
    logout(undefined, {
      onSuccess: () => {
        localStorage.removeItem("amILogged");
        navigate(routes.LOGIN.path);
      },
      onError: (error) => {
        setMessage(error);
      },
    });
    handleDropdownClose();
  };

  return (
    <Fragment>
      <Badge
        overlap="circular"
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: "pointer" }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Avatar
          alt="user"
          onClick={handleDropdownOpen}
          sx={{ width: 40, height: 40 }}
          src=""
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ "& .MuiMenu-paper": { width: 230, marginTop: 4 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: 1,
        }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                marginLeft: 3,
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>{userEmail}</Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.8rem", color: "text.disabled" }}
              >
                {t("Admin")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 0, mb: 1 }} />
        <Divider />
        <Link
          to={generatePath(routes.EDIT_USER.path, { id: data?.user_id })}
          style={{ textDecoration: "none" }}
        >
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
            <Box sx={styles}>
              <CogOutline sx={{ marginRight: 2 }} />
              {t("Account Settings")}
            </Box>
          </MenuItem>
        </Link>
        <MenuItem sx={{ p: 0 }} onClick={() => handleLogout()}>
          <Box sx={styles}>
            <LogoutVariant
              sx={{
                marginRight: 2,
                fontSize: "1.375rem",
                color: "text.secondary",
              }}
            />
            {t("Logout")}
          </Box>
        </MenuItem>
        {isLoading && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader />
          </Box>
        )}
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
