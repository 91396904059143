import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "/api/v1/departments";
const queryKey = "departments";

type Response =
  operations["api_apiv1departments_get_collection"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter =
  operations["api_apiv1departments_get_collection"]["parameters"];

const getDepartments = async (parameters: Parameter) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetDepartmentsQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getDepartments(parameters),
    options
  );
}
