import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext, useFieldArray } from "react-hook-form";
import { map } from "lodash";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import Answers from "components/PageEditForms/EditSingleForm/EditFormAnswer/EditFormAnswer";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { formValueTypes } from "constans/Constans";
import { Controller } from "react-hook-form";
import { InputTextValue } from "components/PageEditForms/EditSingleForm/Components/InputTextValue";
import InputSelectStatusValue from "components/PageEditForms/EditSingleForm/Components/InputSelectStatusValue";
import InputCheckBoxValue from "components/PageEditForms/EditSingleForm/Components/InputCheckBoxValue";
import CustomButton from "components/Buttons/CustomButton";

export default function Fields() {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "questions",
    keyName: "_id",
  });
  const questionWatch = getValues("questions");
  const childrenRef = React.useRef({});
  const isLoading = false;
  const useStrictDroppable = (loading: boolean) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
      let animation: any;
      if (!loading) {
        animation = requestAnimationFrame(() => setEnabled(true));
      }
      return () => {
        cancelAnimationFrame(animation);
        setEnabled(false);
      };
    }, [loading]);
    return [enabled];
  };
  const reorder = (result: DropResult) => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const sourceParentId = source.droppableId;
    const destParentId = destination.droppableId;
    const sourceIndex = source.index;
    const destIndex = destination.index;
    if (type === "parentContainer") {
      move(sourceIndex, destIndex);
      const updatedFields = [...fields];
      const [removed] = updatedFields.splice(sourceIndex, 1);
      updatedFields.splice(destIndex, 0, removed);
      updatedFields.forEach((field: any, index) => {
        field.position = index + 1;
      });
      fields.map((question, index) =>
        setValue(`questions[${index}].sequence`, index + 1)
      );
    } else {
      // @ts-ignore
      const reorderChild = childrenRef.current[source.droppableId];
      if (reorderChild && sourceParentId === destParentId) {
        reorderChild(sourceIndex, destIndex);
      }
    }
  };
  const [enabled] = useStrictDroppable(isLoading);
  useEffect(() => {
    fields.map((question, index) =>
      setValue(`questions[${index}].sequence`, index + 1)
    );
  }, []);
  return (
    <>
      <DragDropContext onDragEnd={reorder}>
        {enabled && (
          <Droppable droppableId="parent" type="parentContainer">
            {(provided) => (
              <ul
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  paddingLeft: "0.5rem",
                  overflowX: "auto",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                  backgroundColor: "rgba(56,65,65,0.1)",
                }}
              >
                {map(fields, (question, questionIndex) => (
                  <Draggable
                    key={question._id}
                    draggableId={question._id}
                    index={questionIndex}
                  >
                    {(provided, snapshot) => {
                      let transform =
                        provided?.draggableProps?.style?.transform;
                      if (snapshot.isDragging && transform) {
                        transform = transform.replace(/\(.+\,/, "(0,");
                      }
                      const style = {
                        ...provided.draggableProps.style,
                        transform,
                      };
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={style}
                          className="form-question-wrapper"
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{t("Question")}</TableCell>
                                <TableCell>{t("Type")}</TableCell>
                                <TableCell>{t("Required")}</TableCell>
                                <TableCell>{t("Visibility")}</TableCell>
                                <TableCell>{t("Operation")}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <label
                                    htmlFor={`questions[${questionIndex}].text`}
                                  >
                                    {t("Question")} {questionIndex + 1}:
                                  </label>
                                  <InputTextValue
                                    name={`questions[${questionIndex}].text`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`questions[${questionIndex}].typePosition`}
                                    control={control}
                                    render={({
                                      field: { value, onChange },
                                    }) => (
                                      <Select
                                        size="small"
                                        disabled={
                                          questionWatch[questionIndex]
                                            .enteredAnswerToQuestion
                                        }
                                        className="edit-form-status"
                                        id={`questions[${questionIndex}].typePosition`}
                                        value={value}
                                        onChange={onChange}
                                      >
                                        {formValueTypes.map((formValue) => (
                                          <MenuItem
                                            key={formValue.value}
                                            value={formValue.value}
                                          >
                                            {formValue.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <InputCheckBoxValue
                                    name={`questions[${questionIndex}].required`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <InputSelectStatusValue
                                    name={`questions[${questionIndex}].status`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <CustomButton
                                    text={t("Remove Question")}
                                    style={{ m: 2 }}
                                    size="small"
                                    color="error"
                                    onClickFunction={() =>
                                      remove(questionIndex)
                                    }
                                  />
                                  <OpenWithIcon sx={{ opacity: "0.7" }} />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <Answers
                            childrenRef={childrenRef}
                            questionIndex={questionIndex}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        )}
      </DragDropContext>
      <CustomButton
        text={t("Add Question")}
        style={{
          mx: 2,
          mt: 2,
          marginBottom: "150px",
        }}
        size="small"
        color="success"
        onClickFunction={() =>
          append({
            typePosition: 0,
            required: false,
            status: 1,
            id: undefined,
            sequence: questionWatch.length + 1,
            name: "",
            answerVariants: [],
          })
        }
      />
    </>
  );
}
