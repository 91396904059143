import { routes } from "routing/routes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useLogoutMutation from "hooks/useLogoutMutation";
import useWhoIAmQuery from "hooks/useWhoIAmQuery";
import { ErrorTypes } from "types/types";

export default function useAuthenticationUser() {
  let navigate = useNavigate();
  const [message, setMessage] = useState<ErrorTypes>();
  const { mutate: logout } = useLogoutMutation();

  return useWhoIAmQuery({
    onSuccess: (data) => {
      if (!data?.user_id) {
        logout(undefined, {
          onSuccess: () => {
            navigate(routes.LOGIN.path);
          },
          onError: (error) => {
            setMessage(error);
          },
        });
      }
    },
    onError: () => {
      logout(undefined, {
        onSuccess: () => {
          navigate(routes.LOGIN.path);
        },
        onError: (error) => {
          setMessage(error);
        },
      });
    },
  });
}
