import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { operations } from "schema/schema";
import { Loader } from "components/Loader/Loader";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Controller, useForm } from "react-hook-form";
import useGetCrmTypesQuery from "hooks/useGetCrmTypesQuery";
import usePostCrmMutation from "hooks/usePostCrmMutation";
import useAuthenticationUser from "hooks/useAuthenticationUser";
import dayjs from "dayjs";
import { formatValueDateTime } from "helpers/Global";
import CustomButton from "components/Buttons/CustomButton";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
    },
  },
};

interface CrmTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
}

interface FormInputs {
  crmType: number;
  content: string;
}

function AddInscription({ userInformationData }: CrmTypes) {
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const { mutate: postCrmTypes, isLoading: changePostTypesLoading } =
    usePostCrmMutation();
  const { data: authenticationUser } = useAuthenticationUser();
  const { data: getCrmsTypes } = useGetCrmTypesQuery({
    query: { "orderBy[order]": "asc", status: "0", pagination: false },
  });
  let date = dayjs(new Date()).format(formatValueDateTime);

  const formParams = {
    toUser: "",
    crmType: undefined,
    content: "",
  };

  let {
    control: AddCrmControl,
    handleSubmit,
    reset,
  } = useForm<FormInputs>({
    defaultValues: formParams,
  });

  const onSubmit = ({ content, crmType }: FormInputs) => {
    postCrmTypes(
      {
        toUser: `/api/v1/users/${String(userInformationData?.id)}`,
        user: `/api/v1/users/${authenticationUser?.user_id}`,
        content: content,
        type: crmType ? `/api/v1/crm_types/${String(crmType)}` : undefined,
        createdAt: date,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
        },
      }
    );
  };

  useEffect(() => {
    reset(formParams);
  }, [openSuccess, openError]);

  return (
    <div>
      {changePostTypesLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={AddCrmControl}
          name="content"
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              multiline
              minRows={3}
              sx={{ width: "100%" }}
              onChange={onChange}
            />
          )}
        />

        <FormControl style={{ marginTop: "15px" }}>
          <InputLabel id="add-inscriptions-user-select-label">
            {t("Type")}
          </InputLabel>
          <Controller
            control={AddCrmControl}
            name="crmType"
            render={({ field: { value, onChange } }) => (
              <Select
                label={t("Type")}
                value={value}
                sx={{ width: 250 }}
                onChange={onChange}
                MenuProps={MenuProps}
                labelId="add-inscriptions-user-select-label"
              >
                {getCrmsTypes?.["hydra:member"].map((getCrmsTypes) => (
                  <MenuItem key={getCrmsTypes.id} value={getCrmsTypes.id}>
                    {getCrmsTypes.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        <FormControl style={{ marginTop: "15px" }}>
          <CustomButton text={t("You Save")} style={{ m: 2 }} type="submit" />
        </FormControl>
      </form>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Added Entry")}
        successMessage={t("Message Success Added Entry")}
      />
    </div>
  );
}
export default AddInscription;
