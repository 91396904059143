import React from "react";
import { Box, Dialog, useMediaQuery } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import { useSettings } from "hooks/useSettings";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

export interface TwoStepsAcceptModalTypes {
  isOpen: boolean;
  message: string;
  executeButtonName: string;
  executeFunction: () => void;
  setIsOpen: (value: boolean) => void;
}

function TwoStepsAcceptModal({
  message,
  executeFunction,
  isOpen,
  executeButtonName,
  setIsOpen,
}: TwoStepsAcceptModalTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { settings } = useSettings();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = () => {
    executeFunction();
    setIsOpen(false);
  };
  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        style: {
          backgroundColor: theme.palette.customColors.modalBgc,
        },
      }}
    >
      <Box
        className="array-modal"
        sx={{
          width: onlySmallScreen ? "90vw" : 400,
          px: onlySmallScreen ? 5 : "unset",
        }}
      >
        <Alert
          severity="warning"
          sx={{
            width: "100%",
            backgroundColor:
              settings.mode === "light"
                ? "rgb(250,243,232)"
                : "rgb(121,112,76)",
            opacity: "1",
            justifyContent: "center",
          }}
        >
          {message}
          <Box sx={{ mt: 4, ml: "19px" }}>
            <Button
              size="large"
              onClick={() => handleClick()}
              variant="contained"
              color="secondary"
              sx={{ m: 2 }}
            >
              <p>{executeButtonName}</p>
            </Button>
            <Button
              size="large"
              onClick={() => setIsOpen(false)}
              variant="contained"
              color="secondary"
              sx={{ m: 2 }}
            >
              <p>{t("Cancel")}</p>
            </Button>
          </Box>
        </Alert>
      </Box>
    </Dialog>
  );
}
export default TwoStepsAcceptModal;
