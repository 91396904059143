import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";
import { UserIdType } from "types/types";

const path = "/api/v1/user_permissions/";

type Payload = components["schemas"]["UserPermissionEditSchemaExample"];
type Response = components["schemas"]["UserPermissionEditCopyResponseSchema"];
type UserId = UserIdType;

type Params = {
  payload: Payload;
  userId: UserId;
};

const putEditPermissions = async ({ userId, payload }: Params) => {
  const { data } = await getApiClient().put(`${path}${userId}`, payload);
  return data;
};

export default function useEditPermissionsMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putEditPermissions, options);
}
