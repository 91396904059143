import React, { useState, MouseEvent } from "react";
import { PencilOutline } from "mdi-material-ui";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DotsVertical from "mdi-material-ui/DotsVertical";
import DeleteOutline from "mdi-material-ui/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import { generatePath } from "react-router";
import { routes } from "routing/routes";
import useDeleteUserMutation from "hooks/useDeleteUserMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";

interface ActionsButtonType {
  id: number;
  setFilterEnabled: (value: boolean) => void;
}

const ActionsButton = ({ id, setFilterEnabled }: ActionsButtonType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openTwoStepsAcceptModal, setOpenTwoStepsAcceptModal] = useState(false);
  const [openError, setOpenError] = useState(false);
  const { t } = useTranslation();
  let { userId } = useParams();
  userId = JSON.stringify(id);
  const { mutate } = useDeleteUserMutation();
  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    mutate(id, {
      onSuccess: () => {
        setOpenSuccess(true);
        setFilterEnabled(true);
      },
      onError: () => {
        setOpenSuccess(false);
        setOpenError(true);
      },
    });
    handleRowOptionsClose();
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <DotsVertical />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem sx={{ p: 0 }}>
          <Link
            to={generatePath(routes.EDIT_USER.path, { id: userId })}
            style={{ textDecoration: "none", width: "100%" }}
          >
            <MenuItem>
              <PencilOutline fontSize="small" sx={{ mr: 2 }} />
              {t("Edit")}
            </MenuItem>
          </Link>
        </MenuItem>
        <MenuItem onClick={() => setOpenTwoStepsAcceptModal(true)}>
          <DeleteOutline fontSize="small" sx={{ mr: 2 }} />
          {t("Delete")}
        </MenuItem>
      </Menu>
      <TwoStepsAcceptModal
        message={t("Do You Want To Delete User?")}
        executeButtonName={t("Delete")}
        executeFunction={handleDelete}
        isOpen={openTwoStepsAcceptModal}
        setIsOpen={setOpenTwoStepsAcceptModal}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Delete User")}
        successMessage={t("Message Success Delete User")}
      />
    </>
  );
};
export default ActionsButton;
