import React from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditPermissionSpecialSelect from "components/PageEditPermissions/EditPermissionsSectionSpecial/EditPermissionSpecialSelect";
import { components } from "schema/schema";

export interface RowTypes {
  id: number;
  name: string;
  permission: number;
}
type parametersPermissionsType = components["schemas"]["LoginLog.jsonld"];

type Props = {
  parametersPermissions: parametersPermissionsType | undefined;
  selectedUserId: number;
};

function EditPermissionsSectionSpecial({
  parametersPermissions,
  selectedUserId,
}: Props) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("Parameter Name")}</TableCell>
            <TableCell align="center">{t("Value")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parametersPermissions
            ? (parametersPermissions as unknown as any[]).map(
                (parameters: RowTypes) => (
                  <TableRow key={parameters.id} sx={{ borderBottom: "none" }}>
                    <EditPermissionSpecialSelect
                      row={parameters}
                      selectedUserId={selectedUserId}
                    />
                  </TableRow>
                )
              )
            : undefined}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EditPermissionsSectionSpecial;
