import React from "react";
import { TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { regexTextValues } from "helpers/Global";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  label: string;
  control: Control<any>;
  editedValue?: boolean;
  variant?: "standard" | "outlined";
  error?: boolean;
  isShrink?: boolean;
}

function TextFieldValidate({
  name,
  label,
  control,
  editedValue,
  variant,
  error,
  isShrink,
}: Props) {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          value={value}
          onChange={onChange}
          variant={variant}
          type="text"
          label={label}
          InputLabelProps={{ shrink: isShrink }}
          error={
            ((!Boolean(regexTextValues.test(value)) && value !== "") ||
              !!error) &&
            editedValue
          }
          helperText={
            !Boolean(regexTextValues.test(value)) && value !== "" && editedValue
              ? t("Invalid Value")
              : ""
          }
          InputProps={{
            disabled: !editedValue,
          }}
          inputProps={{
            maxLength: 15,
          }}
        />
      )}
    />
  );
}

export default TextFieldValidate;
