import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "api/v1/form_answers_csv";
const queryKey = "formHistoryCSV";

type Response = operations["formAnswerCsv"];
type Id = operations["formAnswerCsv"]["parameters"]["path"]["id"];

const getFormHistoryCsvQuery = async (id: Id) => {
  const { data } = await getApiClient().get(`${path}/${id}`);
  return data;
};

export default function useGetFormHistoryCsvQuery(
  Id: Id,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, Id],
    () => getFormHistoryCsvQuery(Id),
    options
  );
}
