import React from "react";
import { Control, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
  Patterns,
  phoneLengthValidation,
  typePhoneValidation,
} from "helpers/Global";
import { InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  label: string;
  control: Control<any>;
  editedValue?: boolean;
  variant?: "standard" | "outlined";
  error?: boolean;
  onFocusSetState?: (value: boolean) => void;
  onFocusState?: boolean;
  phoneTypePrefix?: string[];
  isShrink?: boolean;
}

function PhoneFieldValidate({
  name,
  label,
  control,
  editedValue,
  variant,
  onFocusSetState,
  onFocusState,
  phoneTypePrefix,
  isShrink,
}: Props) {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          value={value}
          onChange={onChange}
          variant={variant}
          InputLabelProps={{ shrink: isShrink }}
          onFocus={() => (onFocusSetState ? onFocusSetState(true) : undefined)}
          type="string"
          error={
            onFocusState !== undefined
              ? !onFocusState
              : phoneTypePrefix
              ? typePhoneValidation(value, phoneTypePrefix) &&
                value !== "48" &&
                editedValue
              : undefined
          }
          helperText={
            onFocusState !== undefined
              ? !onFocusState
                ? t("Additional Phone Validation")
                : ""
              : phoneTypePrefix
              ? typePhoneValidation(value, phoneTypePrefix) &&
                value !== "48" &&
                editedValue
                ? t("Message Error Phone Format")
                : ""
              : undefined
          }
          label={label}
          InputProps={{
            disabled: !editedValue,
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          }}
          inputProps={{
            inputMode: "numeric",
            pattern: Patterns.numericValues,
            maxLength: phoneLengthValidation(value),
            minLength: phoneLengthValidation(value, true),
          }}
        />
      )}
    />
  );
}

export default PhoneFieldValidate;
