import { PaletteMode } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import WeatherNight from "mdi-material-ui/WeatherNight";
import WeatherSunny from "mdi-material-ui/WeatherSunny";
import { Settings } from "config/settingsTheme";
import { useEffect } from "react";

interface Props {
  settings: Settings;
  saveSettings: (values: Settings) => void;
}
const root = document.documentElement;
const ModeToggler = (props: Props) => {
  const { settings, saveSettings } = props;
  const handleModeChange = (mode: PaletteMode) => {
    saveSettings({ ...settings, mode });
  };
  const handleModeToggle = () => {
    if (settings.mode === "light") {
      handleModeChange("dark");
      localStorage.setItem("themeMode", "dark");
      root?.style.setProperty("--font-color-theme", "#f5f4f4");
      root?.style.setProperty(
        "--background-color-theme",
        "rgba(51,45,45,0.63)"
      );
    } else {
      handleModeChange("light");
      localStorage.setItem("themeMode", "light");
      root?.style.setProperty("--font-color-theme", "#1d2124");
      root?.style.setProperty("--background-color-theme", "#D2D0D0A0");
    }
  };
  useEffect(() => {}, [handleModeToggle]);

  return (
    <IconButton color="inherit" aria-haspopup="true" onClick={handleModeToggle}>
      {settings.mode === "dark" ? <WeatherSunny /> : <WeatherNight />}
    </IconButton>
  );
};

export default ModeToggler;
