import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components, operations } from "schema/schema";

const path = "/api/v1/polls/";

type Payload = components["schemas"]["Poll.jsonld-poll.extended"];
type Response = operations["api_apiv1polls_id_put"]["responses"];
type PollId = operations["api_apiv1polls_id_put"]["parameters"]["path"]["id"];

type Params = {
  payload: Payload;
  pollId: PollId;
};

const putPoll = async ({ pollId, payload }: Params) => {
  const { data } = await getApiClient().put(`${path}${pollId}`, payload);
  return data;
};

export default function usePutPollMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putPoll, options);
}
