import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  TableCell,
  TableCellProps,
  TableHead,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Control, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import { styled, useTheme } from "@mui/material/styles";
import JunctionProfilesContactDetails from "components/PageJunctionProfiles/JunctionProfilesContactDetails";
import JunctionProfilesMembershipDetails from "components/PageJunctionProfiles/JunctionProfilesMembershipDetails";
import JunctionProfilesNotebookDetails from "components/PageJunctionProfiles/JunctionProfilesNotebookDetails";
import JunctionProfilesPermissionsDetails from "components/PageJunctionProfiles/JunctionProfilesPermissionsDetails";
import JunctionProfilesTransactionsDetails from "components/PageJunctionProfiles/JunctionProfilesTransactionsDetails";
import JunctionProfilesPointsDetails from "components/PageJunctionProfiles/JunctionProfilesPointsDetails";
import usePutJunctionProfilesMutation from "hooks/usePutJunctionProfilesMutation";
import JunctionProfilesCrmsDetails from "components/PageJunctionProfiles/JunctionProfilesCrmsDetails";
import { Loader } from "components/Loader/Loader";
import { DefaultTFuncReturn } from "i18next";
import JunctionProfilesChangeHistoryDetails from "components/PageJunctionProfiles/JunctionProfilesChangeHistoryDetails";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { components } from "schema/schema";
import { makeStyles } from "@material-ui/core/styles";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";
import useDeleteUserMutation from "hooks/useDeleteUserMutation";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { UserValue } from "types/types";
import JunctionProfilesFormDetails from "components/PageJunctionProfiles/JunctionProfilesFormDetails";
import JunctionProfilesParagraphsDetails from "components/PageJunctionProfiles/JunctionProfilesParagraphsDetails";
import { themeMode } from "helpers/Global";
import { useSettings } from "hooks/useSettings";

export const useStyles = makeStyles((theme) => ({
  groupCheckbox: {
    position: "absolute",
    top: "2px",
    right: "20%",
    [theme.breakpoints.down("sm")]: {
      right: "5%",
    },
  },
  junctionProfilesTextFieldClass: {
    textAlign: "center",
  },
  junctionProfilesFormControlClass: {
    width: "100%",
  },
}));

export const TableCellCustomShort = styled(TableCell)<TableCellProps>(() => ({
  width: "20%",
  textAlign: "center",
  paddingTop: "0.6rem !important",
  paddingBottom: "0.6rem !important",
}));
export const TableCellCustomLong = styled(TableCell)<TableCellProps>(() => ({
  width: "40%",
  textAlign: "center",
  paddingTop: "0.6rem !important",
  paddingBottom: "0.6rem !important",
}));

export const TextFieldCustom = ({ value, children }: TextFieldCustomTypes) => {
  return (
    <TextField
      value={value}
      size="small"
      type="text"
      sx={{ width: "100%", input: { textAlign: "center" } }}
      InputProps={{
        readOnly: true,
      }}
    >
      {children}
    </TextField>
  );
};

export const TableCellCustomTitle = ({ children }: TextFieldCustomTypes) => {
  const { settings } = useSettings();
  const theme = useTheme();

  return (
    <TableCell
      colSpan={3}
      align="center"
      style={{
        backgroundColor: theme.palette.customColors.tableHeaderSecondary,
        color:
          settings.mode === themeMode.light
            ? "inherit"
            : theme.palette.primary.contrastText,
        position: "sticky",
        top: "150px",
        zIndex: 10,
      }}
    >
      {children}
    </TableCell>
  );
};

interface TextFieldCustomTypes {
  value?: string | undefined | DefaultTFuncReturn;
  children?: ReactNode;
}

export interface JunctionProfilesType {
  control: Control<FormInputs>;
  duplicateUserData: components["schemas"]["User.jsonld"];
  mainUserData?: components["schemas"]["User.jsonld"];
  isLoading?: boolean;
  resetAllGroupCheckboxes?: boolean;
  setResetAllGroupCheckboxes?: (value: boolean) => void;
}

interface ContactDetailsSectionType {
  duplicateUserData: UserValue;
  mainUserData: UserValue;
  replaceUsers: (firstValue: UserValue, secValue: UserValue) => void;
}

export interface FormInputs {
  firstName?: string;
  lastName: string;
  email: string;
  emailSii: string;
  additionalPhone: string;
  mobilePhone: string;
  landlinePhone: string;
  zipCode: string;
  city: string;
  street: string;
  zipCodeCorrespondence: string;
  cityCorrespondence: string;
  streetCorrespondence: string;
  birthDate: string;
  nationality: string;
  genderValue: number;
  declarationReceiptDate: string;
  declarationFulfilmentDate: string;
  inMembershipSince: string;
  inMembershipTo: string;
  department: string;
  cardNumber: number;
  comment: string;
  userRoles: string[];
  isActive: boolean;
  password: string;
  transactions: string[];
  crms: string[];
  formHistory: string[];
  balances: string[];
  createCrm: boolean;
}

function JunctionsProfilesTable({
  duplicateUserData,
  mainUserData,
  replaceUsers,
}: ContactDetailsSectionType) {
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [resetAllGroupCheckboxes, setResetAllGroupCheckboxes] = useState(false);
  const [openTwoStepsAcceptModal, setOpenTwoStepsAcceptModal] = useState(false);
  const [
    openDeleteUserTwoStepsAcceptModal,
    setOpenDeleteUserTwoStepsAcceptModal,
  ] = useState(false);
  const [successSaveMainProfile, setSuccessSaveMainProfile] = useState(false);
  const { mutate } = useDeleteUserMutation();
  const theme = useTheme();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const topRef = useRef<HTMLDivElement>(null);
  const isLoading = false;
  const handleScrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const {
    mutate: junctionProfilesMutation,
    isLoading: junctionProfilesMutationLoading,
  } = usePutJunctionProfilesMutation();
  const {
    control: control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<FormInputs>({
    defaultValues: {
      firstName: mainUserData?.firstName ?? undefined,
      lastName: mainUserData?.lastName ?? undefined,
      email: mainUserData?.email ?? undefined,
      emailSii: mainUserData?.emailSii ?? undefined,
      additionalPhone: mainUserData?.additionalPhone ?? undefined,
      landlinePhone: mainUserData?.landlinePhone ?? undefined,
      mobilePhone: mainUserData?.mobilePhone ?? undefined,
      zipCode: mainUserData?.zipCode ?? undefined,
      city: mainUserData?.city ?? undefined,
      street: mainUserData?.street ?? undefined,
      zipCodeCorrespondence: mainUserData?.zipCodeCorrespondence ?? undefined,
      cityCorrespondence: mainUserData?.cityCorrespondence ?? undefined,
      streetCorrespondence: mainUserData?.streetCorrespondence ?? undefined,
      birthDate: mainUserData?.birthDate
        ? mainUserData?.birthDate.slice(0, 10)
        : "0000-00-00",
      nationality: mainUserData?.nationality ?? undefined,
      genderValue: mainUserData?.genderValue ?? undefined,
      declarationReceiptDate: mainUserData?.declarationReceiptDate
        ? mainUserData?.declarationReceiptDate.slice(0, 10)
        : "0000-00-00",
      declarationFulfilmentDate: mainUserData?.declarationFulfilmentDate
        ? mainUserData?.declarationFulfilmentDate.slice(0, 10)
        : "0000-00-00",
      inMembershipSince: mainUserData?.inMembershipSince
        ? mainUserData?.inMembershipSince.slice(0, 10)
        : "0000-00-00",
      inMembershipTo: mainUserData?.inMembershipTo
        ? mainUserData?.inMembershipTo.slice(0, 10)
        : "0000-00-00",
      department: mainUserData?.department
        ? mainUserData?.department.substring(20, 22)
        : undefined,
      cardNumber: mainUserData?.cardNumber ?? undefined,
      comment: mainUserData?.comment ?? undefined,
      transactions: [],
      balances: [],
      crms: [],
      formHistory: [],
      createCrm: false,
    },
  });
  const onSubmit = ({
    firstName,
    lastName,
    email,
    emailSii,
    additionalPhone,
    mobilePhone,
    zipCode,
    city,
    street,
    landlinePhone,
    zipCodeCorrespondence,
    cityCorrespondence,
    streetCorrespondence,
    birthDate,
    nationality,
    genderValue,
    declarationReceiptDate,
    declarationFulfilmentDate,
    inMembershipSince,
    inMembershipTo,
    department,
    cardNumber,
    comment,
    transactions,
    userRoles,
    isActive,
    password,
    crms,
    balances,
    formHistory,
    createCrm,
  }: FormInputs) => {
    const newRoles = userRoles.map((item) =>
      item === "ROLE_REDACTOR_LEVEL_0"
        ? `ROLE_REDACTOR_LEVEL_${duplicateUserData.redactorRoleLevel}`
        : item
    );
    junctionProfilesMutation(
      {
        headers: {
          accept: "application/ld+json",
          "Content-Type": "application/ld+json",
        },
        payload: {
          user: {
            "@id": mainUserData["@id"],
            firstName: firstName,
            lastName: lastName,
            email: email,
            emailSii: emailSii,
            additionalPhone: additionalPhone,
            mobilePhone: mobilePhone,
            landlinePhone: landlinePhone,
            zipCode: zipCode,
            city: city,
            street: street,
            zipCodeCorrespondence: zipCodeCorrespondence,
            cityCorrespondence: cityCorrespondence,
            streetCorrespondence: streetCorrespondence,
            birthDate: birthDate,
            nationality: nationality,
            declarationReceiptDate: declarationReceiptDate,
            declarationFulfilmentDate: declarationFulfilmentDate,
            inMembershipSince: inMembershipSince,
            inMembershipTo: inMembershipTo,
            department: `/api/v1/departments/${department}`,
            cardNumber: cardNumber,
            genderValue: genderValue,
            comment: comment,
            roles: newRoles,
            active: isActive,
            password: password ? duplicateUserData?.password : undefined,
          },
          transactions: transactions,
          crms: crms,
          balances: balances,
          formHistory: formHistory,
          createCrm: createCrm,
        },
        userId: mainUserData?.id,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setSuccessSaveMainProfile(true);
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
        },
      }
    );
  };
  const handleDelete = () => {
    mutate(duplicateUserData?.id, {
      onSuccess: () => {
        setOpenSuccess(true);
      },
      onError: () => {
        setOpenSuccess(false);
        setOpenError(true);
      },
    });
    setOpenDeleteUserTwoStepsAcceptModal(false);
  };
  const handleReset = () => {
    setResetAllGroupCheckboxes(!resetAllGroupCheckboxes);
    reset();
  };
  const handleClick = () => {
    isDirty ? setOpenTwoStepsAcceptModal(true) : reset();
  };
  useEffect(() => {
    reset({
      userRoles: mainUserData?.roles,
      isActive: mainUserData?.active,
    });
  }, [mainUserData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {(junctionProfilesMutationLoading || isLoading) && <Loader />}
      <div ref={topRef} style={{ position: "absolute", top: 0 }} />
      <TableContainer
        sx={{
          width: "100%",
          overflow: onlyMediumScreen ? "auto" : "unset",
        }}
      >
        <Table sx={{ borderCollapse: "separate" }} aria-label="table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: theme.palette.customColors.tableHeaderPrimary,
              zIndex: 10,
            }}
          >
            <TableRow sx={{ height: "70px" }}>
              <TableCellCustomLong>
                {t("Duplicate Profile")}
              </TableCellCustomLong>
              <TableCellCustomShort>
                <Button
                  size="small"
                  onClick={handleScrollToTop}
                  variant="outlined"
                  color="secondary"
                  sx={{
                    width: onlyMediumScreen ? "auto" : "205px",
                    m: 2,
                    px: "10px !important",
                  }}
                >
                  {t("Search New Profiles")}
                </Button>
              </TableCellCustomShort>
              <TableCellCustomLong>{t("Main Profile")}</TableCellCustomLong>
            </TableRow>
            <TableRow sx={{ height: "70px" }}>
              <TableCellCustomLong>
                {duplicateUserData?.id} <br /> {duplicateUserData?.email}
              </TableCellCustomLong>
              <TableCellCustomShort>
                <p>UID & Email</p>
                <Button
                  size="small"
                  onClick={() => replaceUsers(duplicateUserData, mainUserData)}
                  variant="outlined"
                  color="secondary"
                  sx={{
                    m: 1,
                  }}
                >
                  {t("Replace Profiles")}
                </Button>
              </TableCellCustomShort>
              <TableCellCustomLong>
                {mainUserData?.id} <br /> {mainUserData?.email}
              </TableCellCustomLong>
            </TableRow>
          </TableHead>
          <JunctionProfilesContactDetails
            control={control}
            duplicateUserData={duplicateUserData}
          />
          <JunctionProfilesMembershipDetails
            control={control}
            duplicateUserData={duplicateUserData}
            isLoading={isLoading}
          />
          <JunctionProfilesNotebookDetails
            control={control}
            duplicateUserData={duplicateUserData}
          />
          <JunctionProfilesPermissionsDetails
            control={control}
            duplicateUserData={duplicateUserData}
            mainUserData={mainUserData}
          />
          <JunctionProfilesTransactionsDetails
            duplicateUserData={duplicateUserData}
            mainUserData={mainUserData}
            resetAllGroupCheckboxes={resetAllGroupCheckboxes}
            setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
            isLoading={isLoading}
            control={control}
          />
          <JunctionProfilesPointsDetails
            duplicateUserData={duplicateUserData}
            mainUserData={mainUserData}
            resetAllGroupCheckboxes={resetAllGroupCheckboxes}
            setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
            isLoading={isLoading}
            control={control}
          />
          <JunctionProfilesParagraphsDetails
            duplicateUserData={duplicateUserData}
            mainUserData={mainUserData}
            resetAllGroupCheckboxes={resetAllGroupCheckboxes}
            setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
            isLoading={isLoading}
            control={control}
          />
          <JunctionProfilesCrmsDetails
            duplicateUserData={duplicateUserData}
            mainUserData={mainUserData}
            resetAllGroupCheckboxes={resetAllGroupCheckboxes}
            setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
            isLoading={isLoading}
            control={control}
          />
          <JunctionProfilesChangeHistoryDetails
            duplicateUserData={duplicateUserData}
            mainUserData={mainUserData}
            resetAllGroupCheckboxes={resetAllGroupCheckboxes}
            setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
            isLoading={isLoading}
            control={control}
          />
          <JunctionProfilesFormDetails
            duplicateUserData={duplicateUserData}
            mainUserData={mainUserData}
            resetAllGroupCheckboxes={resetAllGroupCheckboxes}
            setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
            isLoading={isLoading}
            control={control}
          />
          <TableRow>
            <TableCellCustomLong>
              {successSaveMainProfile && (
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpenDeleteUserTwoStepsAcceptModal(true)}
                  sx={{ m: 2 }}
                >
                  {t("Delete Duplicate Profile")}
                </Button>
              )}
              <TwoStepsAcceptModal
                message={t("Do You Want To Delete User?")}
                executeButtonName={t("Delete")}
                executeFunction={handleDelete}
                isOpen={openDeleteUserTwoStepsAcceptModal}
                setIsOpen={setOpenDeleteUserTwoStepsAcceptModal}
              />
            </TableCellCustomLong>
            <TableCellCustomShort>
              <Button
                size="large"
                variant="outlined"
                onClick={handleClick}
                color="secondary"
                sx={{ m: 2 }}
              >
                {t("Refresh Data Without Save")}
              </Button>
              {isDirty && (
                <TwoStepsAcceptModal
                  message={t("Data Are Changed. Do You Want Continue?")}
                  executeButtonName={t("Refresh")}
                  executeFunction={handleReset}
                  isOpen={openTwoStepsAcceptModal}
                  setIsOpen={setOpenTwoStepsAcceptModal}
                />
              )}
            </TableCellCustomShort>
            <TableCellCustomLong>
              <Button
                size="large"
                type="submit"
                variant="outlined"
                color="secondary"
                sx={{ m: 2 }}
              >
                {t("Save Main Profile")}
              </Button>
            </TableCellCustomLong>
          </TableRow>
        </Table>
      </TableContainer>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit User Parameters")}
        successMessage={t("Message Success Edit User Parameters")}
      />
    </form>
  );
}

export default JunctionsProfilesTable;
