import { getApiClient } from "helpers/Global";
import { useQuery, UseQueryOptions } from "react-query";
import { AxiosError } from "axios";

const path = "https://restcountries.com/v3/all?fields=name,ccn3,translations";
const queryKey = "countries";

export const getCountries = async () => {
  const { data } = await getApiClient().get(path);
  return data;
};

export default function useGetCountriesQuery(
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey],
    () => getCountries(),
    options
  );
}
