import EditPermissionsSectionSpecial from "components/PageEditPermissions/EditPermissionsSectionSpecial/EditPermissionsSectionSpecial";
import useGetParametersPermissonsQuery from "hooks/useGetParametersPermissonsQuery";

type Props = {
  selectedUserId: number;
};

const ParametersEditPermissions = ({ selectedUserId }: Props) => {
  const { data: parametersPermissions } =
    useGetParametersPermissonsQuery(selectedUserId);
  return (
    <EditPermissionsSectionSpecial
      parametersPermissions={parametersPermissions}
      selectedUserId={selectedUserId}
    />
  );
};

export default ParametersEditPermissions;
