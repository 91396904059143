import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Control, Controller, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormInputs } from "pages/Users/Users";
import useGetCrmTypesQuery from "hooks/useGetCrmTypesQuery";
import { useTheme } from "@mui/material/styles";
import EventIcon from "@mui/icons-material/Event";
import React from "react";
import { formatValueDatepicker } from "helpers/Global";

type Props = { control: Control<FormInputs> };

function CRMFields({ control }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  const { data } = useGetCrmTypesQuery({
    query: {status: "0", pagination: false},
  });
  const { crmDateFrom, crmDateTo } = useWatch({ control });

  return (
    <div>
      <Box
        className="crm-field-wrapper"
        sx={{
          display: "flex",
          alignItems: onlySmallScreen ? "center" : "unset",
          flexDirection: onlySmallScreen ? "column" : "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl sx={{ width: 200, my: 4 }}>
            <Controller
              control={control}
              name="crmFilledAllOrOneFields"
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  id="crm-filled-fields-select"
                >
                  <MenuItem value="allSelected">{t("All")}</MenuItem>
                  <MenuItem value="oneOfSelected">{t("One Of")}</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl sx={{ width: 200 }}>
            <InputLabel id="crm-filled-fields-multiple-checkbox-label">
              {t("Filled Fields")}
            </InputLabel>
            <Controller
              control={control}
              name="crmFilledFields"
              render={({ field: { value, onChange } }) => (
                <Select
                  multiple
                  label={t("Filled Fields")}
                  value={value}
                  MenuProps={MenuProps}
                  onChange={onChange}
                  id="crm-filled-fields-multiple-checkbox"
                  labelId="crm-filled-fields-multiple-checkbox-label"
                >
                  {data?.["hydra:member"].map((crmType: any) => (
                    <MenuItem key={crmType.id} value={crmType.id}>
                      {crmType.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            alignSelf: onlySmallScreen ? "center" : "end",

            height: "72px",
            mx: 4,
          }}
        >
          <FormControl sx={{ width: 80, my: 4, textAlign: "center" }}>
            <Controller
              control={control}
              name="joinMethodAndOrCrm"
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  id="crm-and-or-select"
                  labelId="crm-and-or-select-label"
                >
                  <MenuItem value="and">{t("And")}</MenuItem>
                  <MenuItem value="or">{t("Or")}</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <FormControl sx={{ width: 200, my: 4 }}>
            <Controller
              control={control}
              name="crmBlankAllOrOneNoneFields"
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  id="crm-blank-fields-select"
                >
                  <MenuItem value="noneOfAllSelected">{t("None Of")}</MenuItem>
                  <MenuItem value="noneOfOneSelected">{t("One Of")}</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl sx={{ width: 200 }}>
            <InputLabel id="crm-blank-fields-multiple-checkbox-label">
              {t("Blank Fields")}
            </InputLabel>
            <Controller
              control={control}
              name="crmBlankFields"
              render={({ field: { value, onChange } }) => (
                <Select
                  multiple
                  label={t("Blank Fields")}
                  value={value}
                  MenuProps={MenuProps}
                  onChange={onChange}
                  id="crm-blank-fields-multiple-checkbox"
                  labelId="crm-blank-fields-multiple-checkbox-label"
                >
                  {data?.["hydra:member"].map((crmType: any) => (
                    <MenuItem key={crmType.id} value={crmType.id}>
                      {crmType.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>
      </Box>
      <FormControl
        sx={{
          width: onlySmallScreen ? "100%" : 300,
          my: 4,
          alignItems: onlySmallScreen ? "center" : "unset",
        }}
      >
        <Controller
          control={control}
          name="containTextField"
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              onChange={onChange}
              name="crmContainTextField"
              type="text"
              label={t("Contain")}
              sx={{ width: onlySmallScreen ? "200px" : "unset" }}
            />
          )}
        />
      </FormControl>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: onlySmallScreen ? "column" : "row",
            mt: onlySmallScreen ? 5 : 0,
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={t("Language Code") as string}
          >
            <Typography>{t("Entry Date")}</Typography>

            <Controller
              control={control}
              name="crmDateFrom"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  inputFormat={formatValueDatepicker}
                  value={value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={"YYYY-MM-DD"}
                      sx={{ width: "180px", mx: 2 }}
                    />
                  )}
                />
              )}
            />
            <Typography>{t("To")}</Typography>
            <Controller
              control={control}
              name="crmDateTo"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  inputFormat={formatValueDatepicker}
                  value={value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={"YYYY-MM-DD"}
                      sx={{
                        width: "180px",
                        mx: 2,
                      }}
                      error={crmDateFrom > crmDateTo}
                      helperText={
                        crmDateFrom > crmDateTo
                          ? t("Date Range Validation")
                          : ""
                      }
                      FormHelperTextProps={{
                        style: { position: "absolute", bottom: "-25px" },
                      }}
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </div>
  );
}

export default CRMFields;
