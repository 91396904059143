import React from "react";
import { Box, Button, Card, Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { useSettings } from "hooks/useSettings";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import CustomButton from "components/Buttons/CustomButton";

interface RefundTransactionModalTypes {
  addAnotherUserOrEditTheAddedModal: boolean;
  setAddAnotherUserOrEditTheAddedModal: (val: boolean) => void;
  idUser: number | undefined;
  resetForm: () => void;
}

function AddAnotherUserOrEditTheAddedModal({
  addAnotherUserOrEditTheAddedModal,
  setAddAnotherUserOrEditTheAddedModal,
  idUser,
  resetForm,
}: RefundTransactionModalTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { settings } = useSettings();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const AddAnotherUser = () => {
    setAddAnotherUserOrEditTheAddedModal(false);
    resetForm();
  };
  return (
    <Dialog
      open={addAnotherUserOrEditTheAddedModal}
      BackdropProps={{
        style: {
          backgroundColor: theme.palette.customColors.modalBgc,
        },
      }}
    >
      <Box
        className="array-modal"
        sx={{
          width: onlyMediumScreen ? "90vw" : "70vw",
          minHeight: 150,
          px: onlySmallScreen ? 5 : "unset",
        }}
      >
        <Card
          sx={{
            minHeight: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: onlySmallScreen ? "unset" : "50px",
            flexWrap: "wrap",
          }}
        >
          <Alert
            severity="success"
            sx={{
              my: 4,
              width: "80%",
              backgroundColor:
                settings.mode === "light"
                  ? "rgb(240, 250, 232)"
                  : "rgb(99,121,76)",
            }}
          >
            <p>{t("Message Success Added User")}</p>
          </Alert>
          <CustomButton
            text={t("Show All Sessions")}
            style={{ m: 2, mb: 5 }}
            onClickFunction={() => {
              AddAnotherUser();
            }}
          />
          <Button
            color="inherit"
            variant="contained"
            className="button-custom-styles"
            sx={{ m: 2, mb: 5 }}
          >
            <Link
              to={`/users/edit/${idUser}`}
              style={{ textDecoration: "none", color: "unset" }}
            >
              {t("Edit The Added User")}
            </Link>
          </Button>
        </Card>
        <Button
          className="array-close-button"
          onClick={() => setAddAnotherUserOrEditTheAddedModal(false)}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Dialog>
  );
}
export default AddAnotherUserOrEditTheAddedModal;
