import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = "/api/login";

type Payload = components["schemas"]["Credentials"];
type Response = components["schemas"]["UserShortInfo"];
const postLogin = async (payload: Payload) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function useLoginMutation(
  options: UseMutationOptions<any, AxiosError, any> = {}
) {
  return useMutation<Response, AxiosError, Payload>(postLogin, options);
}
