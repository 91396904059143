import React, { useEffect, useState } from "react";
import {
  styled,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";
import { Outlet } from "react-router";
import NavigationComponent from "components/Navigation/NavigationComponents/NavigationComponent";
import AppBar from "components/AppBar/AppBar";
import Footer from "components/Footer/Footer";
import themeConfig from "config/themeConfig";
import { useSettings } from "hooks/useSettings";
import AppBarContent from "components/AppBar/AppBarContent/AppBarContent";
import NavItems from "components/Navigation/NavigationComponents/NavLinksList";
import themeOptions from "config/ThemeOptions";
import overrides from "theme/overrides";
import typography from "theme/typography/typography";
import { PaletteMode, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { routes } from "routing/routes";

export const selectedMode = localStorage.getItem("themeMode");

const LayoutWrapper = styled("div")({
  height: "100%",
  display: "flex",
});

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
});

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  padding: theme.spacing(6),
  transition: "padding .25s ease-in-out",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Layout = () => {
  const { settings, saveSettings } = useSettings();
  const contentWidth = settings.contentWidth;
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const navWidth = isIOS ? "100vw" : themeConfig.navigationSize;
  const [navVisible, setNavVisible] = useState<boolean>(false);
  const toggleNavVisibility = () => setNavVisible(!navVisible);
  const coreThemeConfig = themeOptions(settings);
  let theme = createTheme(coreThemeConfig);
  theme = createTheme(theme, {
    components: { ...overrides(theme) },
    typography: { ...typography(theme) },
  });
  const themes = useTheme();
  const smallerThanLargeScreen = useMediaQuery(themes.breakpoints.down("lg"));
  const handleModeChange = (mode: PaletteMode) => {
    saveSettings({ ...settings, mode });
  };
  const location = useLocation();
  const setThemeMode = () => {
    if (selectedMode === "light") {
      handleModeChange("light");
    } else if (selectedMode === "dark") {
      handleModeChange("dark");
    }
  };
  useEffect(() => {
    setThemeMode();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LayoutWrapper
        className="layout-wrapper"
        sx={{
          backgroundColor:
            settings.mode === "light"
              ? theme.palette.customColors.customBg
              : "#28243D",
        }}
      >
        {location.pathname !== routes.EDIT_FORMS.path &&
          location.pathname !== routes.ADD_POLL.path && (
            <NavigationComponent
              isIOS={isIOS}
              saveSettings={saveSettings}
              settings={settings}
              navWidth={navWidth}
              navVisible={navVisible}
              setNavVisible={setNavVisible}
              NavItems={NavItems()}
              toggleNavVisibility={toggleNavVisibility}
            />
          )}
        <MainContentWrapper
          className="layout-content-wrapper"
          sx={{
            mt: smallerThanLargeScreen ? "100px" : 0,
          }}
        >
          {location.pathname !== routes.EDIT_FORMS.path &&
            location.pathname !== routes.ADD_POLL.path && (
              <AppBar
                saveSettings={saveSettings}
                settings={settings}
                toggleNavVisibility={toggleNavVisibility}
                AppBarContent={AppBarContent}
              />
            )}

          <ContentWrapper
            className="layout-page-content"
            sx={{
              ...(contentWidth === "boxed" && {
                mx: "auto",
                "@media (min-width:1440px)": { maxWidth: 1440 },
                "@media (min-width:1200px)": { maxWidth: "100%" },
              }),
            }}
          >
            <Outlet />
          </ContentWrapper>
          <Footer />
        </MainContentWrapper>
      </LayoutWrapper>
    </ThemeProvider>
  );
};

export default Layout;
