import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";
import { TransactionsResponseTypes, UserIdType } from "types/types";

const path = "/api/v1/p24_transactions";
const queryKey = "transactions";

type Response = TransactionsResponseTypes;
type UserId = UserIdType;
type Parameters =
  operations["api_apiv1p24_transactions_get_collection"]["parameters"];

const getTransactions = async (parameters: Parameters, userId: UserId) => {
  const { data } = await getApiClient().get(`${path}?user=${userId}`, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetTransactionsQuery(
  userId: UserId,
  parameters: Parameters,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, userId, parameters],
    () => getTransactions(parameters, userId),
    options
  );
}
