import { useQuery, UseQueryOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = `${API_PREFIX}/newsletter_subscriber_csv`;
const queryKey = "newsletterSubscriberCSV";

type Response =
  operations["newsletterSubscriberCsv"]["responses"]["200"]["content"]["application/ld+json"];

const getNewsletterSubscriberCsvQuery = async () => {
  const { data } = await getApiClient().get(path);
  return data;
};

export default function useGetNewsletterSubscriberCsvQuery(
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey],
    () => getNewsletterSubscriberCsvQuery(),
    options
  );
}
