import React from "react";
import { concat, filter, includes } from "lodash";
import TableRow from "@mui/material/TableRow";
import {
  TableCellCustomLong,
  TableCellCustomShort,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";
import { formattedDate, formatValueDateTimeOther } from "helpers/Global";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { BalancesTypes, CrmsTypes, TransactionsTypes } from "types/types";

export type InputValues =
  | TransactionsTypes
  | BalancesTypes
  | CrmsTypes
  | undefined;

interface Props {
  item: CrmsTypes;
  value: string[];
  onChange: (value: any) => void;
}

function CheckboxSetSingleItem({ item, value, onChange }: Props) {
  const { t } = useTranslation();

  return (
    <TableRow key={item.id}>
      <TableCellCustomLong>
        <p>
          {item.typeCrm} {item.content}
        </p>
        <p>
          {formattedDate(item.createdAt, formatValueDateTimeOther)}{" "}
          {item.author}
        </p>
      </TableCellCustomLong>
      <TableCellCustomShort>
        <p>{">> >> >>"}</p>
      </TableCellCustomShort>
      <TableCellCustomLong>
        <FormControlLabel
          control={
            <Checkbox
              value={item["@id"]}
              checked={includes(value, item["@id"])}
              onChange={() => {
                onChange(
                  includes(value, item["@id"])
                    ? filter(value, (val) => val !== item["@id"])
                    : concat(value, item["@id"])
                );
              }}
            />
          }
          label={t("Get From Left")}
        />
      </TableCellCustomLong>
    </TableRow>
  );
}

export default CheckboxSetSingleItem;
