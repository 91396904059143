import React, { useState } from "react";
import {
  Box,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import FormHelperText from "@mui/material/FormHelperText";
import { styled, useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import IconButton from "@mui/material/IconButton";
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import { FormControlProps } from "@mui/material/FormControl";
import EventIcon from "@mui/icons-material/Event";
import {
  dateFormat,
  landlinePhonePrefix,
  mobilePhonePrefix,
} from "constans/Constans";
import { intervalToDuration } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { formatValueDatepicker, regexTextValues } from "helpers/Global";
import { isValid } from "date-fns";
import AddAnotherUserOrEditTheAddedModal from "components/PageAddUser/AddAnotherUserOrEditTheAddedModal";
import usePostAddUserMutation from "hooks/usePostAddUserMutation";
import { Loader } from "components/Loader/Loader";
import TextFieldValidate from "components/ValidateFields/TextFieldValidate";
import EmailFieldValidate from "components/ValidateFields/EmailFieldValidate";
import ZipCodeFieldValidate from "components/ValidateFields/ZipCodeFieldValidate";
import PhoneFieldValidate from "components/ValidateFields/PhoneFieldValidate";
import CustomButton from "components/Buttons/CustomButton";

export interface FormInputs {
  firstName: string;
  lastName: string;
  gender?: number;
  birthDate: string;
  nationality: string;
  mainAddress: string;
  location: string;
  zipCode?: string;
  mobilePhoneNumber: string;
  landlinePhoneNumber: string;
  email: string;
  additionalEmail: string;
  password: string;
  repeatedPassword: string;
  correspondenceAddress?: string;
  correspondenceLocation: string;
  correspondenceZipCode?: string;
}

const FormControlCustom = styled(FormControl)<FormControlProps>(
  ({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 260,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 500,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 800,
    },
    margin: "0.5rem 1.25rem",
  })
);

function PersonalData() {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [
    addAnotherUserOrEditTheAddedModal,
    setAddAnotherUserOrEditTheAddedModal,
  ] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [addedUserId, setAddedUserId] = useState<undefined | number>();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
  const schema = yup.object().shape({
    firstName: yup.string().required(t("This Field is Required") as string),
    lastName: yup.string().required(t("This Field is Required") as string),
    email: yup.string().required(t("This Field is Required") as string),
    additionalEmail: yup.string(),
    password: yup
      .string()
      .required(t("This Field is Required") as string)
      .min(5, t("Password Is To Short") as string),
    repeatedPassword: yup
      .string()
      .required(t("This Field is Required") as string)
      .min(5, t("Password Is To Short") as string)
      .oneOf(
        [yup.ref("password"), null],
        t("Passwords Are Not The Same") as string
      ),
  });
  const { mutate: addUser, isLoading: addUserLoading } =
    usePostAddUserMutation();
  const {
    control: control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      birthDate: "2000-01-01",
      firstName: "",
      lastName: "",
      gender: 0,
      nationality: "",
      mainAddress: "",
      location: "",
      zipCode: "",
      mobilePhoneNumber: "48",
      landlinePhoneNumber: "48",
      email: "",
      additionalEmail: "",
      password: "",
      repeatedPassword: "",
      correspondenceAddress: "",
      correspondenceLocation: "",
      correspondenceZipCode: "",
    },
  });
  const onSubmit = ({
    firstName,
    lastName,
    gender,
    birthDate,
    nationality,
    mainAddress,
    location,
    zipCode,
    mobilePhoneNumber,
    landlinePhoneNumber,
    email,
    additionalEmail,
    password,
    correspondenceAddress,
    correspondenceLocation,
    correspondenceZipCode,
  }: FormInputs) => {
    addUser(
      {
        payload: {
          firstName: firstName?.replace(/\s+/g, ""),
          lastName: lastName?.replace(/\s+/g, ""),
          genderValue: Number(gender),
          birthDate: birthDate,
          nationality: nationality?.replace(/\s+/g, ""),
          address: mainAddress.replace(/\s+/g, ""),
          city: location?.replace(/\s+/g, ""),
          zipCode: zipCode?.replace(/\s+/g, ""),
          mobilePhone:
            mobilePhoneNumber.length >= 3
              ? `+${mobilePhoneNumber?.replace(/\s+/g, "")}`
              : "",
          landlinePhone:
            landlinePhoneNumber.length >= 3
              ? `+${landlinePhoneNumber?.replace(/\s+/g, "")}`
              : "",
          email: email?.replace(/\s+/g, ""),
          emailSii: additionalEmail?.replace(/\s+/g, ""),
          password: password,
          streetCorrespondence: correspondenceAddress?.replace(/\s+/g, ""),
          cityCorrespondence: correspondenceLocation?.replace(/\s+/g, ""),
          zipCodeCorrespondence: correspondenceZipCode?.replace(/\s+/g, ""),
          department: "/api/v1/departments/20",
        },
      },
      {
        onSuccess: (response) => {
          setAddAnotherUserOrEditTheAddedModal(true);
          setAddedUserId(response.id);
        },
        onError: () => {
          setAddAnotherUserOrEditTheAddedModal(false);
          setOpenError(true);
        },
      }
    );
  };
  const { birthDate } = useWatch({
    control: control,
  });
  const compareDate = intervalToDuration({
    start: isValid(new Date(birthDate as string))
      ? new Date(birthDate as string)
      : new Date(dateFormat as string),
    end: new Date(dateFormat as string),
  });

  return (
    <Box>
      {addUserLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Typography sx={{ ml: 5, mt: 5 }}>{t("Personal Data")}</Typography>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              py: 5,
              marginBottom: "30px",
            }}
          >
            <FormControlCustom>
              <TextFieldValidate
                name="firstName"
                label={t("First Name")}
                control={control}
                editedValue
                error={!!errors?.firstName}
              />
              {errors.firstName && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors.firstName.message}
                </FormHelperText>
              )}
            </FormControlCustom>
            <FormControlCustom>
              <TextFieldValidate
                name="lastName"
                label={t("Last Name")}
                control={control}
                editedValue
                error={!!errors?.lastName}
              />
              {errors.lastName && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors.lastName.message}
                </FormHelperText>
              )}
            </FormControlCustom>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                maxWidth: onlySmallScreen ? 260 : onlyMediumScreen ? 500 : 800,
                height: "56px",
                my: 2,
                mx: 5,
                border: "1px solid #d4d3d5",
                borderRadius: "6px",
              }}
            >
              <Typography sx={{ lineHeight: "56px", mx: 2 }}>
                {t("Gender")}:
              </Typography>
              <FormControl>
                <Controller
                  control={control}
                  name="gender"
                  render={({ field }) => (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      sx={{ flexDirection: "row", height: "56px" }}
                      {...field}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={
                          onlySmallScreen ? t("Woman Shortcut") : t("Woman")
                        }
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label={onlySmallScreen ? t("Man Shortcut") : t("Man")}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Box>
            <FormControl
              sx={{
                width: "100%",
                maxWidth: onlySmallScreen ? 260 : "100%",
                my: 2,
                mx: onlySmallScreen ? 5 : 3,
                alignItems: onlyMediumScreen ? "center" : "flex-start",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={t("Language Code") as string}
              >
                <Controller
                  control={control}
                  name="birthDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      inputFormat={formatValueDatepicker}
                      label={t("Birth Date")}
                      value={value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="YYYY-MM-DD"
                          sx={{
                            width: onlySmallScreen ? "100%" : 260,
                            mx: 2,
                          }}
                          error={
                            !!(compareDate.years && compareDate.years < 18)
                          }
                          helperText={
                            !!(compareDate.years && compareDate.years < 18)
                              ? t("User Is Not 18 Years Old")
                              : ""
                          }
                          FormHelperTextProps={{
                            style: { position: "absolute", bottom: "-19px" },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControlCustom>
              <Controller
                control={control}
                name="nationality"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    type="text"
                    label={t("Nationality")}
                    error={
                      !Boolean(regexTextValues.test(value)) && value !== ""
                    }
                    helperText={
                      !Boolean(regexTextValues.test(value)) && value !== ""
                        ? t("Invalid Value")
                        : ""
                    }
                    inputProps={{
                      maxLength: 15,
                    }}
                  />
                )}
              />
            </FormControlCustom>
            <FormControlCustom>
              <Controller
                control={control}
                name="mainAddress"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    type="text"
                    label={`${t("Address")} ${t(
                      "Street & House Number/Local"
                    )}`}
                  />
                )}
              />
            </FormControlCustom>
            <FormControlCustom>
              <Controller
                control={control}
                name="location"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    type="text"
                    label={t("Location")}
                    error={
                      !Boolean(regexTextValues.test(value)) && value !== ""
                    }
                    helperText={
                      !Boolean(regexTextValues.test(value)) && value !== ""
                        ? t("Invalid Value")
                        : ""
                    }
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                )}
              />
            </FormControlCustom>
            <FormControlCustom>
              <ZipCodeFieldValidate
                name="zipCode"
                label={t("Zip Code")}
                control={control}
                countryCode={"616"}
                editedValue
              />
            </FormControlCustom>
            <FormControlCustom>
              <PhoneFieldValidate
                control={control}
                name="mobilePhoneNumber"
                variant="outlined"
                label={t("Mobile Phone Number")}
                phoneTypePrefix={mobilePhonePrefix}
                editedValue
              />
            </FormControlCustom>
            <FormControlCustom>
              <PhoneFieldValidate
                control={control}
                name="landlinePhoneNumber"
                variant="outlined"
                label={t("Landline Phone Number")}
                phoneTypePrefix={landlinePhonePrefix}
                editedValue
              />
            </FormControlCustom>
            <FormControlCustom>
              <EmailFieldValidate
                name="email"
                label={t("Email")}
                control={control}
                editedValue
                error={!!errors?.email}
              />
              {errors.email && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors.email.message}
                </FormHelperText>
              )}
            </FormControlCustom>
            <FormControlCustom>
              <EmailFieldValidate
                name="additionalEmail"
                label={t("Additional Email")}
                control={control}
                editedValue
              />
            </FormControlCustom>
            <FormControlCustom>
              <Controller
                name="password"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("Password")}
                    value={value}
                    variant="outlined"
                    onChange={onChange}
                    id="new-user-password"
                    error={!!errors?.password}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors.password.message}
                </FormHelperText>
              )}
            </FormControlCustom>
            <FormControlCustom>
              <Controller
                name="repeatedPassword"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t("Repeat Password")}
                    value={value}
                    variant="outlined"
                    onChange={onChange}
                    id="new-user-password-repeat"
                    error={!!errors?.repeatedPassword}
                    type={showRepeatedPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() =>
                              setShowRepeatedPassword(!showRepeatedPassword)
                            }
                          >
                            {showRepeatedPassword ? (
                              <EyeOutline />
                            ) : (
                              <EyeOffOutline />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {errors.repeatedPassword && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {errors.repeatedPassword.message}
                </FormHelperText>
              )}
            </FormControlCustom>
          </Box>
        </Card>
        <Card sx={{ my: 5 }}>
          <Typography sx={{ ml: 5, mt: 5 }}>
            {t("Correspondence Data")} (
            {t("Please Complete If Different From The Above")})
          </Typography>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              py: 5,
            }}
          >
            <FormControlCustom>
              <Controller
                control={control}
                name="correspondenceAddress"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    type="text"
                    label={`${t("Address")} ${t(
                      "Street & House Number/Local"
                    )}`}
                  />
                )}
              />
            </FormControlCustom>
            <FormControlCustom>
              <Controller
                control={control}
                name="correspondenceLocation"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    type="text"
                    label={t("Location")}
                    error={
                      !Boolean(regexTextValues.test(value)) && value !== ""
                    }
                    helperText={
                      !Boolean(regexTextValues.test(value)) && value !== ""
                        ? t("Invalid Value")
                        : ""
                    }
                  />
                )}
              />
            </FormControlCustom>
            <FormControlCustom>
              <ZipCodeFieldValidate
                name="correspondenceZipCode"
                label={t("Zip Code")}
                control={control}
                countryCode={"616"}
                editedValue
              />
            </FormControlCustom>
          </Box>
        </Card>
        <Box
          sx={{
            mt: "30px",
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <CustomButton text={t("You Save")} style={{ m: 2 }} type="submit" />
        </Box>
      </form>
      <ChangeParametersModal
        openError={openError}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Added User")}
      />
      <AddAnotherUserOrEditTheAddedModal
        addAnotherUserOrEditTheAddedModal={addAnotherUserOrEditTheAddedModal}
        setAddAnotherUserOrEditTheAddedModal={
          setAddAnotherUserOrEditTheAddedModal
        }
        idUser={addedUserId}
        resetForm={reset}
      />
    </Box>
  );
}

export default PersonalData;
