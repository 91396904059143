import { Settings } from "config/settingsTheme";
import { NavLink, NavSectionTitle, NavItemsType } from "types/typesTheme";
import NavigationSectionTitle from "components/Navigation/NavigationComponents/NavigationSectionTitle";
import NavigationLink from "components/Navigation/NavigationComponents/NavigationLink";
import { groupBy, map } from "lodash";
import UseHasAccess from "hooks/useHasAccess";
import { TreeView, TreeItem } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface Props {
  settings: Settings;
  navVisible?: boolean;
  groupActive: string[];
  currentActiveGroup: string[];
  NavItems?: NavItemsType;
  saveSettings: (values: Settings) => void;
  setGroupActive: (value: string[]) => void;
  setCurrentActiveGroup: (item: string[]) => void;
}

const resolveNavItemComponent = (item: NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return NavigationSectionTitle;

  return NavigationLink;
};

const NavigationItems = (props: Props) => {
  const { NavItems } = props;
  const menuGroups = groupBy(NavItems, "navSection");
  const RenderMenuItems = map(
    menuGroups,
    (items: (NavLink | NavSectionTitle)[], section: string) => {
      const treeItems = map(
        items,
        (item: NavLink | NavSectionTitle, index: number) => {
          if (UseHasAccess(item.requireRole).length > 0) {
            const TagName: any = resolveNavItemComponent(item);
            return item.navContent ? (
              item.navContent()
            ) : (
              <TagName {...props} key={index} item={item} />
            );
          } else {
            return undefined;
          }
        }
      );
      if (treeItems.some(Boolean)) {
        return (
          <TreeView
            key={section}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            <TreeItem
              nodeId={section}
              label={section}
              sx={{ padding: "5px 0" }}
            >
              {treeItems}
            </TreeItem>
          </TreeView>
        );
      } else {
        return null;
      }
    }
  );
  return <>{RenderMenuItems}</>;
};

export default NavigationItems;
