import React from "react";
import { FormControl, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import {
  JunctionProfilesType,
  TableCellCustomLong,
  TableCellCustomShort,
  TableCellCustomTitle,
  useStyles,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";

function JunctionProfilesNotebookDetails({
  control,
  duplicateUserData,
}: JunctionProfilesType) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <TableBody>
      <TableRow>
        <TableCellCustomTitle>{t("Notebook")}</TableCellCustomTitle>
      </TableRow>
      <TableRow>
        <TableCellCustomLong>
          <TextField
            size="small"
            multiline
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: "100%" }}
            value={duplicateUserData?.comment ?? undefined}
          />
        </TableCellCustomLong>
        <TableCellCustomShort>{t("Notebook")}</TableCellCustomShort>
        <TableCellCustomLong>
          <FormControl className={classes.junctionProfilesFormControlClass}>
            <Controller
              control={control}
              name="comment"
              render={({ field: { value, onChange } }) => (
                <TextField
                  multiline
                  value={value}
                  onChange={onChange}
                  size="small"
                />
              )}
            />
          </FormControl>
        </TableCellCustomLong>
      </TableRow>
    </TableBody>
  );
}

export default JunctionProfilesNotebookDetails;
