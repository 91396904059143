import React, { useEffect } from "react";
import { Box, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { logout } from "helpers/Global";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function ErrorModal() {
  const { t } = useTranslation();
  const open = true;
  useEffect(() => {
    setTimeout(logout, 2000);
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="array-modal"
        sx={{
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "5px",
          boxShadow: 24,
        }}
      >
        <Alert severity="error" sx={{ textAlign: "center" }}>
          <AlertTitle> {t("Not Authorized - 401")}</AlertTitle>
          {t("Reload To Login Page")}
        </Alert>
      </Box>
    </Modal>
  );
}

export default ErrorModal;
