import React from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

interface TotalPointsTypes {
  totalPointsData: any | undefined;
}
function Points({ totalPointsData }: TotalPointsTypes) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
        value={totalPointsData?.totalPointsScored ?? 0}
        variant="standard"
        type="string"
        sx={{ width: 200, m: 2 }}
        label={t("Purchased Points")}
        InputLabelProps={{ shrink: true }}
        InputProps={{ readOnly: true, disableUnderline: true }}
        inputProps={{style: { cursor: 'default' }}}

      />
      <TextField
        value={totalPointsData?.totalPointsSpent ?? 0}
        variant="standard"
        type="string"
        sx={{ width: 200, m: 2 }}
        label={t("Spent Points")}
        InputLabelProps={{ shrink: true }}
        InputProps={{ readOnly: true, disableUnderline: true }}
        inputProps={{style: { cursor: 'default' }}}
      />
      <TextField
        value={totalPointsData?.balance ?? 0}
        variant="standard"
        type="string"
        sx={{ width: 200, m: 2 }}
        label={t("Left Points")}
        InputLabelProps={{ shrink: true }}
        InputProps={{ readOnly: true, disableUnderline: true }}
        inputProps={{style: { cursor: 'default' }}}
      />
    </Box>
  );
}

export default Points;
