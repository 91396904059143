import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";
import { PollTypes } from "types/types";

const path = "/api/v1/polls";
const queryKey = "polls";

type Parameters = operations["api_apiv1polls_get_collection"]["parameters"];

const getPolls = async (parameters: Parameters) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetPollsQuery(
  parameters: Parameters,
  options: UseQueryOptions<PollTypes, AxiosError> = {}
) {
  return useQuery<PollTypes, AxiosError>(
    [queryKey, parameters],
    () => getPolls(parameters),
    options
  );
}
