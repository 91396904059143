import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import { Loader } from "components/Loader/Loader";
import useGetNewslettersTypesQuery from "hooks/useGetNewslettersTypesQuery";
import { useTheme } from "@mui/material/styles";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import usePostNewsletterSubscriptionMutation from "hooks/usePostNewsletterSubscriptionMutation";
import CustomButton from "components/Buttons/CustomButton";

interface FormInputs {
  emailList: string;
  newsletterType: string;
  isSubscribe: number;
}

interface MultiEditingSubscribersTypes {
  setSuccessEditSubscription: (value: boolean) => void;
}

function MultiEditingSubscribers({
  setSuccessEditSubscription,
}: MultiEditingSubscribersTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const { control, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      newsletterType: "/api/v1/newsletter_types/1",
    },
  });
  const { emailList, newsletterType, isSubscribe } = useWatch({
    control,
  });
  const {
    mutate: MultiEditingSubscribersMutation,
    isLoading: MultiEditingSubscribersMutationLoading,
  } = usePostNewsletterSubscriptionMutation();
  const { data: newsletterTypesData } = useGetNewslettersTypesQuery({
    query: {},
  });
  function prepareMultiEditSubscriberData(
    emailList: string | undefined,
    newsletterType: string | undefined,
    subscribeValue: number | undefined
  ) {
    const value = subscribeValue === 1;
    const users = emailList
      ? emailList.split(/[;,|/\n]/).map((item) => item.trim())
      : undefined;
    return map(users, (email) => ({
      email,
      subscriptions: [
        {
          newsletterType,
          value,
        },
      ],
    }));
  }
  const subscribers = prepareMultiEditSubscriberData(
    emailList,
    newsletterType,
    isSubscribe
  );
  const onSubmit = () => {
    MultiEditingSubscribersMutation(
      {
        subscriptions: subscribers,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setSuccessEditSubscription(true);
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {MultiEditingSubscribersMutationLoading && <Loader />}
      <Typography sx={{ my: 5 }}>{t("Edit Multi Subscribers")}</Typography>
      <FormControl
        sx={{
          width: onlySmallScreen ? "90%" : 300,
          mr: onlySmallScreen ? 1 : 5,
          ml: 1,
          my: 2,
        }}
      >
        <Controller
          control={control}
          name="emailList"
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              onChange={onChange}
              size="small"
              variant="outlined"
              multiline
              type="text"
              label={t("Email Addresses")}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </FormControl>
      <FormControl sx={{ my: 2, mx: onlySmallScreen ? 1 : 5 }}>
        <InputLabel id="subscribers-selectBox-label">
          {t("Get Subscribe")}
        </InputLabel>
        <Controller
          control={control}
          name="newsletterType"
          render={({ field: { value, onChange } }) => (
            <Select
              sx={{ width: 200 }}
              value={value}
              label={t("Get Subscribe")}
              onChange={onChange}
              size="small"
              id="subscribers-select"
              labelId="subscribers-selectBox-label"
            >
              {map(newsletterTypesData?.["hydra:member"], (subscription) => (
                <MenuItem key={subscription.id} value={subscription?.["@id"]}>
                  {subscription.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ my: 2, mx: onlySmallScreen ? 1 : 5 }}>
        <InputLabel id="subscribers-type-selectBox-label">
          {t("Operation Type")}
        </InputLabel>
        <Controller
          control={control}
          name="isSubscribe"
          render={({ field: { value, onChange } }) => (
            <Select
              sx={{ width: 200 }}
              value={value}
              defaultValue={0}
              label={t("Operation Type")}
              onChange={onChange}
              size="small"
              id="subscribers-type-select"
              labelId="subscribers-type-selectBox-label"
            >
              <MenuItem value={0}>{t("UnSubscribe")}</MenuItem>
              <MenuItem value={1}>{t("Subscribe-v1")}</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <CustomButton
        text={t("You Save")}
        size="small"
        style={{ m: 2, display: "block" }}
        type="submit"
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit User Parameters")}
        successMessage={t("Message Success Edit User Parameters")}
      />
    </form>
  );
}

export default MultiEditingSubscribers;
