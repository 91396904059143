import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import useAuthenticationUser from "hooks/useAuthenticationUser";
import useGetActiveSessionsQuery from "hooks/useGetActiveSessionsQuery";
import useDeleteSessionsMutation from "hooks/useDeleteSessionsMutation";
import { GridColDef } from "@mui/x-data-grid";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import dayjs from "dayjs";

const ActiveSessions = () => {
  const { t } = useTranslation();
  const { data } = useAuthenticationUser();
  const userId = data?.user_id;
  const [openSuccess, setOpenSuccess] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [openError, setOpenError] = useState(false);
  const [activeSessionsEnabled, setActiveSessionsEnabled] = useState(false);
  const [openErrorNotChosenUser, setOpenErrorNotChosenUser] = useState(false);
  const [selectedElements, setSelectedElements] = useState<any | null>(null);
  const { data: activeSessionData, isLoading: activeSessionIsLoading } =
    useGetActiveSessionsQuery(
      {
        userId,
        parameters: {
          query: {
            page: pageNumber,
            itemsPerPage: itemsPerPage,
          },
        },
      },
      {
        enabled: !!userId || activeSessionsEnabled,
        onSettled: () => {
          setActiveSessionsEnabled(false);
        },
      }
    );
  const { mutate: deleteCheckedSessions } = useDeleteSessionsMutation();
  const deleteSessions = () => {
    const ids = selectedElements;
    if (ids && ids.length > 0) {
      deleteCheckedSessions(
        { ids },
        {
          onSuccess: () => {
            setOpenErrorNotChosenUser(false);
            setOpenSuccess(true);
            setActiveSessionsEnabled(true);
          },
          onError: () => {
            setOpenSuccess(false);
            setOpenError(true);
          },
        }
      );
    } else {
      setOpenSuccess(false);
      setOpenErrorNotChosenUser(true);
    }
  };
  const onRowsSelectionHandler = (ids: number[]) => {
    const selectedRowsData = ids.map((id: number) =>
      activeSessionData?.["hydra:member"].find((row) => row.id === id)
    );
    // @ts-ignore
    const result = selectedRowsData.map(({ id }) => id);
    setSelectedElements(result);
  };

  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "id",
      minWidth: 120,
      headerName: t("ID Log") as string,
    },
    {
      flex: 0.25,
      minWidth: 800,
      field: "userAgent",
      headerName: t("System") as string,
      renderCell: (params) => {
        const paymentTypeName = params.value as string;
        return (
          <p
            title={paymentTypeName}
            style={{ textAlign: "start", cursor: "pointer" }}
          >
            {paymentTypeName}
          </p>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "inet",
      headerName: t("Active Session Device IP") as string,
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "loginDateTime",
      valueFormatter: (params) =>
        dayjs(params.value).format("YYYY-MM-DD   HH:mm"),
      headerName: t("Date Login") as string,
    },
  ];
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <TableBasic
          paginationMode="server"
          columns={columns}
          loading={activeSessionIsLoading}
          onCheckboxClick={deleteSessions}
          onCheckboxSelect={onRowsSelectionHandler}
          rows={activeSessionData?.["hydra:member"]}
          title={t("Actives Sessions List")}
          buttonTxt={t("Delete Selected Sessions")}
          setItemsPerPage={setItemsPerPage}
          totalItems={activeSessionData?.["hydra:totalItems"]}
          setPageNumber={setPageNumber}
          queryFunction={setActiveSessionsEnabled}
        />
      </Grid>
      <ChangeParametersModal
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        setOpenErrorNotChosenUser={setOpenErrorNotChosenUser}
        openSuccess={openSuccess}
        openError={openError}
        openErrorNotChosenUser={openErrorNotChosenUser}
        errorMessage={t("Message Error Delete Active Sessions")}
        errorNotChosenUserMessage={t("Message Error Not Chosen Elements")}
        successMessage={t("Message Success Delete Active Sessions")}
      />
    </Grid>
  );
};
export default ActiveSessions;
