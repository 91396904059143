import React from "react";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material";
import { Theme } from "@emotion/react";

type Props = {
  size?: "small" | "medium" | "large";
  color?: "success" | "error" | "inherit";
  text: string;
  style: SxProps<Theme> | undefined;
  onClickFunction?: () => void;
  type?: "submit" | "reset" | "button";
  isDisabled?: boolean;
};
function CustomButton({
  size,
  color,
  text,
  style,
  type,
  onClickFunction,
  isDisabled,
}: Props) {
  const handleClick = () => {
    if (onClickFunction) {
      onClickFunction();
    }
  };
  return (
    <Button
      size={size ?? "large"}
      type={type ?? "button"}
      color={color ?? "inherit"}
      variant="contained"
      className="button-custom-styles"
      sx={style}
      onClick={handleClick}
      disabled={isDisabled}
    >
      {text}
    </Button>
  );
}

export default CustomButton;
