import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { components, operations } from "schema/schema";

const path = "/api/v1/verification_logs/partner_verification_statement";
const queryKey = "verificationStatement";

type Response =
  components["schemas"]["VerificationLog.VerificationStetamentDto.jsonld"];
type Parameter = operations["verificationStatement"]["parameters"];

const getVerificationStatement = async (parameters: Parameter) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useVerificationStatementQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getVerificationStatement(parameters),
    options
  );
}
