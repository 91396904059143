import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = "/api/v1/p24_transactions";

type Payload =
  components["schemas"]["P24Transaction.P24TransactionRefundInputDto"];

type Params = {
  payload: Payload;
};

const postTransactionRefund = async ({ payload }: Params) => {
  const { data } = await getApiClient().post(`${path}/refund`, payload);
  return data;
};

export default function usePostTransactionRefundMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(
    postTransactionRefund,
    options
  );
}
