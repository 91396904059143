import React, { ReactNode, useRef, useState } from "react";
import List from "@mui/material/List";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, Theme, useTheme } from "@mui/material/styles";
import { Settings } from "config/settingsTheme";
import { NavItemsType } from "types/typesTheme";
import NavigationWrapper from "components/Navigation/NavigationComponents/NavigationWrapper";
import { hexToRGBA } from "hooks/useBgColor";
import NavigationItems from "components/Navigation/NavigationComponents/NavigationItems";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "images/logo-sii.png";
import { routes } from "routing/routes";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  navWidth: string;
  settings: Settings;
  children?: ReactNode;
  isIOS: boolean;
  navVisible: boolean;
  toggleNavVisibility: () => void;
  setNavVisible: (value: boolean) => void;
  NavItems?: NavItemsType;
  saveSettings: (values: Settings) => void;
  NavMenuContent?: (props?: any) => ReactNode;
}

const StyledBoxForShadow = styled(Box)<BoxProps>({
  top: 50,
  left: -8,
  zIndex: 2,
  height: 75,
  display: "none",
  position: "absolute",
  pointerEvents: "none",
  width: "calc(100% + 15px)",
  "&.d-block": {
    display: "block",
  },
});

const NavigationComponent = (props: Props) => {
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const { NavMenuContent: userNavMenuContent } = props;
  const [groupActive, setGroupActive] = useState<string[]>([]);
  const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([]);
  const shadowRef = useRef(null);
  const theme = useTheme();
  const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.spacing(4.5),
    transition: "padding .25s ease-in-out",
    minHeight: theme.mixins.toolbar.minHeight,
  }));

  return (
    <NavigationWrapper {...props} hidden={hidden}>
      <MenuHeaderWrapper className="nav-header" sx={{ pl: 6 }}>
        <Link to={routes.HOME.path} style={{ textDecoration: "none" }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "200px", marginTop: "20px" }}
          />
        </Link>
        {props.isIOS && (
          <Button
            sx={{ position: "absolute", right: "15px", top: "10px" }}
            onClick={() => props.setNavVisible(false)}
          >
            <CloseIcon sx={{ fontSize: "2.5rem" }} />
          </Button>
        )}
      </MenuHeaderWrapper>
      <StyledBoxForShadow
        ref={shadowRef}
        sx={{
          background: `linear-gradient(${
            theme.palette.background.default
          } 40%,${hexToRGBA(
            theme.palette.background.default,
            0.1
          )} 95%,${hexToRGBA(theme.palette.background.default, 0.05)})`,
        }}
      />
      <Box sx={{ height: "100%", position: "relative", mb: "50px" }}>
        <Box
          sx={{
            mt: "50px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {userNavMenuContent ? (
            userNavMenuContent(props)
          ) : (
            <List
              className="nav-items"
              sx={{
                transition: "padding .25s ease",
                pr: 4.5,
                overflowY: "auto",
              }}
            >
              <NavigationItems
                groupActive={groupActive}
                setGroupActive={setGroupActive}
                currentActiveGroup={currentActiveGroup}
                setCurrentActiveGroup={setCurrentActiveGroup}
                {...props}
              />
            </List>
          )}
        </Box>
      </Box>
    </NavigationWrapper>
  );
};

export default NavigationComponent;
