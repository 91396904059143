import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";
import { UserIdType } from "types/types";

const path = "api/v1/user_parameters_permissions/";
const queryKey = "editParametersPermissions";

type Response =
  operations["api_apiv1login_logs_id_get"]["responses"]["200"]["content"]["application/ld+json"];
type UserId = UserIdType;

const getParametersPermissons = async (userId: UserId) => {
  const { data } = await getApiClient().get(`${path}${userId}`);
  return data;
};

export default function useGetParametersPermissonsQuery(
  userId: UserId,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, userId],
    () => getParametersPermissons(userId),
    options
  );
}
