import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { operations } from "schema/schema";
import { Controller, useForm } from "react-hook-form";
import useGetNewslettersTypesQuery from "hooks/useGetNewslettersTypesQuery";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { map, reduce, toPairs } from "lodash";
import useGetNewsletterSubscriberConfigQuery from "hooks/useGetNewsletterSubscriberConfigQuery";
import usePostNewsletterSubscriptionMutation from "hooks/usePostNewsletterSubscriptionMutation";
import CustomButton from "components/Buttons/CustomButton";
import { Loader } from "components/Loader/Loader";

interface UserNewsletterTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
}

interface Props {
  userInformationData: UserNewsletterTypes["userInformationData"];
}

interface FormInputs {
  [arg: string]: boolean;
}

function Subscriptions({ userInformationData }: Props) {
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { data: newsletterSubscriberConfigsData } =
    useGetNewsletterSubscriberConfigQuery({
      query: {
        "newsletterSubscriber.email": userInformationData?.email,
      },
    });
  const { data: newsletterTypesData, refetch } = useGetNewslettersTypesQuery({
    query: {},
  });
  const {
    mutate: newSubscriptionConfig,
    isLoading: newSubscriptionConfigLoading,
  } = usePostNewsletterSubscriptionMutation();
  const {
    control: newsletterTypeControl,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormInputs>();
  const onSubmit = (data: FormInputs) => {
    newSubscriptionConfig(
      {
        subscriptions: [
          {
            email: userInformationData?.email,
            subscriptions: map(toPairs(data), ([key, value]) => {
              return { newsletterType: key, value };
            }),
          },
        ],
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setIsEdit(false);
          refetch();
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
          setIsEdit(false);
          reset();
        },
      }
    );
  };
  useEffect(() => {
    reset(
      reduce(
        newsletterTypesData?.["hydra:member"],
        (acc: FormInputs, current) => {
          const newsletterTypeConfigStatus = newsletterSubscriberConfigsData?.[
            "hydra:member"
          ]?.find((obj) => {
            return obj.newsletterType?.["@id"] === current["@id"];
          });
          return (
            (acc[`${current["@id"]}`] =
              newsletterTypeConfigStatus?.currentConfigChange?.status || false),
            acc
          );
        },
        {}
      )
    );
  }, [newsletterTypesData, newsletterSubscriberConfigsData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {newSubscriptionConfigLoading && <Loader />}
      <FormControl>
        <FormGroup>
          {newsletterSubscriberConfigsData &&
            newsletterTypesData?.["hydra:member"].map((newsletterType) => {
              const newsletterTypeId = newsletterType["@id"];
              return (
                <Controller
                  key={newsletterType.id}
                  name={`${newsletterTypeId}`}
                  control={newsletterTypeControl}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      disabled={!isEdit}
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      }
                      label={newsletterType.name}
                    />
                  )}
                />
              );
            })}
        </FormGroup>
        <CustomButton
          text={!isEdit ? t("Edit") : t("Cancel")}
          style={{ m: 2 }}
          onClickFunction={() => {
            setIsEdit(!isEdit);
            if (isEdit && isDirty) reset();
          }}
        />
        {isEdit && (
          <CustomButton text={t("You Save")} style={{ m: 2 }} type="submit" />
        )}
        <ChangeParametersModal
          openSuccess={openSuccess}
          openError={openError}
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
          errorMessage={t("Message Error Edit User Parameters")}
          successMessage={t("Message Success Edit User Parameters")}
        />
      </FormControl>
    </form>
  );
}

export default Subscriptions;
