import React from "react";
import { Box, Dialog, Snackbar, useMediaQuery } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import { useSettings } from "hooks/useSettings";
import { useTranslation } from "react-i18next";
import CustomButton from "components/Buttons/CustomButton";

export interface ChangePermissionsModalTypes {
  openSuccess?: boolean | null;
  openErrorNotChosenUser?: boolean | null;
  openError?: boolean | null;
  successMessage?: string | null;
  errorMessage?: string | null;
  errorNotChosenUserMessage?: string | null;
  setOpenSuccess?: (param: boolean) => void;
  setOpenError?: (param: boolean) => void;
  setOpenErrorNotChosenUser?: (param: boolean) => void;
  RestartTreeView?: (param: boolean) => void;
}

function ChangeParametersModal({
  openSuccess,
  openErrorNotChosenUser,
  errorNotChosenUserMessage,
  openError,
  errorMessage,
  setOpenSuccess,
  RestartTreeView,
  setOpenError,
  setOpenErrorNotChosenUser,
  successMessage,
}: ChangePermissionsModalTypes) {
  const isOpen = !!(openSuccess || openError || openErrorNotChosenUser);
  const theme = useTheme();
  const { t } = useTranslation();
  const { settings } = useSettings();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (setOpenSuccess) {
      setOpenSuccess(false);
    }
    if (setOpenError) {
      setOpenError(false);
    }
    if (setOpenErrorNotChosenUser) {
      setOpenErrorNotChosenUser(false);
    }
  };
  const handleClick = () => {
    if (RestartTreeView) RestartTreeView(false);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        style: {
          backgroundColor: theme.palette.customColors.modalBgc,
        },
      }}
    >
      <Box
        className="array-modal"
        sx={{
          width: onlySmallScreen ? 300 : 400,
          px: onlySmallScreen ? 5 : "unset",
        }}
      >
        <Snackbar
          open={openSuccess ?? false}
          autoHideDuration={RestartTreeView ? 10000 : 2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{
              width: "100%",
              backgroundColor:
                settings.mode === "light"
                  ? "rgb(240, 250, 232)"
                  : "rgb(99,121,76)",
              opacity: "1",
              color:
                settings.mode === "light" ? "rgb(23,16,16)" : "rgb(17,16,16)",
              textAlign: "center",
            }}
          >
            {successMessage}

            {RestartTreeView && (
              <CustomButton
                text={t("Refresh List")}
                style={{ mt: 3 }}
                onClickFunction={() => handleClick()}
              />
            )}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openErrorNotChosenUser ?? false}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{
              width: "100%",
              backgroundColor:
                settings.mode === "light"
                  ? "rgb(246,208,208)"
                  : "rgb(234,150,150)",
              color:
                settings.mode === "light" ? "rgb(224,66,71)" : "rgb(108,37,38)",
              textAlign: "center",
            }}
          >
            {errorNotChosenUserMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={openError ?? false}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{
              width: "100%",
              backgroundColor:
                settings.mode === "light"
                  ? "rgb(246,208,208)"
                  : "rgb(234,150,150)",
              color:
                settings.mode === "light" ? "rgb(224,66,71)" : "rgb(108,37,38)",
              textAlign: "center",
            }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}

export default ChangeParametersModal;
