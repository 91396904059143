import React, { useEffect, useState } from "react";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { map } from "lodash";
import { useStyles } from "components/PageJunctionProfiles/JunctionsProfilesTable";
import { useTranslation } from "react-i18next";
import {
  BalancesTypes,
  CrmsTypes,
  FormsTypes,
  TransactionsTypes,
} from "types/types";

export type InputValues =
  | TransactionsTypes
  | BalancesTypes
  | CrmsTypes
  | FormsTypes
  | undefined;

interface Props {
  value?: InputValues[];
  onChange: (value: InputValues[]) => void;
  resetAllGroupCheckboxes?: boolean;
  setResetAllGroupCheckboxes?: (value: boolean) => void;
}

function CheckboxSetAllItems({
  value,
  onChange,
  resetAllGroupCheckboxes,
  setResetAllGroupCheckboxes,
}: Props) {
  const [allElements, setAllElements] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (resetAllGroupCheckboxes) {
      setAllElements(false);
      if (setResetAllGroupCheckboxes) {
        setResetAllGroupCheckboxes(false);
      }
    }
  }, [resetAllGroupCheckboxes]);

  return (
    <FormControlLabel
      className={classes.groupCheckbox}
      label={!allElements ? t("Check All") : t("Uncheck All")}
      control={
        <Checkbox
          checked={allElements}
          onChange={(e) => {
            setAllElements(!allElements);
            onChange(e.target.checked ? map(value, "@id") : []);
          }}
        />
      }
    />
  );
}

export default CheckboxSetAllItems;
