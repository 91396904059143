import React, { useEffect, useState } from "react";
import {
  Checkbox,
  MenuItem,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { map } from "lodash";
import { Droppable, Draggable } from "react-beautiful-dnd";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { formValueTypes } from "constans/Constans";
import { InputTextValue } from "components/PageEditForms/EditSingleForm/Components/InputTextValue";
import InputSelectStatusValue from "components/PageEditForms/EditSingleForm/Components/InputSelectStatusValue";
import InputCheckBoxValue from "components/PageEditForms/EditSingleForm/Components/InputCheckBoxValue";
import InputSelectValidateValue from "components/PageEditForms/EditSingleForm/Components/InputSelectValidateValue";
import { Info as InfoIcon } from "@mui/icons-material";
import CustomButton from "components/Buttons/CustomButton";
import { childrenRefType } from "types/types";

interface Props {
  questionIndex: number;
  childrenRef: childrenRefType;
}

export default ({ questionIndex, childrenRef }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [validateIsEnabled, setValidateIsEnabled] = useState(false);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const setReorder = React.useCallback(
    (
      index: string | number,
      reorderCallback: (from: number, to: number) => void
    ) => {
      childrenRef.current[index] = reorderCallback;
    },
    [childrenRef]
  );
  const {
    control,
    setValue,
    formState: { isDirty },
  } = useFormContext();
  const { fields, remove, append, move } = useFieldArray({
    control,
    name: `questions[${questionIndex}].answerVariants`,
  });
  const question = useWatch({
    control: control,
    name: `questions[${questionIndex}]`,
  });
  useEffect(() => {
    question.answerVariants.map((answers: { validate: string | null }) => {
      if (answers.validate) setValidateIsEnabled(true);
    });
  }, []);
  useEffect(() => {
    fields.map((answers, index) =>
      setValue(
        `questions[${questionIndex}].answerVariants[${index}].typePosition`,
        question.typePosition
      )
    );
  }, [question.typePosition]);
  useEffect(() => {
    fields.map((answers, index) =>
      setValue(
        `questions[${questionIndex}].answerVariants[${index}].sequence`,
        index + 1
      )
    );
    setReorder(`child-${questionIndex}`, (from: number, to: number) => {
      move(from, to);
      const updatedFields = [...fields];
      const [removed] = updatedFields.splice(from, 1);
      updatedFields.splice(to, 0, removed);
      updatedFields.forEach((field: any) => {
        field.position = +1;
      });
      fields.map((answers, index) =>
        setValue(
          `questions[${questionIndex}].answerVariants[${index}].sequence`,
          index + 1
        )
      );
    });
  }, [questionIndex, setReorder, move, isDirty, fields]);
  return (
    <>
      {![5, 7].includes(question.typePosition) && (
        <Droppable droppableId={`child-${questionIndex}`} type="childContainer">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ width: "1040px" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Answer Text")}</TableCell>
                    <TableCell>{t("Type")}</TableCell>
                    <TableCell>{t("Required")}</TableCell>
                    <TableCell>{t("Visibility")}</TableCell>
                    <TableCell>{t("Position")}</TableCell>
                    <TableCell sx={{ pr: "0 !important" }}>
                      {t("Validate")}
                      <Checkbox
                        checked={validateIsEnabled}
                        onChange={() =>
                          setValidateIsEnabled(!validateIsEnabled)
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(fields, (item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        let transform =
                          provided?.draggableProps?.style?.transform;
                        if (snapshot.isDragging && transform) {
                          transform = transform.replace(/\(.+\,/, "(0,");
                        }
                        const style = {
                          ...provided.draggableProps.style,
                          transform,
                        };
                        return (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={style}
                          >
                            <TableCell sx={{ width: "400px" }}>
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "inline-block",
                                  fontSize: "16px",
                                  paddingRight: "12px",
                                }}
                                onClick={(
                                  event: React.MouseEvent<HTMLDivElement>
                                ) => {
                                  return setAnchorEl(event.currentTarget);
                                }}
                              >
                                <InfoIcon />
                              </div>
                              <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <Controller
                                  name={`questions[${questionIndex}].answerVariants[${index}].description`}
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <TextField
                                      value={value}
                                      onChange={onChange}
                                      placeholder={t("Description") as string}
                                    />
                                  )}
                                />
                              </Popover>
                              <label
                                htmlFor={`questions[${questionIndex}].answerVariants[${index}].text`}
                              >
                                {t("Answer")} {index + 1}:
                              </label>
                              <InputTextValue
                                name={`questions[${questionIndex}].answerVariants[${index}].text`}
                              />
                            </TableCell>
                            <TableCell sx={{ width: "150px" }}>
                              <Controller
                                name={`questions[${questionIndex}].answerVariants[${index}].typePosition`}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <Select
                                    size="small"
                                    value={value}
                                    disabled={question.enteredAnswerToQuestion}
                                    onChange={onChange}
                                    className="edit-form-status"
                                    id={`questions[${questionIndex}].answerVariants[${index}].typePosition`}
                                  >
                                    {formValueTypes
                                      .filter(
                                        (formValue) =>
                                          formValue.value ===
                                            question.typePosition ||
                                          formValue.value === 2
                                      )
                                      .map((formValue) => (
                                        <MenuItem
                                          key={formValue.value}
                                          value={formValue.value}
                                        >
                                          {formValue.name}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <InputCheckBoxValue
                                name={`questions[${questionIndex}].answerVariants[${index}].required`}
                              />
                            </TableCell>
                            <TableCell>
                              <InputSelectStatusValue
                                name={`questions[${questionIndex}].answerVariants[${index}].status`}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={`questions[${questionIndex}].answerVariants[${index}].rowColumn`}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <Select
                                    size="small"
                                    className="edit-form-status"
                                    id={`questions[${questionIndex}].answerVariants[${index}].rowColumn`}
                                    value={value}
                                    onChange={onChange}
                                  >
                                    <MenuItem value={1}>{t("Row")}</MenuItem>
                                    <MenuItem value={2}>{t("Column")}</MenuItem>
                                  </Select>
                                )}
                              />
                            </TableCell>
                            {validateIsEnabled && (
                              <TableCell>
                                <InputSelectValidateValue
                                  name={`questions[${questionIndex}].answerVariants[${index}].validate`}
                                />
                              </TableCell>
                            )}
                            <TableCell>
                              <CustomButton
                                text={t("Delete")}
                                style={{ m: 2 }}
                                size="small"
                                color="error"
                                onClickFunction={() => remove(index)}
                              />
                              <OpenWithIcon sx={{ opacity: "0.7" }} />
                            </TableCell>
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  ))}
                </TableBody>
              </Table>
              {provided.placeholder}
              <CustomButton
                text={t("Add Answer")}
                style={{ m: 2 }}
                size="small"
                color="success"
                onClickFunction={() =>
                  append({
                    sequence: question.length + 1,
                    typePosition: question.typePosition,
                    rowColumn: 1,
                    required: false,
                    status: 1,
                    id: undefined,
                    validate: undefined,
                    text: "",
                    description: "",
                  })
                }
              />
            </div>
          )}
        </Droppable>
      )}
    </>
  );
};
