import React, { useState } from "react";
import { Box, FormControl, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled, useTheme } from "@mui/material/styles";
import DeclarationContent from "components/PageMembershipVerification/DeclarationContent";
import useGetMembershipValidationQuery from "hooks/useGetMembershipValidationQuery";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";

interface FormInputs {
  firstName?: string;
  lastName?: string;
  verificationValue: string;
}

const TextFieldCustom = styled(TextField)<TextFieldProps>(({ theme }) => ({
  backgroundColor: theme.palette.customColors.customBg,
  borderRadius: "6px",
}));

function VerificationForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [message, setMessage] = useState("");
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [formValues, setFormValues] = useState<FormInputs>({
    firstName: "",
    lastName: "",
    verificationValue: "",
  });
  const { control: VerificationFormControl, handleSubmit } =
    useForm<FormInputs>();
  const { data, isLoading } = useGetMembershipValidationQuery(
    {
      query: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        emailOrCardNumber: formValues.verificationValue,
      },
    },
    {
      retry: false,
      enabled: filterEnabled,
      onSettled: () => {
        setFilterEnabled(false);
      },
      onSuccess: () => {
        setMessage("");
      },
      onError: (error) => {
        setMessage(error.message);
      },
    }
  );
  const onSubmit = (data: FormInputs) => {
    setFormValues(data);
    setFilterEnabled(true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: onlySmallScreen ? "200px" : "300px",
          margin: "0 auto",
        }}
      >
        <FormControl sx={{ my: 3 }}>
          <Controller
            control={VerificationFormControl}
            name="firstName"
            render={({ field: { value, onChange } }) => (
              <TextFieldCustom
                value={value}
                onChange={onChange}
                variant="outlined"
                size="small"
                type="text"
                label={t("First Name")}
              />
            )}
          />
        </FormControl>
        <FormControl sx={{ my: 3 }}>
          <Controller
            control={VerificationFormControl}
            name="lastName"
            render={({ field: { value, onChange } }) => (
              <TextFieldCustom
                value={value}
                onChange={onChange}
                variant="outlined"
                size="small"
                type="text"
                label={t("Last Name")}
              />
            )}
          />
        </FormControl>
        <FormControl sx={{ my: 3 }}>
          <Controller
            control={VerificationFormControl}
            name="verificationValue"
            render={({ field: { value, onChange } }) => (
              <TextFieldCustom
                value={value}
                onChange={onChange}
                variant="outlined"
                size="small"
                type="text"
                label={`${t("Membership Number")} / ${t("Email")}*`}
              />
            )}
          />
        </FormControl>
        <CustomButton
          text={t("Verify")}
          style={{ m: 2, mb: 4 }}
          type="submit"
        />
      </Box>
      {isLoading && <Loader />}
      {data?.["hydra:member"][0] ? (
        <DeclarationContent
          profileStatus={data?.["hydra:member"][0].profileStatus}
          cardNumber={data?.["hydra:member"][0].cardNumber}
          fullName={data?.["hydra:member"][0].fullName}
          activeStatus={data?.["hydra:member"][0].active}
          email={data?.["hydra:member"][0].email}
          statementTypeForMembershipVerification={
            data?.["hydra:member"][0].statementTypeForMembershipVerification
          }
          currentContributionLevelExpirationDate={
            data?.["hydra:member"][0].currentContributionLevelExpirationDate
          }
          contributionPeriod={data?.["hydra:member"][0].contributionPeriod}
          verificationValue={formValues.verificationValue}
        />
      ) : message !== "" ? (
        <p style={{ textAlign: "center", margin: "50px 0" }}>{t("No Data")}</p>
      ) : undefined}
    </form>
  );
}

export default VerificationForm;
