import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { Control, Controller } from "react-hook-form";
import { FormInputs, RegisteredState } from "pages/Users/Users";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Buttons/CustomButton";

export const useStyles = makeStyles((theme) => ({
  usersSectionWrapper: {
    marginTop: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
      alignItems: "unset",
      flexDirection: "row",
    },
  },
}));

type Props = { control: Control<FormInputs>; resetForm: () => void };

function UsersAndMembers({ control, resetForm }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Box className={classes.usersSectionWrapper}>
        <div style={{ display: "inline-block" }}>
          <Controller
            control={control}
            name="usersAndMembersIsPaidUsers"
            render={({ field: { value, onChange } }) => (
              <Checkbox checked={value} onChange={onChange} />
            )}
          />
          <Typography className="users-checkbox-text">{t("Show")}</Typography>
        </div>
        <FormControl sx={{ width: 200 }}>
          <Controller
            control={control}
            name="usersAndMembersIsPaidUsersValue"
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={onChange}
                id="users-filters-select-users-paid"
                defaultValue="Paid"
              >
                <MenuItem value="paid">{t("Paid")}</MenuItem>
                <MenuItem value="unpaid">{t("Unpaid")}</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <Typography className="users-checkbox-text">{t("Users-v2")}</Typography>
      </Box>
      <Box className={classes.usersSectionWrapper}>
        <div style={{ display: "inline-block" }}>
          <Controller
            control={control}
            name="usersAndMembersIsActiveMember"
            render={({ field: { value, onChange } }) => (
              <Checkbox checked={value} onChange={onChange} />
            )}
          />
          <Typography className="users-checkbox-text">{t("Show")}</Typography>
        </div>
        <FormControl sx={{ width: 200 }}>
          <Controller
            control={control}
            name="usersAndMembersIsActiveMemberValue"
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={onChange}
                id="users-filters-select-members-paid"
                defaultValue="active"
              >
                <MenuItem value="active">{t("Active")}</MenuItem>
                <MenuItem value="inactive">{t("Inactive")}</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <Typography className="users-checkbox-text">
          {t("Members-v2")}
        </Typography>
      </Box>
      <Box className={classes.usersSectionWrapper}>
        <div style={{ display: "inline-block" }}>
          <Controller
            control={control}
            name="activityRegisteredOrNotRegisteredChecked"
            render={({ field: { value, onChange } }) => (
              <Checkbox checked={value} onChange={onChange} />
            )}
          />
          <Typography
            sx={{ display: "inline-block", lineHeight: "56px", mx: 4 }}
          >
            {t("Show Users Registered Within")}
          </Typography>
        </div>
        <FormControl sx={{ width: 175 }}>
          <Controller
            control={control}
            name="activityRegisteredOrNotRegisteredProperties"
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={onChange}
                id="activity-logged-select"
              >
                <MenuItem value={RegisteredState.Registered}>
                  {t("Registered")}
                </MenuItem>
                <MenuItem value={RegisteredState.NotRegistered}>
                  {t("Not Registered")}
                </MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <Typography sx={{ display: "inline-block", lineHeight: "56px", mx: 4 }}>
          {t("Within")}
        </Typography>
        <div
          style={{
            marginTop: onlySmallScreen ? "15px" : 0,
            display: "inline-block",
          }}
        >
          <FormControl sx={{ width: 70 }}>
            <Controller
              control={control}
              name="activityRegisteredOrNotRegisteredDaysField"
              render={({ field: { value, onChange } }) => (
                <TextField
                  id="outlined-basic"
                  value={value}
                  onChange={onChange}
                  name="users-register-within-number-field"
                  type="number"
                />
              )}
            />
          </FormControl>
          <Typography
            sx={{ display: "inline-block", lineHeight: "56px", mx: 4 }}
          >
            {t("Days")}
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          my: 5,
          display: onlySmallScreen ? "flex" : "unset",
          flexDirection: "column",
        }}
      >
        <CustomButton
          text={t("Show")}
          style={{ mr: 5, m: onlySmallScreen ? 5 : 0 }}
          type="submit"
        />
        <CustomButton
          text={t("Clear Form")}
          style={{ m: 5 }}
          onClickFunction={() => resetForm()}
        />
      </Box>
    </div>
  );
}

export default UsersAndMembers;
