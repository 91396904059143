import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { components } from "schema/schema";
import { UserIdType } from "types/types";

const path = "/api/v1/users/transfer_data";

type Payload = {
  user?: components["schemas"]["User.jsonld"];
  transactions?: string[];
  crms?: string[];
  formHistory?: string[];
  balances?: string[];
  createCrm?: boolean;
};
type Response = components["schemas"]["User.UserTransferInputDto-user.write"];
type UserId = UserIdType;

type Params = {
  payload: Payload;
  userId: UserId;
  headers: { accept: string; "Content-Type": string };
};

const putJunctionProfiles = async ({ userId, payload, headers }: Params) => {
  const { data } = await getApiClient().put(`${path}/${userId}`, payload, {
    headers,
  });
  return data;
};

export default function usePutJunctionProfilesMutation(
  options: UseMutationOptions<Response, AxiosError, Params, Headers> = {}
) {
  return useMutation<Response, AxiosError, Params, Headers>(
    putJunctionProfiles,
    options
  );
}
