import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

type inputProps = {
  name: string;
};

function InputSelectStatusValue({ name }: inputProps) {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <div
          data-tip={
            value === 0
              ? t("Visible To Everyone")
              : value === 1
              ? t("Visible To Operator")
              : value === 3
              ? t("Visible To Filled")
              : value === 4
              ? t("Hide To Everyone")
              : ""
          }
        >
          <Select
            size="small"
            sx={{ width: "100px" }}
            className="edit-form-status"
            id={name}
            value={value}
            onChange={onChange}
          >
            <MenuItem value={0}>(0){t("Visible To Everyone")}</MenuItem>
            <MenuItem value={1}>(1){t("Visible To Operator")}</MenuItem>
            <MenuItem value={3}>(3){t("Visible To Filled")}</MenuItem>
            <MenuItem value={4}>(4){t("Hide To Everyone")}</MenuItem>
          </Select>
        </div>
      )}
    />
  );
}

export default InputSelectStatusValue;
