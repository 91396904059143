import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserInformationMutation from "hooks/useUserInformationMutation";
import { Controller, useForm } from "react-hook-form";
import { operations } from "schema/schema";
import { Loader } from "components/Loader/Loader";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { useTheme } from "@mui/material/styles";
import useGetDepartmentsQuery from "hooks/useGetDepartmentsQuery";
import { dateFormat } from "constans/Constans";
import EventIcon from "@mui/icons-material/Event";
import { formatValueDatepicker, Patterns } from "helpers/Global";
import CustomButton from "components/Buttons/CustomButton";

interface FormInputs {
  cardNumber: number;
  userDepartmentsValue: string | undefined;
  declarationFulfilmentDate: string | null;
  declarationReceiptDate: string | null;
  inMembershipSince: string | null;
  membershipPaidTo?: string | null;
  contributionExpirationDate?: string | null;
}
interface MembershipTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
  refetch: () => void;
}
function Membership({ userInformationData, refetch }: MembershipTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [isEdit, setIsEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const { mutate: changeOthersDetails, isLoading: changeOthersDetailsLoading } =
    useUserInformationMutation();
  const { data: departmentsData } = useGetDepartmentsQuery({
    query: { status: 0 },
  });
  let date = dateFormat;
  const formParams = {
    cardNumber: userInformationData?.cardNumber,
    userDepartmentsValue: userInformationData?.department
      ? userInformationData?.department.substring(20, 22)
      : "",
    declarationFulfilmentDate: userInformationData?.declarationFulfilmentDate
      ? userInformationData?.declarationFulfilmentDate?.substring(0, 1) === "-"
        ? date
        : userInformationData?.declarationFulfilmentDate
      : null,
    declarationReceiptDate: userInformationData?.declarationReceiptDate
      ? userInformationData?.declarationReceiptDate?.substring(0, 1) === "-"
        ? date
        : userInformationData?.declarationReceiptDate
      : null,
    inMembershipSince: userInformationData?.inMembershipSince
      ? userInformationData?.inMembershipSince?.substring(0, 1) === "-"
        ? date
        : userInformationData?.inMembershipSince
      : null,
    contributionExpirationDate: userInformationData?.contributionExpirationDate,
  };
  const {
    control: MembershipControl,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FormInputs>({
    defaultValues: formParams,
  });
  const onSubmit = ({
    cardNumber,
    declarationFulfilmentDate,
    declarationReceiptDate,
    inMembershipSince,
    userDepartmentsValue,
  }: FormInputs) => {
    changeOthersDetails(
      {
        payload: {
          cardNumber: parseInt(String(cardNumber)),
          declarationFulfilmentDate: declarationFulfilmentDate
            ? new Date(declarationFulfilmentDate).toLocaleString()
            : null,
          declarationReceiptDate: declarationReceiptDate
            ? new Date(declarationReceiptDate).toLocaleString()
            : null,
          inMembershipSince: inMembershipSince
            ? new Date(inMembershipSince).toLocaleString()
            : null,
          department: `/api/v1/departments/${userDepartmentsValue}`,
        },
        userId: userInformationData?.id,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setIsEdit(false);
          refetch();
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
          setIsEdit(false);
          reset();
        },
      }
    );
  };
  useEffect(() => {
    reset(formParams);
  }, [userInformationData]);
  return (
    <div>
      {changeOthersDetailsLoading && <Loader />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: onlySmallScreen ? "flex" : "inherit",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={t("Language Code") as string}
        >
          <FormControl
            sx={{
              width: onlySmallScreen ? 200 : 100,
              mx: 2,
              my: onlyLargeScreen ? 4 : 2,
            }}
          >
            <Controller
              control={MembershipControl}
              name="cardNumber"
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  variant="standard"
                  inputProps={{
                    maxLength: 7,
                    pattern: Patterns.numericValues,
                  }}
                  label={t("Card Number")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    disabled: !isEdit,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl
            sx={{ width: "300", mx: 2, my: onlyLargeScreen ? 4 : 2 }}
          >
            <InputLabel id="departments-multiple-checkbox-label">
              {t("Department")}
            </InputLabel>
            <Controller
              control={MembershipControl}
              name="userDepartmentsValue"
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  label={t("Department")}
                  sx={{ width: 250 }}
                  disabled={!isEdit}
                  id="multiple-checkbox"
                >
                  {departmentsData?.["hydra:member"].map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          {(!userInformationData?.declarationFulfilmentDate ||
            userInformationData?.declarationFulfilmentDate?.substring(0, 1) ===
              "-") &&
            !isEdit && (
              <TextField
                value={t("None")}
                variant="standard"
                type="string"
                sx={{ width: 180, m: 2 }}
                label={t("Declaration Fulfilment Date")}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true, disableUnderline: true }}
              />
            )}
          {((userInformationData?.declarationFulfilmentDate &&
            userInformationData?.declarationFulfilmentDate?.substring(0, 1) !==
              "-") ||
            isEdit) && (
            <FormControl
              sx={{
                width: 200,
                my: onlyLargeScreen ? 4 : 2,
              }}
            >
              <Controller
                control={MembershipControl}
                name="declarationFulfilmentDate"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    value={value || null}
                    onChange={onChange}
                    disabled={!isEdit}
                    label={t("Declaration Fulfilment Date")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EventIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputFormat={formatValueDatepicker}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "180px", mx: 2 }} />
                    )}
                  />
                )}
              />
            </FormControl>
          )}
          {!userInformationData?.declarationReceiptDate && !isEdit && (
            <TextField
              value={t("None")}
              variant="standard"
              type="string"
              sx={{ width: 180, m: 2 }}
              label={t("Declaration Receipt Date")}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true, disableUnderline: true }}
            />
          )}
          {(userInformationData?.declarationReceiptDate || isEdit) && (
            <FormControl sx={{ width: 200, my: onlyLargeScreen ? 4 : 2 }}>
              <Controller
                control={MembershipControl}
                name="declarationReceiptDate"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    disabled={!isEdit}
                    label={t("Declaration Receipt Date")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EventIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputFormat={formatValueDatepicker}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "180px", mx: 2 }} />
                    )}
                  />
                )}
              />
            </FormControl>
          )}
          {!userInformationData?.inMembershipSince && !isEdit && (
            <TextField
              value={t("None")}
              variant="standard"
              type="string"
              sx={{ width: 180, m: 2 }}
              label={t("In Membership Since")}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true, disableUnderline: true }}
              inputProps={{ style: { cursor: "default" } }}
            />
          )}
          {(userInformationData?.inMembershipSince || isEdit) && (
            <FormControl sx={{ width: 200, my: 2 }}>
              <Controller
                control={MembershipControl}
                name="inMembershipSince"
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    value={value || null}
                    onChange={onChange}
                    disabled={!isEdit}
                    label={t("In Membership Since")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <EventIcon />
                        </InputAdornment>
                      ),
                    }}
                    inputFormat={formatValueDatepicker}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "180px", mx: 2 }} />
                    )}
                  />
                )}
              />
            </FormControl>
          )}
          <TextField
            value={
              userInformationData?.contributionExpirationDate?.substring(
                0,
                4
              ) === "9999"
                ? t("For Life")
                : userInformationData?.contributionExpirationDate === null
                ? t("Not Paid")
                : userInformationData?.contributionExpirationDate
                ? userInformationData?.contributionExpirationDate.slice(0, 10)
                : undefined
            }
            variant="standard"
            type="string"
            sx={{ width: 175, m: 2 }}
            label={t("Membership Paid To")}
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: true, disableUnderline: true }}
            inputProps={{ style: { cursor: "default" } }}
          />
          <Box>
            <CustomButton
              text={!isEdit ? t("Edit") : t("Cancel")}
              style={{ m: 2 }}
              onClickFunction={() => {
                setIsEdit(!isEdit);
                if (isEdit && isDirty) reset();
              }}
            />
            {isEdit && (
              <CustomButton
                text={t("You Save")}
                style={{ m: 2 }}
                type="submit"
              />
            )}
          </Box>
        </LocalizationProvider>
      </form>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit User Parameters")}
        successMessage={t("Message Success Edit User Parameters")}
      />
    </div>
  );
}

export default Membership;
