import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import TableButton from "components/PageContactTypes/TableButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { Controller, useForm, useWatch } from "react-hook-form";
import usePutContactTypeMutation from "hooks/usePutContactTypeMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { components } from "schema/schema";
import { Loader } from "components/Loader/Loader";

interface TableRowValuesType {
  data: components["schemas"]["CrmType.jsonld"];
  setSuccessDelete: (value: boolean) => void;
}

interface FormInputs {
  editTypeValue: number;
  editTypeName?: string;
}

function TableRowValues({ data, setSuccessDelete }: TableRowValuesType) {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { mutate, isLoading } = usePutContactTypeMutation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const formParams = {
    editTypeName: data.name,
    editTypeValue: data.kindValue,
  };
  const {
    control: EditCrmControl,
    formState: { isDirty },
    reset,
  } = useForm<FormInputs>({
    defaultValues: formParams,
  });
  const { editTypeName, editTypeValue } = useWatch({
    control: EditCrmControl,
  });
  const onToggleEditMode = (id: number | undefined) => {
    mutate(
      {
        id: id,
        payload: {
          name: editTypeName ?? undefined,
          kindValue: editTypeValue ?? undefined,
        },
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setSuccessDelete(true);
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
        },
      }
    );
  };
  useEffect(() => {
    reset(formParams);
  }, [data]);

  return (
    <TableRow key={data.id}>
      {isLoading && <Loader />}
      <TableCell sx={{ position: "relative" }}>
        <FormControl>
          <Controller
            control={EditCrmControl}
            name="editTypeName"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                disabled={!isEdit}
                sx={{ width: onlySmallScreen ? "100%" : 320 }}
                size="small"
                variant="standard"
              />
            )}
          />
        </FormControl>
      </TableCell>
      <TableCell sx={{ position: "relative" }}>
        <FormControl>
          <Controller
            control={EditCrmControl}
            name="editTypeValue"
            render={({ field: { value, onChange } }) => (
              <Select
                sx={{ width: onlySmallScreen ? 150 : 200 }}
                disabled={!isEdit}
                value={value}
                onChange={onChange}
                size="small"
                variant="standard"
                id="multiple-checkbox"
              >
                <MenuItem value={0}>{t("Normal")}</MenuItem>
                <MenuItem value={1}>{t("Discount")}</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </TableCell>
      <TableCell className="contact-types-buttons-wrapper">
        <TableButton
          id={data?.id}
          setIsEdit={setIsEdit}
          setSuccessDelete={setSuccessDelete}
        />
        {isEdit && (
          <>
            <Button
              className="contact-types-button"
              sx={{
                left: "10px",
              }}
              size="small"
              variant="outlined"
              onClick={() => {
                if (isEdit && isDirty) reset();
                setIsEdit(false);
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              className="contact-types-button"
              sx={{
                left: "100px",
              }}
              size="small"
              type="submit"
              variant="outlined"
              color="secondary"
              onClick={() => {
                setIsEdit(false);
                if (isEdit && isDirty) onToggleEditMode(data?.id);
              }}
            >
              {t("You Save")}
            </Button>
          </>
        )}
      </TableCell>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit Type")}
        successMessage={t("Message Success Edit Type")}
      />
    </TableRow>
  );
}

export default TableRowValues;
