import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components, operations } from "schema/schema";

const path = "/api/v1/p24_transactions";

type Payload = components["schemas"]["P24Transaction.jsonld"];
type Mode =
  operations["api_apiv1p24_transactions_post"]["parameters"]["query"]["mode"];

type Params = {
  payload: Payload;
  mode: Mode;
};

const postTransaction = async ({ mode, payload }: Params) => {
  const { data } = await getApiClient().post(`${path}?mode=${mode}`, payload);
  return data;
};

export default function usePostTransactionMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(postTransaction, options);
}
