import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = "/api/v1/payment_types";

type Payload = components["schemas"]["PaymentType.jsonld"];
type Response = components["schemas"]["PaymentType.jsonld"];
const postPaymentTypes = async (payload: Payload) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function usePostPaymentTypesMutation(
    options: UseMutationOptions<any, AxiosError, any> = {}
    ) {
        return useMutation<Response, AxiosError, Payload>(
            postPaymentTypes,
            options
            );
}
