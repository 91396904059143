import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components, operations } from "schema/schema";

const path = "/api/v1/forms/";

type Payload = components["schemas"]["Form.jsonld-form.extended"];
type Response = operations["api_apiv1forms_id_put"]["responses"];
type FormId = number | undefined;

type Params = {
  payload: Payload;
  formId: FormId;
};

const putForm = async ({ formId, payload }: Params) => {
  const { data } = await getApiClient().put(`${path}${formId}`, payload);
  return data;
};

export default function usePutFormMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putForm, options);
}
