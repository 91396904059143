import React from "react";
import { Control, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { regexTextValues, zipCodeValidation } from "helpers/Global";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  label: string;
  control: Control<any>;
  editedValue?: boolean;
  countryCode?: string;
  variant?: "standard" | "outlined";
  isShrink?: boolean;
}

function ZipCodeFieldValidate({
  name,
  label,
  control,
  editedValue,
  countryCode,
  variant,
  isShrink,
}: Props) {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          value={value}
          onChange={onChange}
          variant={variant}
          type="string"
          label={label}
          InputLabelProps={{ shrink: isShrink }}
          InputProps={{
            disabled: !editedValue,
          }}
          inputProps={{
            maxLength: countryCode === "616" ? 6 : 10,
          }}
          error={
            (Boolean(!zipCodeValidation(value, countryCode, editedValue)) ||
              (editedValue &&
                countryCode === "616" &&
                Boolean(regexTextValues.test(value)))) &&
            value !== ""
          }
          helperText={
            !zipCodeValidation(value, countryCode, editedValue) && value !== ""
              ? t("Message Error ZipCode Format")
              : ""
          }
          FormHelperTextProps={{
            style: {
              width: "170px",
            },
          }}
        />
      )}
    />
  );
}

export default ZipCodeFieldValidate;
