import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { operations } from "schema/schema";
import useUserInformationMutation from "hooks/useUserInformationMutation";
import { Loader } from "components/Loader/Loader";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "components/Buttons/CustomButton";

interface UserNotesTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
  refetch: () => void;
}

interface FormInputs {
  comment: string;
}
function Note({ userInformationData, refetch }: UserNotesTypes) {
  const { t } = useTranslation();
  const { mutate: changeContactDetails, isLoading: changeCommentLoading } =
    useUserInformationMutation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const formParams = {
    comment: userInformationData?.comment,
  };

  let {
    control: ContactDetailsControl,
    handleSubmit,
    reset,
  } = useForm<FormInputs>({
    defaultValues: formParams,
  });

  const onSubmit = ({ comment }: FormInputs) => {
    changeContactDetails(
      {
        payload: {
          comment: comment,
        },
        userId: userInformationData?.id,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          refetch();
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
        },
      }
    );
  };

  useEffect(() => {
    reset(formParams);
  }, [userInformationData]);

  return (
    <div>
      {changeCommentLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={ContactDetailsControl}
          name="comment"
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              multiline
              minRows={3}
              sx={{ width: "100%" }}
              onChange={onChange}
            />
          )}
        />
        <CustomButton text={t("You Save")} style={{ m: 2 }} type="submit" />
      </form>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Added Comment")}
        successMessage={t("Message Success Added Comment")}
      />
    </div>
  );
}

export default Note;
