import React, { useState } from "react";
import { Card, CardHeader, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { useSettings } from "hooks/useSettings";
import useGetFormsQuery from "hooks/useGetFormsQuery";
import { useForm, useWatch } from "react-hook-form";
import AnswersTable from "components/PageFormAnswers/AnswersTable";
import { Loader } from "components/Loader/Loader";
import useGetAnswersFormQuery from "hooks/useGetAnswersFormQuery";
import SelectAutocomplete from "components/PageEditForms/SelectAutocomplete";

function FormAnswers() {
  const { settings } = useSettings();
  const { t } = useTranslation();
  const [isFocusedSelect, setIsFocusedSelect] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const matchResult = inputValue.match(/^(.*?)\s?\( ID:/);
  const { control: control, setValue } = useForm<{
    formId: string | null;
  }>({
    defaultValues: {
      formId: "",
    },
  });
  const { data: formsData, isLoading: formDataLoading } = useGetFormsQuery(
    {
      query: {
        pagination: false,
        text: matchResult ? matchResult[1] : undefined,
      },
    },
    {
      enabled: inputValue === "" || inputValue.length > 2,
    }
  );
  const { formId } = useWatch({ control });
  const { data: singleFormData } = useGetAnswersFormQuery(
    JSON.stringify(formId),
    { query: {} },
    {
      enabled: !!formId,
      cacheTime: 0,
    }
  );

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
        overflow: "unset",
      }}
    >
      {formDataLoading && <Loader />}
      <CardHeader title={t("Forms-Answers")} />
      <Divider />
      <form style={{ padding: "15px" }}>
        <SelectAutocomplete
          inputValue={inputValue}
          control={control}
          setValue={setValue}
          setInputValue={setInputValue}
          formsData={formsData}
          isFocusedSelect={isFocusedSelect}
          setIsFocusedSelect={setIsFocusedSelect}
        />
      </form>
      {singleFormData && inputValue !== "" && (
        // @ts-ignore
        <AnswersTable singleFormData={singleFormData} formId={formId} />
      )}
    </Card>
  );
}

export default FormAnswers;
