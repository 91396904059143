import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = `${API_PREFIX}/contribution_discount_codes/`;

type Payload = components["schemas"]["ContributionDiscountCode.jsonld"];
type Response = components["schemas"]["ContributionDiscountCode.jsonld"];
type ContributionDiscountCodeId = components["schemas"]["ContributionDiscountCode.jsonld"]["id"]
type Params = {
  payload: Payload;
  contributionDiscountCodeId: ContributionDiscountCodeId;
};
const putDiscountCode = async ({ contributionDiscountCodeId, payload }: Params) => {
  const { data } = await getApiClient().put(`${path}${contributionDiscountCodeId}`, payload);
  return data;
};

export default function usePutDiscountCodeMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putDiscountCode, options);
}
