import React, { useState, MouseEvent } from "react";
import { PencilOutline } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DotsVertical from "mdi-material-ui/DotsVertical";
import IconButton from "@mui/material/IconButton";
import {discountCodesTypes} from "types/types";


interface ActionsButtonType {
  rowData: discountCodesTypes ;
  setDiscountRowData: (data: discountCodesTypes) => void;
  setOpenEditDiscountModal: (value: boolean) => void;
}

const ActionsButtonDiscountButtonTable = ({
  rowData,
  setDiscountRowData,
  setOpenEditDiscountModal,
}: ActionsButtonType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const { t } = useTranslation();
  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <DotsVertical />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem
          onClick={() => {
            setDiscountRowData(rowData);
            setOpenEditDiscountModal(true);
          }}
          sx={{ p: 0 }}
        >
          <MenuItem>
            <PencilOutline fontSize="small" sx={{ mr: 2 }} />
            {t("Edit")}
          </MenuItem>
        </MenuItem>
      </Menu>
    </>
  );
};
export default ActionsButtonDiscountButtonTable;
