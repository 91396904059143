import { useQuery, UseQueryOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = `${API_PREFIX}/newsletter_subscribers_total_items`;
const queryKey = "newsletterSubscriberstotalItems";

type Response = operations["totalItemsNewslettersSubscribers"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter =
  operations["totalItemsNewslettersSubscribers"]["parameters"];

const getNewslettersSubscribersTotalItems = async (
  parameters: Parameter
) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetNewsletterSubscribersTotalItemsQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getNewslettersSubscribersTotalItems(parameters),
    options
  );
}
