import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Box, FormControl, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ActivesSessionsModal from "components/PageEditUser/ActivesSessionsModal";
import { operations } from "schema/schema";
import { useTheme } from "@mui/material/styles";
import useChangePasswordMutation from "hooks/useChangePasswordMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { formattedDate } from "helpers/Global";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";

interface LoginParametersTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
}
interface LoginParametersChangedTypes {
  password: string;
}

function LoginParameters({ userInformationData }: LoginParametersTypes) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showActiveSessions, setShowActiveSessions] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const theme = useTheme();
  const { mutate: changePassword, isLoading: changePasswordLoading } =
    useChangePasswordMutation();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlyExtraLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const schema = yup.object().shape({
    password: yup.string().min(5).required(),
  });
  const formParams = {
    password: "",
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: formParams,
  });
  const onSubmit = ({ password }: LoginParametersChangedTypes) => {
    changePassword(
      {
        payload: {
          newPassword: password,
        },
        userId: userInformationData?.id,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setIsEdit(false);
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
          setIsEdit(false);
          reset();
        },
      }
    );
  };
  useEffect(() => {
    reset(formParams);
  }, [openSuccess, openError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {changePasswordLoading && <Loader />}
      <div
        style={{
          display: onlyMediumScreen
            ? "block"
            : onlyExtraLargeScreen
            ? "flex"
            : "unset",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: onlyExtraLargeScreen ? "column" : "row",
          }}
        >
          <TextField
            value={formattedDate(
              userInformationData?.addedDate,
              "yyyy-MM-dd HH:mm"
            )}
            variant="standard"
            type="string"
            sx={{ width: 200, m: 2 }}
            label={t("Registration Date")}
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: true, disableUnderline: true }}
            inputProps={{ style: { cursor: "default" } }}
          />
          <TextField
            value={
              userInformationData?.passwordSetDate
                ? formattedDate(
                    userInformationData?.passwordSetDate,
                    "yyyy-MM-dd HH:mm"
                  )
                : t("Password Has Not Been Changed")
            }
            variant="standard"
            sx={{ width: 200, m: 2 }}
            label={t("Password Changed Date")}
            InputLabelProps={{ shrink: true }}
            InputProps={{ readOnly: true, disableUnderline: true }}
            inputProps={{ style: { cursor: "default" } }}
          />
        </Box>
        <Box
          sx={{
            display: onlyExtraLargeScreen ? "block" : "flex",
            flexDirection: "row",
            my: 5,
          }}
        >
          <FormControl sx={{ mr: 3 }}>
            <Controller
              name="password"
              control={control}
              render={({ field: { value, onChange } }) => (
                <OutlinedInput
                  value={value}
                  onChange={onChange}
                  placeholder={t("Password") as string}
                  id="auth-login-v2-password"
                  disabled={!isEdit}
                  error={Boolean(errors.password)}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        disabled={!isEdit}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
          </FormControl>
          <CustomButton
            text={!isEdit ? t("Change Password") : t("Cancel")}
            style={{ m: 2 }}
            onClickFunction={() => {
              setIsEdit(!isEdit);
              if (isEdit && isDirty) reset();
            }}
          />
          {isEdit && (
            <CustomButton text={t("You Save")} style={{ m: 2 }} type="submit" />
          )}
        </Box>
      </div>
      <CustomButton
        text={t("Show All Sessions")}
        style={{ my: 3 }}
        onClickFunction={() => {
          setShowActiveSessions(!showActiveSessions);
        }}
      />
      <ActivesSessionsModal
        userInformationData={userInformationData}
        setShowActiveSessions={setShowActiveSessions}
        showActiveSessions={showActiveSessions}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit User Password")}
        successMessage={t("Message Success Edit User Password")}
      />
    </form>
  );
}

export default LoginParameters;
