import { Routes, Route, BrowserRouter } from "react-router-dom";
import { routes } from "routing/routes";
import { map } from "lodash";
import NotFound from "pages/NotFound/NotFound";
import Login from "pages/Login/Login";
import LayoutWrapper from "layout/LayoutWrapper/LayoutWrapper";
import React from "react";
import PrivateRoute from "modules/Root/PrivateRoute";

interface Props {
  isError: boolean;
  setIsError: (isError: boolean) => void;
}

function Router({ isError, setIsError }: Props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login setIsError={setIsError} />} />
        <Route path="/" element={<LayoutWrapper isError={isError} />}>
          {map(routes, ({ path, component: Component, role }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateRoute roles={role}>
                  <Component />
                </PrivateRoute>
              }
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
