import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";
import { UserIdType } from "types/types";

const path = "/api/v1/login_logs";
const queryKey = "activeSessions";

type Response =
  operations["api_apiv1login_logs_get_collection"]["responses"]["200"]["content"]["application/ld+json"];
type UserId = UserIdType;
type Parameter = operations["api_apiv1login_logs_get_collection"]["parameters"];
type Params = {
  parameters?: Parameter;
  userId: UserId;
};

const getActiveSessions = async ({ parameters, userId }: Params) => {
  const { data } = await getApiClient().get(
    `${path}?onlyNotArchived&user.id=${userId}`,
    {
      params: parameters?.query,
    }
  );
  return data;
};

export default function useGetActiveSessionsQuery(
  params: Params,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, params],
    () => getActiveSessions(params),
    options
  );
}
