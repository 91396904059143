import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserInformationMutation from "hooks/useUserInformationMutation";
import { Controller, useForm } from "react-hook-form";
import { operations } from "schema/schema";
import { Loader } from "components/Loader/Loader";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { useTheme } from "@mui/material/styles";
import EventIcon from "@mui/icons-material/Event";
import { formatValueDatepicker, regexTextValues } from "helpers/Global";
import CustomButton from "components/Buttons/CustomButton";

interface FormInputs {
  genderValue: number;
  birthDate: string | null;
  nationality: string;
}
interface OtherDetailsTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
  refetch: () => void;
}
function OthersDetails({ userInformationData, refetch }: OtherDetailsTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isEdit, setIsEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [message, setMessage] = useState<string | null>("");
  const { mutate: changeOthersDetails, isLoading: changeOthersDetailsLoading } =
    useUserInformationMutation();
  const formParams = {
    genderValue: userInformationData?.genderValue,
    birthDate: userInformationData?.birthDate,
    nationality: userInformationData?.nationality,
  };
  const {
    control: OthersDetailsControl,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<FormInputs>({
    defaultValues: formParams,
  });

  const onSubmit = ({ genderValue, birthDate, nationality }: FormInputs) => {
    changeOthersDetails(
      {
        payload: {
          genderValue: genderValue === 3 ? 0 : genderValue,
          birthDate: birthDate ? new Date(birthDate).toLocaleString() : null,
          nationality: nationality.replace(/\s+/g, ""),
        },
        userId: userInformationData?.id,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setIsEdit(false);
          refetch();
        },
        onError: (error) => {
          setOpenSuccess(false);
          setOpenError(true);
          setIsEdit(false);
          if (error.response) {
            // @ts-ignore
            setMessage(error.response?.data["hydra:description"]);
          }
          reset();
        },
      }
    );
  };
  useEffect(() => {
    reset(formParams);
  }, [userInformationData]);
  return (
    <div>
      {changeOthersDetailsLoading && <Loader />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: onlySmallScreen ? "flex" : "inherit",
          flexDirection: "column",
        }}
      >
        <FormControl sx={{ width: onlySmallScreen ? 200 : 150, m: 2 }}>
          <InputLabel id="user-gender-selectBox-label">
            {t("Gender")}
          </InputLabel>
          <Controller
            control={OthersDetailsControl}
            name="genderValue"
            render={({ field: { value, onChange } }) => (
              <Select
                value={value === 0 ? 3 : value || " "}
                onChange={onChange}
                disabled={!isEdit}
                variant="standard"
                id="gender-select"
                labelId="user-gender-selectBox-label"
              >
                <MenuItem value={3}>{t("Undefined")}</MenuItem>
                <MenuItem value={1}>{t("Woman")}</MenuItem>
                <MenuItem value={2}>{t("Man")}</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <FormControl sx={{ width: 200, m: onlySmallScreen ? 4 : 2 }}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={t("Language Code") as string}
          >
            <Controller
              control={OthersDetailsControl}
              name="birthDate"
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  disabled={!isEdit}
                  label={t("Birth Date")}
                  inputFormat={formatValueDatepicker}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EventIcon />
                      </InputAdornment>
                    ),
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "170px", mx: 2 }} />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ width: onlySmallScreen ? 200 : 150, m: 2 }}>
          <Controller
            control={OthersDetailsControl}
            name="nationality"
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                onChange={onChange}
                variant="standard"
                type="text"
                label={t("Nationality")}
                InputLabelProps={{ shrink: true }}
                error={
                  !Boolean(regexTextValues.test(value)) &&
                  isEdit &&
                  value !== ""
                }
                helperText={
                  !Boolean(regexTextValues.test(value)) &&
                  isEdit &&
                  value !== ""
                    ? t("Invalid Value")
                    : ""
                }
                inputProps={{
                  maxLength: 15,
                }}
                InputProps={{
                  disabled: !isEdit,
                }}
              />
            )}
          />
        </FormControl>
        <Box>
          <CustomButton
            text={!isEdit ? t("Edit") : t("Cancel")}
            style={{ m: 2 }}
            onClickFunction={() => {
              setIsEdit(!isEdit);
              if (isEdit && isDirty) reset();
            }}
          />
          {isEdit && (
            <CustomButton text={t("You Save")} style={{ m: 2 }} type="submit" />
          )}
        </Box>
      </form>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={`${t("Message Error Edit User Parameters")}. ${message}`}
        successMessage={t("Message Success Edit User Parameters")}
      />
    </div>
  );
}

export default OthersDetails;
