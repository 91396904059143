import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import EditPermissionsSelect from "components/PageEditPermissions/EditPermissionsSelect/EditPermissionsSelect";
import TreeItem from "@mui/lab/TreeItem";
import useGetEditPermissionsQuery from "hooks/useGetEditPermissonsQuery";
import EditPermissionsModules from "components/PageEditPermissions/EditPermissionsTab/EditPermissionsModules";
import { Loader } from "components/Loader/Loader";
import { paths } from "schema/schema";
import { EditPermissionsTypes } from "types/types";

interface Props {
  selectedUserId: number;
  menu: EditPermissionsTypes;
  websiteData: any;
  menusLevelData: any;
  filterByLevelPermissions: string | undefined;
  filterByNamePermissionsValue: string | undefined;
  setParentUserPermissionsEnabled: (value: boolean) => void;
  setIsTreeOpen: (value: boolean) => void;
}

type RowsDataTypes =
  paths["/api/v1/user_paragraphs_permissions/{userId}"]["get"]["responses"]["200"]["content"]["application/json"];

function EditPermissionsMenus({
  selectedUserId,
  menu,
  setParentUserPermissionsEnabled,
  websiteData,
  setIsTreeOpen,
  menusLevelData,
  filterByLevelPermissions,
  filterByNamePermissionsValue,
}: Props) {
  const [menuLevelUserPermissionsEnabled, setMenuLevelUserPermissionsEnabled] =
    useState(false);
  const [positionId, setPositionId] = useState<number>();
  const [level, setLevel] = useState<string>();

  const { data: modulesLevelData, isLoading: modulesLevelDataLoading } =
    useGetEditPermissionsQuery(
      selectedUserId,
      {
        path: {
          id: selectedUserId,
        },
        query: {
          level: level,
          positionId: positionId,
          page: 1,
          itemsPerPage: 30,

          "name[value]": filterByNamePermissionsValue
            ? filterByNamePermissionsValue
            : undefined,

          "name[level]": filterByNamePermissionsValue
            ? filterByLevelPermissions
            : undefined,
        },
      },
      {
        enabled: menuLevelUserPermissionsEnabled,
        onSettled: () => {
          setMenuLevelUserPermissionsEnabled(false);
        },
      }
    );

  const getModulesLevelPermissions = (id: number, level: string) => {
    setPositionId(id);
    setLevel(level);
    setMenuLevelUserPermissionsEnabled(true);
  };

  return (
    <>
      {modulesLevelDataLoading && <Loader />}
      {menusLevelData && (
        <TreeItem
          sx={{
            padding: "10px 0",
            display: "inline-block",
            width: "calc(100% - 210px)",
          }}
          nodeId={JSON.stringify(menu.id)}
          label={menu.name}
          onClick={() =>
            menu.id ? getModulesLevelPermissions(menu.id, "MENU") : undefined
          }
        >
          {menusLevelData.totalItems! > 0 ? <> </> : undefined}

          {modulesLevelData && !modulesLevelDataLoading
            ? modulesLevelData.children?.items?.map((module: RowsDataTypes) => (
                <div
                  className={"edit-permissions-modules-level"}
                  key={module.id}
                  style={{
                    marginTop: "14px",
                    minWidth: "900px",
                  }}
                >
                  {module.name && (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <Divider
                        sx={{
                          borderColor: "rgba(58, 53, 65, 0.52)",
                          position: "absolute",
                          margin: 0,
                          right: 0,
                          top: "-5px",
                          width: "100%",
                        }}
                      />
                      <div>
                        <EditPermissionsSelect
                          id={module.id}
                          setIsTreeOpen={setIsTreeOpen}
                          setParentUserPermissionsEnabled={
                            setParentUserPermissionsEnabled
                          }
                          permission={
                            module.permission !== null
                              ? module.permission
                              : menu.permission
                              ? menu.permission
                              : websiteData.permission
                          }
                          inherit={module.permission === null}
                          selectedUserId={selectedUserId}
                          level={"MODULE"}
                        />
                        <EditPermissionsModules
                          setIsTreeOpen={setIsTreeOpen}
                          selectedUserId={selectedUserId}
                          module={module}
                          menu={menu}
                          websiteData={websiteData}
                          modulesLevelData={modulesLevelData}
                          filterByLevelPermissions={filterByLevelPermissions}
                          filterByNamePermissionsValue={
                            filterByNamePermissionsValue
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))
            : undefined}
        </TreeItem>
      )}
    </>
  );
}

export default EditPermissionsMenus;
