import { Box, CircularProgress, Dialog } from "@mui/material";
import { useSettings } from "hooks/useSettings";

export const Loader = () => {
  const { settings } = useSettings();
  return (
    <Dialog
      open={true}
      BackdropProps={{
        style: {
          backgroundColor:
            settings.mode === "light"
              ? "rgba(255,255,255,0.58)"
              : "rgba(37,34,34,0.1)",
        },
      }}
    >
      <Box className="array-modal">
        <CircularProgress color="success" />
      </Box>
    </Dialog>
  );
};
