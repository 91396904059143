import { ReactNode } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import MuiToolbar, { ToolbarProps } from "@mui/material/Toolbar";
import { Settings } from "config/settingsTheme";
import { useMediaQuery } from "@mui/material";

interface Props {
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
  AppBarContent?: (props?: any) => ReactNode;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  transition: "none",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 6),
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
  width: "100%",
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  padding: `${theme.spacing(0)} !important`,
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition:
    "padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out, background-color .25s ease-in-out",
}));

const LayoutAppBar = (props: Props) => {
  const { settings, AppBarContent: userAppBarContent } = props;
  const theme = useTheme();
  const smallerThanLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { contentWidth } = settings;
  return (
    <AppBar
      elevation={0}
      color="default"
      className="layout-navbar"
      sx={{
        backgroundColor:
          smallerThanLargeScreen && settings.mode === "light"
            ? "#F4F5FA"
            : settings.mode === "light"
            ? "unset"
            : "#28243D",
        position: smallerThanLargeScreen ? "fixed" : "static",
      }}
    >
      <Toolbar
        className="navbar-content-container"
        sx={{
          ...(contentWidth === "boxed" && {
            "@media (min-width:1440px)": {
              maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)`,
            },
          }),
        }}
      >
        {(userAppBarContent && userAppBarContent(props)) || null}
      </Toolbar>
    </AppBar>
  );
};

export default LayoutAppBar;
