import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@mui/material";
import useEditPermissionsMutation from "hooks/useEditPermissionsMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";

interface EditPermissionsSelectType {
  permission: number | null;
  id?: number;
  inherit: boolean;
  setIsTreeOpen?: (value: boolean) => void;
  isTreeOpen?: boolean;
  selectedUserId: number | undefined;
  level: string | undefined;
  isChangeInherit?: boolean | undefined;
  setParentUserPermissionsEnabled: (value: boolean) => void;
}

function EditPermissionsSelect({
  permission,
  id,
  selectedUserId,
  setParentUserPermissionsEnabled,
  inherit,
  setIsTreeOpen,
  isChangeInherit,
  level,
}: EditPermissionsSelectType) {
  const { t } = useTranslation();
  const [isInherit, setIsInherit] = useState<boolean>(false);
  const { mutate } = useEditPermissionsMutation();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openErrorNotChosenUser, setOpenErrorNotChosenUser] = useState(false);
  const [selectPermission, setSelectPermission] = useState();

  const handleChange = (event: any) => {
    setIsInherit(!isInherit);
    mutate(
      {
        payload: {
          0: {
            level: level,
            positionId: id,
            newPermission: parseInt(event.target.value),
          },
        },
        userId: selectedUserId,
      },
      {
        onSuccess: () => {
          setOpenErrorNotChosenUser(false);
          setOpenSuccess(true);
          setParentUserPermissionsEnabled(true);
        },
        onError: () => {
          setOpenError(true);
        },
      }
    );
  };

  const changeInherit = () => {
    if (isChangeInherit !== false) {
      setIsInherit(!isInherit);
      mutate(
        {
          payload: {
            0: {
              level: level,
              positionId: id,
              newPermission: null,
            },
          },
          userId: selectedUserId,
        },
        {
          onSuccess: () => {
            setOpenErrorNotChosenUser(false);
            setOpenSuccess(true);
            setParentUserPermissionsEnabled(true);
          },
          onError: () => {
            setOpenError(true);
          },
        }
      );
    }
  };

  useEffect(() => {
    setIsInherit(inherit);
  }, [inherit]);

  return (
    <>
      <FormControl
        sx={{
          width: 210,
          height: 32,
          display: "inline-block",
          marginTop: "5px",
        }}
      >
        <InputLabel
          sx={{ top: "-11px" }}
          id="edit-permissions-permissions-select-label"
        >
          {t("Edit Permissions")}
        </InputLabel>

        <Select
          value={selectPermission}
          onChange={handleChange}
          sx={{ width: 210, height: 32 }}
          label={t("Edit Permissions")}
          id="edit-permissions-permissions-select"
          labelId="edit-permissions-permissions-select-label"
          defaultValue={permission !== null ? permission : ""}
        >
          <MenuItem key={1} value={1}>
            {t("Standard")}
            {inherit && permission === 1 && <span>(D)</span>}
          </MenuItem>
          <MenuItem key={2} value={2}>
            {t("Is Hiding")}
            {inherit && permission === 2 && <span>(D)</span>}
          </MenuItem>
          <MenuItem key={3} value={3}>
            {t("Save")}
            {inherit && permission === 3 && <span>(D)</span>}
          </MenuItem>
          <MenuItem key={4} value={4}>
            {t("Save And Structure")}
            {inherit && permission === 4 && <span>(D)</span>}
          </MenuItem>
          <Checkbox key={5} checked={isInherit} onClick={changeInherit} />
          <ListItemText
            primary={t("Inherit")}
            sx={{ display: "inline-block" }}
          />
        </Select>
      </FormControl>
      <ChangeParametersModal
        setOpenSuccess={setOpenSuccess}
        RestartTreeView={setIsTreeOpen}
        setOpenError={setOpenError}
        setOpenErrorNotChosenUser={setOpenErrorNotChosenUser}
        openSuccess={openSuccess}
        openError={openError}
        openErrorNotChosenUser={openErrorNotChosenUser}
        errorMessage={t("Message Error Change Permission")}
        successMessage={t("Message Success Change Permission")}
      />
    </>
  );
}

export default EditPermissionsSelect;
