import React, { useEffect, useState } from "react";
import { Divider, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext, useFieldArray } from "react-hook-form";
import { map } from "lodash";
import Answers from "components/PageEditPolls/EditPollForm/Answers";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { useTheme } from "@mui/material/styles";
import CustomButton from "components/Buttons/CustomButton";

export default function Fields() {
  const { t } = useTranslation();
  const {
    register,
    control,
    setValue,
    formState: { isDirty },
  } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "questions",
    keyName: "_id",
  });
  const childrenRef = React.useRef({});
  const isLoading = false;
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const useStrictDroppable = (loading: boolean) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
      let animation: number;
      if (!loading) {
        animation = requestAnimationFrame(() => setEnabled(true));
      }
      return () => {
        cancelAnimationFrame(animation);
        setEnabled(false);
      };
    }, [loading]);
    return [enabled];
  };
  const reorder = (result: DropResult) => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const sourceParentId = source.droppableId;
    const destParentId = destination.droppableId;
    const sourceIndex = source.index;
    const destIndex = destination.index;
    if (type === "parentContainer") {
      move(sourceIndex, destIndex);
      const updatedFields = [...fields];
      const [removed] = updatedFields.splice(sourceIndex, 1);
      updatedFields.splice(destIndex, 0, removed);
      updatedFields.forEach((field: any, index) => {
        field.position = index + 1;
      });
      fields.map((question, index) =>
        setValue(`questions[${index}].position`, index + 1)
      );
    } else {
      // @ts-ignore
      const reorderChild = childrenRef.current[source.droppableId];
      if (reorderChild && sourceParentId === destParentId) {
        reorderChild(sourceIndex, destIndex);
      }
    }
  };
  const [enabled] = useStrictDroppable(isLoading);
  useEffect(() => {
    fields.map((question, index) =>
      setValue(`questions[${index}].position`, index + 1)
    );
  }, [isDirty, fields, reorder]);

  return (
    <>
      <DragDropContext onDragEnd={reorder}>
        {enabled && (
          <Droppable droppableId="questions" type="parentContainer">
            {(provided) => (
              <ul
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ paddingLeft: 0 }}
              >
                {map(fields, (question, questionIndex) => (
                  <Draggable
                    key={question._id}
                    draggableId={question._id}
                    index={questionIndex}
                  >
                    {(provided) => (
                      <div
                        key={question._id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="form-question-wrapper"
                      >
                        <label htmlFor={`questions[${questionIndex}].name`}>
                          {t("Question")} {questionIndex + 1}:
                        </label>
                        <input
                          className="edit-polls-textField"
                          type="text"
                          id={`questions[${questionIndex}].name`}
                          {...register(`questions[${questionIndex}].name`)}
                        />
                        <input
                          type="hidden"
                          {...register(`questions[${questionIndex}].position`)}
                          value={
                            // @ts-ignore
                            question.position
                          }
                        />
                        <CustomButton
                          text={t("Remove Question")}
                          style={{ m: 2 }}
                          size="small"
                          color="error"
                          onClickFunction={() => remove(questionIndex)}
                        />
                        <OpenWithIcon sx={{ opacity: "0.7" }} />
                        <Answers
                          childrenRef={childrenRef}
                          questionIndex={questionIndex}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        )}
      </DragDropContext>
      <Divider />
      <CustomButton
        text={t("Add Question")}
        style={{
          mx: onlySmallScreen ? 0 : 2,
          mt: 5,
          marginBottom: "150px",
          width: onlySmallScreen ? "100%" : "unset",
        }}
        size="small"
        color="success"
        onClickFunction={() =>
          append({ position: 0, id: undefined, name: "", answers: [] })
        }
      />
    </>
  );
}
