import React, { useState } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import EditPermissionsSelect from "components/PageEditPermissions/EditPermissionsSelect/EditPermissionsSelect";
import TreeItem from "@mui/lab/TreeItem";
import useGetEditPermissionsQuery from "hooks/useGetEditPermissonsQuery";
import EditPermissionsMenus from "components/PageEditPermissions/EditPermissionsTab/EditPermissionsMenus";
import { EditPermissionsTypes } from "types/types";

type Props = {
  selectedUserId: number;
  filterByLevelPermissions: string | undefined;
  filterByNamePermissionsValue: string | undefined;
  setParentUserPermissionsEnabled: (value: boolean) => void;
  setIsTreeOpen: (isTreeOpen: boolean) => void;
  isTreeOpen: boolean;
  websiteData: any;
};

const EditPermissionsWebsite = ({
  selectedUserId,
  setParentUserPermissionsEnabled,
  filterByLevelPermissions,
  filterByNamePermissionsValue,
  setIsTreeOpen,
  websiteData,
  isTreeOpen,
}: Props) => {
  const [positionId, setPositionId] = useState<number>();
  const [level, setLevel] = useState<string>();
  const [webLevelUserPermissionsEnabled, setWebLevelUserPermissionsEnabled] =
    useState(false);
  let websiteLevelData = websiteData;
  const getWebsiteLevelPermissions = (id: number, level: string) => {
    setPositionId(id);
    setLevel(level);
    setWebLevelUserPermissionsEnabled(true);
  };
  const { data: menusLevelData, isLoading: menusLevelDataLoading } =
    useGetEditPermissionsQuery(
      selectedUserId,

      {
        path: {
          id: selectedUserId,
        },
        query: {
          level: level,
          positionId: positionId,
          page: 1,
          itemsPerPage: 30,
          "name[value]": filterByNamePermissionsValue
            ? filterByNamePermissionsValue
            : undefined,

          "name[level]": filterByNamePermissionsValue
            ? filterByLevelPermissions
            : undefined,
        },
      },
      {
        enabled: webLevelUserPermissionsEnabled,
        onSettled: () => {
          setWebLevelUserPermissionsEnabled(false);
        },
      }
    );

  return (
    <TreeView
      aria-label="multi-select"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      multiSelect
      className={"edit-permissions-tree-view"}
      sx={{
        height: "unset",
        flexGrow: 1,
        marginBottom: 3,
      }}
    >
      {websiteLevelData && (
        <div className=" edit-permissions-site-level">
          <EditPermissionsSelect
            setIsTreeOpen={setIsTreeOpen}
            setParentUserPermissionsEnabled={setParentUserPermissionsEnabled}
            id={websiteData.id}
            permission={websiteData.permission}
            isChangeInherit={false}
            inherit={false}
            selectedUserId={selectedUserId}
            level={"SITE"}
          />
          <>
            <TreeItem
              nodeId={JSON.stringify(websiteLevelData.name)}
              label={websiteLevelData.name}
              className={"edit-permissions-wrapper"}
              TransitionProps={{
                in: isTreeOpen,
              }}
              sx={{
                display: "inline-block",
                width: "100%",
              }}
              onClick={() => {
                getWebsiteLevelPermissions(websiteLevelData.id, "SITE");
                setIsTreeOpen(!isTreeOpen);
              }}
            >
              {websiteLevelData.children && !menusLevelDataLoading
                ? websiteLevelData.children.items.map(
                    (menu: EditPermissionsTypes) => (
                      <div key={menu.id}>
                        {menu.name && (
                          <div
                            style={{
                              position: "relative",
                              minWidth: "900px",
                            }}
                          >
                            <Divider
                              className={"edit-permissions-divider"}
                              sx={{
                                borderColor: "rgba(58, 53, 65, 0.52)",
                                position: "absolute",
                                width: "100%",
                                margin: 0,
                                left: 0,
                                top: "-5px",
                              }}
                            />
                            <div
                              className={"edit-permissions-menu-level"}
                              style={{
                                margin: "5px 0",
                              }}
                            >
                              <EditPermissionsSelect
                                id={menu.id}
                                setParentUserPermissionsEnabled={
                                  setParentUserPermissionsEnabled
                                }
                                setIsTreeOpen={setIsTreeOpen}
                                permission={
                                  menu.permission !== null
                                    ? menu.permission
                                    : websiteData.permission
                                }
                                inherit={menu.permission === null}
                                selectedUserId={selectedUserId}
                                level={"MENU"}
                              />
                              <EditPermissionsMenus
                                setIsTreeOpen={setIsTreeOpen}
                                selectedUserId={selectedUserId}
                                menu={menu}
                                setParentUserPermissionsEnabled={
                                  setWebLevelUserPermissionsEnabled
                                }
                                websiteData={websiteData}
                                menusLevelData={menusLevelData}
                                filterByLevelPermissions={
                                  filterByLevelPermissions
                                }
                                filterByNamePermissionsValue={
                                  filterByNamePermissionsValue
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  )
                : undefined}
            </TreeItem>
          </>
        </div>
      )}
    </TreeView>
  );
};
export default EditPermissionsWebsite;
