import React, { useState } from "react";
import TreeItem from "@mui/lab/TreeItem";
import useGetEditPermissionsQuery from "hooks/useGetEditPermissonsQuery";
import { paths } from "schema/schema";
import EditPermissionsParagraphs from "./EditPermissionsParagraphs";
import { EditPermissionsTypes } from "types/types";

type ModulesLevelDataTypes =
  paths["/api/v1/user_paragraphs_permissions/{userId}"]["get"]["responses"]["200"]["content"]["application/json"];

interface Props {
  module: EditPermissionsTypes;
  menu: EditPermissionsTypes;
  modulesLevelData: ModulesLevelDataTypes;
  websiteData: any;
  selectedUserId: number;
  filterByLevelPermissions: string | undefined;
  filterByNamePermissionsValue: string | undefined;
  setIsTreeOpen: (value: boolean) => void;
}

function EditPermissionsModules({
  module,
  menu,
  modulesLevelData,
  setIsTreeOpen,
  websiteData,
  selectedUserId,
  filterByLevelPermissions,
  filterByNamePermissionsValue,
}: Props) {
  const [moduleUserPermissionsEnabled, setModuleUserPermissionsEnabled] =
    useState(false);
  const [positionId, setPositionId] = useState<number>();
  const [level, setLevel] = useState<string>();
  const {} = useGetEditPermissionsQuery(
    selectedUserId,
    {
      path: {
        id: selectedUserId,
      },
      query: {
        level: level,
        positionId: positionId,
        page: 1,

        "name[value]": filterByNamePermissionsValue
          ? filterByNamePermissionsValue
          : undefined,

        "name[level]": filterByNamePermissionsValue
          ? filterByLevelPermissions
          : undefined,
      },
    },
    {
      enabled: moduleUserPermissionsEnabled,
      onSettled: () => {
        setModuleUserPermissionsEnabled(false);
      },
    }
  );

  const getParagraphLevelPermissions = (id: number, level: string) => {
    setPositionId(id);
    // @ts-ignore
    setLevel(level);
    setModuleUserPermissionsEnabled(true);
  };

  return (
    <>
      {modulesLevelData && (
        <TreeItem
          sx={{
            padding: "5px 0",
            display: "inline-block",
          }}
          nodeId={JSON.stringify(module.id)}
          key={module.id}
          disabled={module.childrenCount === 0}
          label={module.name}
          onClick={
            module.childrenCount && module.childrenCount > 0
              ? () =>
                  module.id
                    ? getParagraphLevelPermissions(module.id, "MODULE")
                    : undefined
              : undefined
          }
        >
          <EditPermissionsParagraphs
            selectedUserId={selectedUserId}
            setIsTreeOpen={setIsTreeOpen}
            module={module}
            setParentUserPermissionsEnabled={setModuleUserPermissionsEnabled}
            menu={menu}
            websiteData={websiteData}
            filterByLevelPermissions={filterByLevelPermissions}
            filterByNamePermissionsValue={filterByNamePermissionsValue}
            positionId={positionId}
            level={level}
          />
        </TreeItem>
      )}
    </>
  );
}

export default EditPermissionsModules;
