import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { UserIdType } from "types/types";

const path = "/api/v1/users";
type UserId = UserIdType;

const putDeleteUser = async (userId: UserId) => {
  const { data } = await getApiClient().put(`${path}/${userId}/anonymize`);
  return data;
};

export default function useDeleteUserMutation(
  options: UseMutationOptions<void, AxiosError, UserId> = {}
) {
  return useMutation<void, AxiosError, UserId>(putDeleteUser, options);
}
