import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { Box, Button, Dialog, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid";
import useGetActiveSessionsQuery from "hooks/useGetActiveSessionsQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { operations } from "schema/schema";
import dayjs from "dayjs";

interface ActivesSessionsModalTypes {
  showActiveSessions: boolean;
  setShowActiveSessions: (showActiveSessions: boolean) => void;
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
}

function ActivesSessionsModal({
  showActiveSessions,
  setShowActiveSessions,
  userInformationData,
}: ActivesSessionsModalTypes) {
  const { t } = useTranslation();
  const userId = userInformationData?.id;
  const theme = useTheme();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activeSessionsEnabled, setActiveSessionsEnabled] = useState(false);
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { data: activeSessionData, isLoading: activeSessionIsLoading } =
    useGetActiveSessionsQuery(
      {
        userId,
        parameters: {
          query: {
            page: pageNumber,
            itemsPerPage: itemsPerPage,
          },
        },
      },
      {
        enabled: !!userId || activeSessionsEnabled,
        onSettled: () => {
          setActiveSessionsEnabled(false);
        },
      }
    );
  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "id",
      minWidth: 100,
      headerName: t("ID Log") as string,
    },
    {
      flex: 0.25,
      minWidth: 500,
      field: "userAgent",
      headerName: t("System") as string,
      renderCell: (params) => {
        const paymentTypeName = params.value as string;
        return (
          <p
            title={paymentTypeName}
            style={{ textAlign: "start", cursor: "pointer" }}
          >
            {paymentTypeName}
          </p>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "inet",
      headerName: t("Active Session Device IP") as string,
      renderCell: (params) => {
        const paymentTypeName = params.value as string;
        return (
          <p
            title={paymentTypeName}
            style={{ textAlign: "start", cursor: "pointer" }}
          >
            {paymentTypeName}
          </p>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "loginDateTime",
      headerName: t("Date Login") as string,
      valueFormatter: (params) =>
        dayjs(params.value).format("YYYY-MM-DD   HH:mm"),
    },
  ];
  return (
    <Dialog
      open={showActiveSessions}
      BackdropProps={{
        style: {
          backgroundColor: theme.palette.customColors.modalBgc,
        },
      }}
    >
      <Box
        className="array-modal"
        sx={{
          width: onlyMediumScreen ? "90vw" : "70vw",
          px: onlySmallScreen ? 5 : "unset",
        }}
      >
        <Button
          className="array-close-button"
          onClick={() => setShowActiveSessions(false)}
        >
          <CloseIcon />
        </Button>
        <TableBasic
          paginationMode="server"
          columns={columns}
          loading={activeSessionIsLoading}
          title={t("Actives Sessions List")}
          setItemsPerPage={setItemsPerPage}
          setPageNumber={setPageNumber}
          queryFunction={setActiveSessionsEnabled}
          rows={activeSessionData?.["hydra:member"]}
          totalItems={activeSessionData?.["hydra:totalItems"]}
        />
      </Box>
    </Dialog>
  );
}
export default ActivesSessionsModal;
