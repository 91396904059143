import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";
import { UserIdType } from "types/types";

const path = "/api/v1/users/";

type Payload = components["schemas"]["User.UserChangePasswordDto"];
type UserId = UserIdType;
type Response = components["schemas"]["User"];

type Params = {
  payload: Payload;
  userId: UserId;
};

const putChangePassword = async ({ userId, payload }: Params) => {
  const { data } = await getApiClient().put(
    `${path}${userId}/change_password`,
    payload
  );
  return data;
};

export default function useChangePasswordMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putChangePassword, options);
}
