import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";

const path = "/api/v1/newsletters/subscription";

type Payload =
  components["schemas"]["NewsletterSubscriber.NewsletterSubscriptionChangeDto.jsonld"];
type Response =
  components["schemas"]["NewsletterSubscriber.NewsletterSubscriptionChangeDto.jsonld"];

const postNewsletterSubscription = async (payload: Payload) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function usePostNewsletterSubscriptionMutation(
  options: UseMutationOptions<any, AxiosError, any> = {}
) {
  return useMutation<Response, AxiosError, Payload>(
    postNewsletterSubscription,
    options
  );
}
