import axios from "axios";
import { routes } from "routing/routes";
import { format, parseISO } from "date-fns";

export function getApiClient() {
  return axios.create({
    baseURL: "",
  });
}

export enum Role {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_SUPER_USER = "ROLE_SUPER_USER",
  ROLE_USER = "ROLE_USER",
  ROLE_REDACTOR_LEVEL = "ROLE_REDACTOR_LEVEL",
  ROLE_SII_EMPLOYEE = "ROLE_SII_EMPLOYEE",
  ROLE_PARTNER = "ROLE_PARTNER",
}

export enum CrmTypes {
  type_0 = 0,
  type_1 = 1,
}

export const logout = () => {
  axios.post("/api/logout");
  document.location.href = routes.LOGIN.path;
};

export const formattedDate = (
  date: string | null | undefined,
  formatValue: string
) =>
  date !== undefined && date !== null
    ? format(parseISO(date), formatValue)
    : undefined;

export const formatValueDatepicker = "YYYY-MM-DD";
export const formatValueDateTime = "YYYY-MM-DD HH:mm";
export const formatValueDateTimeOther = "yyyy-MM-dd HH:mm";
export const formatValueDateOther = "yyyy-MM-dd";
export const formatValuePayloadDate = "yyyy-MM-dd'T'HH:mm:ss";

export const API_PREFIX = "/api/v1";
export enum themeMode {
  light = "light",
  dark = "dark",
}

export enum Status {
  Status_0 = "0",
  Status_1 = "1",
  Status_3 = "3",
}

export const zipCodeValidation = (
  value: string | undefined,
  contactCountryCode: string | undefined,
  isEdit?: boolean
) => {
  if (contactCountryCode && contactCountryCode === "616" && isEdit) {
    return value && value.substring(2, 3) === "-" && value.length === 6;
  } else return true;
};

export const validateEmail = (email: string) => {
  const regex = /^[a-z\d]+[\w\d.-]*@(?:[a-z\d]+[a-z\d-]+\.){1,5}[a-z]{2,6}$/i;
  return regex.test(String(email).toLowerCase());
};

export const regexTextValues = new RegExp("^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ /-]+$");

export enum Patterns {
  textValues = "^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ -]+$",
  numericTextValues = "^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9/ -]+$",
  numericValues = "[0-9]*",
  numericAndSpecialSignsValues = "-?[0-9]+(-[0-9]+)*",
}

export const isPhoneEnabled = (
  phonePrefix: string,
  phoneList: string[] | undefined
) => {
  let isPhoneValidated = false;
  if (phoneList) {
    for (let i = 0; i < phoneList?.length; i++) {
      if (phonePrefix === phoneList[i]) {
        isPhoneValidated = true;
      }
    }
  }
  return isPhoneValidated;
};
export const typePhoneValidation = (value: string, prefix: string[]) => {
  let mobilePhoneIsNotValidate = false;
  if (
    value.substring(0, 2) === "48" &&
    !isPhoneEnabled(value.substring(2, 4), prefix)
  ) {
    mobilePhoneIsNotValidate = true;
  }
  return mobilePhoneIsNotValidate;
};
export const phoneLengthValidation = (value: string, minValue?: boolean) => {
  return value.substring(0, 2) === "48" ? 11 : minValue ? 5 : 15;
};
