import { Controller, useFormContext } from "react-hook-form";
import React from "react";
import TextField from "@mui/material/TextField";
import { SxProps } from "@mui/material";
import { Theme } from "@emotion/react";

type inputProps = {
  name: string;
  style: SxProps<Theme> | undefined;
  type: string;
  maxLength?: number;
};

export const InputTextFieldValue = ({
  name,
  style,
  type,
  maxLength,
}: inputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <TextField
          size="small"
          sx={style}
          value={value}
          onChange={onChange}
          variant="outlined"
          inputProps={{
            maxLength: maxLength ?? maxLength,
          }}
          onInput={(e: any) => {
            if (type === "number")
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
          }}
        />
      )}
    />
  );
};
