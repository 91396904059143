import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";
import { API_PREFIX } from "helpers/Global";
import { UserIdType } from "types/types";

const path = `${API_PREFIX}/crm_types/`;

type Payload = components["schemas"]["CrmType.jsonld"];
type Response = components["schemas"]["CrmType.jsonld"];
type id = UserIdType;

type Params = {
  payload: Payload;
  id: id;
};

const putContactType = async ({ id, payload }: Params) => {
  const { data } = await getApiClient().put(`${path}${id}`, payload);
  return data;
};

export default function usePutContactTypeMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putContactType, options);
}
