import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { Link } from "react-router-dom";
import { routes } from "routing/routes";

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw",
  },
}));

const Error404 = () => {
  const { t } = useTranslation();

  return (
    <Box className="content-center">
      <Box
        sx={{
          p: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <BoxWrapper>
          <Typography variant="h1" sx={{ mb: 2.5 }}>
            404
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mb: 2.5,
              letterSpacing: "0.18px",
              fontSize: "1.5rem !important",
            }}
          >
            {t("Page Not Found - 404")}
          </Typography>
          <Typography variant="body2">{t("We Dont Found Page")}</Typography>
        </BoxWrapper>
        <Link to={routes.HOME.path} style={{ textDecoration: "none" }}>
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{ m: 5, px: 5.5 }}
            color="primary"
          >
            {t("Back To Main Page")}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Error404;
