import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  List,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Controller, useForm, FormProvider } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import EventIcon from "@mui/icons-material/Event";
import { makeStyles } from "@mui/styles";
import FieldArray from "components/PageEditPolls/EditPollForm/Questions";
import { formatValueDateTime } from "helpers/Global";
import usePutPollMutation from "hooks/usePutPollsMutation";
import usePostPollMutation from "hooks/usePostPollsMutation";
import { PollTypes } from "types/types";
import { Loader } from "components/Loader/Loader";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { components } from "schema/schema";
import CustomButton from "components/Buttons/CustomButton";

const useStyles = makeStyles(() => ({
  sectionTitle: {
    textAlign: "center",
    marginTop: 5,
    fontWeight: "bold",
  },
  formControlStyles: {
    width: "100%",
    marginTop: 5,
    marginBottom: 5,
  },
  formControlRWDStyles: {
    width: "100%",
    marginBottom: "15px",
  },
}));

export interface FormInputs {
  poll?: PollTypes;
  pollId?: number | string | null;
  title?: string;
  name?: string;
  rightColumn: boolean;
  iniPoll: boolean;
  publicationDate: string | null;
  expirationDate: string | null;
  questions:
    | {
        id: number | undefined;
        name: string;
        position: number;
        answers: {
          answerText: string;
          id: number | undefined;
          position: number;
        }[];
      }[]
    | [];
}
export interface pollDataTypes {
  setValue: (name: any, value?: unknown, config?: Object) => void;
  pollData: components["schemas"]["Poll.jsonld-poll.extended"] | undefined;
}
export const defaultValues = {
  questions: [
    {
      id: undefined,
      position: 0,
      name: "",
      answers: [
        {
          answerText: "",
          id: undefined,
          position: 0,
        },
      ],
    },
  ],
};

function EditPollForm({ pollData, setValue }: pollDataTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const methods = useForm<FormInputs>({ defaultValues });
  const { mutate: updatePoll, isLoading: updatePollLoading } =
    usePutPollMutation();
  const { mutate: addPoll, isLoading: addPollLoading } = usePostPollMutation();
  const onSubmit = ({
    title,
    rightColumn,
    iniPoll,
    publicationDate,
    expirationDate,
    questions,
  }: FormInputs) => {
    const payloadValues = {
      name: title,
      rightColumn: rightColumn,
      iniPoll: iniPoll,
      publicationDate: publicationDate
        ? new Date(publicationDate).toLocaleString()
        : null,
      expirationDate: expirationDate
        ? new Date(expirationDate).toLocaleString()
        : null,
      questions: questions,
    };
    //@ts-ignore
    pollData && pollData !== "newPoll"
      ? updatePoll(
          {
            pollId: JSON.stringify(pollData.id),
            payload: payloadValues,
          },
          {
            onSuccess: () => {
              setOpenSuccess(true);
              setOpenError(false);
            },
            onError: () => {
              setOpenError(true);
              setOpenSuccess(false);
            },
          }
        )
      : title &&
        title?.length > 2 &&
        addPoll(
          {
            payload: payloadValues,
          },
          {
            onSuccess: () => {
              setOpenSuccess(true);
              setOpenError(false);
              methods.reset();
            },
            onError: () => {
              setOpenError(true);
              setOpenSuccess(false);
            },
          }
        );
  };
  useEffect(() => {
    methods.reset({
      title: pollData?.name || "",
      rightColumn: pollData?.rightColumn || false,
      iniPoll: pollData?.iniPoll || false,
      publicationDate: pollData?.publicationDate,
      expirationDate: pollData?.expirationDate,
      questions: pollData?.questions || [],
    });
  }, [pollData, methods.reset]);

  return (
    <div>
      {(updatePollLoading || addPollLoading) && <Loader />}
      <FormProvider {...methods}>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {!pollData && (
            <CustomButton
              text={t("Clear Form")}
              style={{
                margin: "15px auto",
                width: onlySmallScreen ? "100%" : "300px",
                backgroundColor: "transparent",
                border: "1px solid #ccc",
              }}
              size="small"
              onClickFunction={() =>
                methods.reset({
                  title: "",
                  questions: [],
                })
              }
            />
          )}
          <Typography className={classes.sectionTitle}>
            {t("Poll Definition")}:
          </Typography>
          <FormControl
            sx={{ flexDirection: "row" }}
            className={classes.formControlStyles}
          >
            <Typography
              sx={{
                width: onlySmallScreen ? "unset" : "150px",
                lineHeight: onlySmallScreen ? "inherit" : "42px",
              }}
            >
              {t("Title Poll")}
            </Typography>
            <Controller
              control={methods.control}
              name="title"
              render={({ field: { value, onChange } }) => (
                <TextField
                  size="small"
                  sx={{ ml: 1, maxWidth: "600px", width: "80%" }}
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                  type="text"
                />
              )}
            />
          </FormControl>
          <Box sx={{ display: "flex", mt: 5 }}>
            {!onlySmallScreen && (
              <Typography
                sx={{
                  width: "150px",
                  lineHeight: "42px",
                }}
              >
                {t("Right Column")}
              </Typography>
            )}
            <FormControl
              sx={{
                width: "100%",
                mx: onlySmallScreen ? 5 : 3,
                alignItems: onlyMediumScreen ? "center" : "flex-start",
              }}
            >
              <Controller
                name="rightColumn"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    label={t("Show Poll On Left Column")}
                    control={
                      <Checkbox
                        checked={!!value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    }
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", mt: 2, mb: 5 }}>
            {!onlySmallScreen && (
              <Typography
                sx={{
                  width: "150px",
                  lineHeight: "42px",
                }}
              >
                {t("INI Poll")}
              </Typography>
            )}
            <FormControl
              sx={{
                width: "100%",
                mx: onlySmallScreen ? 5 : 3,
                alignItems: onlyMediumScreen ? "center" : "flex-start",
              }}
            >
              <Controller
                name="iniPoll"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    label={t("Treat The Poll As Part Of The INI Project")}
                    control={
                      <Checkbox
                        checked={!!value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    }
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: onlySmallScreen ? "column" : "row",
              alignItems: "center",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={t("Language Code") as string}
            >
              <Typography
                sx={{
                  width: onlySmallScreen ? "100%" : "150px",
                  textAlign: "center",
                }}
              >
                {t("Date Of Publication")}
              </Typography>
              <FormControl className="form-control-datePicker">
                <Controller
                  control={methods.control}
                  name="publicationDate"
                  render={({ field: { value, onChange } }) => (
                    <DateTimePicker
                      inputFormat={formatValueDateTime}
                      label={t("From")}
                      value={value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={formatValueDateTime}
                          sx={{
                            mx: 2,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              <FormControl className="form-control-datePicker">
                <Controller
                  control={methods.control}
                  name="expirationDate"
                  render={({ field: { value, onChange } }) => (
                    <DateTimePicker
                      inputFormat={formatValueDateTime}
                      label={t("To")}
                      value={value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EventIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={onChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={formatValueDateTime}
                          sx={{
                            mx: 2,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>
          </Box>
          {!pollData && (
            <CustomButton
              text={t("Add New Poll")}
              style={{
                my: 5,
                mx: "auto",
                width: onlySmallScreen ? "100%" : "300px",
              }}
              type="submit"
              onClickFunction={() => setValue("pollId", "newPoll")}
            />
          )}
          {pollData && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
              <CustomButton
                text={t("Update Poll")}
                style={{ m: 2 }}
                type="submit"
              />
            </Box>
          )}
          <Typography sx={{ textAlign: "center", mt: 5, fontWeight: "bold" }}>
            {t("Definition Of A New Poll Question")}
          </Typography>
          <List>
            <FieldArray />
            <Divider />
          </List>
        </form>
      </FormProvider>
      <ChangeParametersModal
        openError={openError}
        setOpenError={setOpenError}
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        successMessage={t("Successful Edit Poll")}
        errorMessage={t("Error Edit Poll")}
      />
    </div>
  );
}

export default EditPollForm;
