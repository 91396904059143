import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";

const path = "https://www.sii.org.pl/aktywacja.html";

type Payload = {
  hash?: string;
};

const postActivateLink = async (payload: Payload) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function usePostActivateLinkMutation(
  options: UseMutationOptions<Response, AxiosError, Payload> = {}
) {
  return useMutation<Response, AxiosError, Payload>(postActivateLink, options);
}
