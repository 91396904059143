import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Divider,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { useSettings } from "hooks/useSettings";
import EditPollForm, {
  FormInputs,
} from "components/PageEditPolls/EditPollForm/EditPollForm";
import useGetPollsQuery from "hooks/useGetPollsQuery";
import { useForm, useWatch, Controller } from "react-hook-form";
import { Loader } from "components/Loader/Loader";
import useGetPollsExtendedQuery from "hooks/useGetPollsExtendedQuery";
import TextField from "@mui/material/TextField";
import { map, uniqBy } from "lodash";

function EditPoll() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { settings } = useSettings();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isFocusedSelect, setIsFocusedSelect] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { data: pollsData, isLoading: pollDataLoading } = useGetPollsQuery(
    {
      query: {
        pagination: false,
        name: inputValue,
      },
    },
    {
      enabled: inputValue === "" || inputValue.length > 2,
    }
  );
  const { control, setValue } = useForm<FormInputs>({
    defaultValues: {
      pollId: "newPoll",
    },
  });
  const { pollId } = useWatch({ control });
  const { data: pollData } = useGetPollsExtendedQuery(
    JSON.stringify(pollId),
    { query: {} },
    {
      enabled: !!pollId && pollId !== "newPoll",
    }
  );
  const handlePollIdChange = (selectedValue: string | null | undefined) => {
    const selectedPoll = pollsData?.["hydra:member"].find(
      (poll) => poll.name === selectedValue
    );
    const selectedPollId = selectedPoll ? selectedPoll.id : null;
    setValue("pollId", selectedPollId);
  };

  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
      }}
    >
      {pollDataLoading && <Loader />}
      <CardHeader title={t("Add Poll")} />
      <Divider />
      <Grid
        container
        spacing={6}
        item
        sx={{
          m: onlyMediumScreen ? 0 : 4,
          justifyContent: "center",
          width: "100%",
          pl: onlyMediumScreen ? 0 : "unset",
        }}
      >
        <Box
          sx={{
            m: 5,
            overflow: onlyMediumScreen ? "hidden" : "inherit",
            width: "90%",
          }}
        >
          <form>
            <Controller
              name="pollId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  noOptionsText={
                    inputValue.length < 3
                      ? t("Enter At Least 3 Characters")
                      : t("None")
                  }
                  sx={{
                    width: onlyMediumScreen ? "100%" : "700px",
                    mb: 5,
                    mt: 2,
                  }}
                  onInputChange={(_, value) => setInputValue(value)}
                  value={inputValue}
                  open={
                    (inputValue.length > 0 || inputValue === "") &&
                    isFocusedSelect
                  }
                  onFocus={() => setIsFocusedSelect(true)}
                  onClose={() => setIsFocusedSelect(false)}
                  onOpen={() => setIsFocusedSelect(true)}
                  isOptionEqualToValue={(option, value) =>
                    option?.name === value?.name
                  }
                  options={
                    map(
                      uniqBy(pollsData?.["hydra:member"], "name"),
                      (paymentType: any) => paymentType.name
                    ) || []
                  }
                  onChange={(_, selectedValue) =>
                    handlePollIdChange(selectedValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Available Polls")}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </form>
          <EditPollForm pollData={pollData} setValue={setValue} />
        </Box>
      </Grid>
    </Card>
  );
}
export default EditPoll;
