import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormInputs } from "pages/Users/Users";
import { Control, Controller } from "react-hook-form";
import useGetDepartmentsQuery from "hooks/useGetDepartmentsQuery";

type Props = { control: Control<FormInputs> };

function Departments({ control }: Props) {
  const { t } = useTranslation();

  const { data } = useGetDepartmentsQuery({
    query: { status: 0 },
  });

  return (
    <FormControl sx={{ width: "300" }}>
      <InputLabel id="departments-multiple-checkbox-label">
        {t("Department")}
      </InputLabel>
      <Controller
        control={control}
        name="usersDepartmentsValue"
        render={({ field: { value, onChange } }) => (
          <Select
            multiple
            label={t("Department")}
            value={value}
            sx={{ width: 250 }}
            onChange={onChange}
            id="multiple-checkbox"
          >
            {data?.["hydra:member"].map((department) => (
              <MenuItem key={department.id} value={department.id}>
                {department.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}

export default Departments;
