import React, { useEffect, useState } from "react";
import { Box, FormControl, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { operations } from "schema/schema";
import useUserInformationMutation from "hooks/useUserInformationMutation";
import { Loader } from "components/Loader/Loader";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { useTheme } from "@mui/material/styles";
import { landlinePhonePrefix, mobilePhonePrefix } from "constans/Constans";
import { isPhoneEnabled } from "helpers/Global";
import TextFieldValidate from "components/ValidateFields/TextFieldValidate";
import EmailFieldValidate from "components/ValidateFields/EmailFieldValidate";
import PhoneFieldValidate from "components/ValidateFields/PhoneFieldValidate";
import CustomButton from "components/Buttons/CustomButton";

export interface FormInputs {
  firstName: string;
  lastName: string;
  firstEmailAddress: string;
  secEmailAddress: string;
  mobilePhoneNumber: string;
  landlinePhoneNumber: string;
  additionalPhoneNumber: string;
}

interface ContactDetailsTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
  refetch: () => void;
}

function ContactDetails({ userInformationData, refetch }: ContactDetailsTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isEdit, setIsEdit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [additionalPhoneValidation, setAdditionalPhoneValidation] =
    useState(true);
  const {
    mutate: changeContactDetails,
    isLoading: changeContactDetailsLoading,
  } = useUserInformationMutation();
  const firstCharValidate = (value: string | undefined) => {
    return value ? (value.charAt(0) === "+" ? value.substring(1) : value) : "";
  };
  const formParams = {
    firstName: userInformationData?.firstName,
    lastName: userInformationData?.lastName,
    firstEmailAddress: userInformationData?.email,
    secEmailAddress: userInformationData?.emailSii,
    mobilePhoneNumber: firstCharValidate(userInformationData?.mobilePhone),
    landlinePhoneNumber: firstCharValidate(userInformationData?.landlinePhone),
    additionalPhoneNumber: firstCharValidate(
      userInformationData?.additionalPhone
    ),
  };
  const {
    control: ContactDetailsControl,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormInputs>({
    defaultValues: formParams,
  });
  const phoneValidation = (
    mobile: string,
    landline: string,
    additional: string,
    propsComponentValue: (value: boolean) => void
  ) => {
    propsComponentValue(true);
    let isValidate = true;
    if (
      (mobile.length < 3 &&
        additional &&
        additional.substring(0, 2) === "48" &&
        isPhoneEnabled(additional.substring(2, 4), mobilePhonePrefix) &&
        additional.length === 11) ||
      (landline.length < 3 &&
        additional &&
        additional.substring(0, 2) === "48" &&
        isPhoneEnabled(additional.substring(2, 4), landlinePhonePrefix) &&
        additional.length === 11)
    ) {
      isValidate = false;
      propsComponentValue(false);
    }
    return isValidate;
  };
  const onSubmit = ({
    firstName,
    lastName,
    firstEmailAddress,
    secEmailAddress,
    mobilePhoneNumber,
    landlinePhoneNumber,
    additionalPhoneNumber,
  }: FormInputs) => {
    phoneValidation(
      mobilePhoneNumber || "",
      landlinePhoneNumber || "",
      additionalPhoneNumber || "",
      setAdditionalPhoneValidation
    ) &&
      changeContactDetails(
        {
          payload: {
            firstName: firstName?.replace(/\s+/g, ""),
            lastName: lastName?.replace(/\s+/g, ""),
            email: firstEmailAddress?.replace(/\s+/g, ""),
            emailSii: secEmailAddress?.replace(/\s+/g, ""),
            mobilePhone:
              mobilePhoneNumber.length > 1
                ? `+${mobilePhoneNumber?.replace(/\s+/g, "")}`
                : "",
            landlinePhone:
              landlinePhoneNumber.length > 1
                ? `+${landlinePhoneNumber?.replace(/\s+/g, "")}`
                : "",
            additionalPhone:
              additionalPhoneNumber.length > 1
                ? `+${additionalPhoneNumber?.replace(/\s+/g, "")}`
                : "",
          },
          userId: userInformationData?.id,
        },
        {
          onSuccess: () => {
            setOpenSuccess(true);
            setIsEdit(false);
            refetch();
          },
          onError: () => {
            setOpenSuccess(false);
            setOpenError(true);
            setIsEdit(false);
            reset();
          },
        }
      );
  };

  useEffect(() => {
    reset(formParams);
  }, [userInformationData]);

  return (
    <Box>
      {changeContactDetailsLoading && <Loader />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: onlySmallScreen ? "flex" : "inherit",
          flexDirection: "column",
        }}
      >
        <FormControl sx={{ width: onlySmallScreen ? 200 : 150, m: 2 }}>
          <TextFieldValidate
            name={"firstName"}
            label={t("First Name")}
            control={ContactDetailsControl}
            editedValue={isEdit}
            variant="standard"
            isShrink
          />
        </FormControl>
        <FormControl sx={{ width: onlySmallScreen ? 200 : 150, m: 2 }}>
          <TextFieldValidate
            name={"lastName"}
            label={t("Last Name")}
            control={ContactDetailsControl}
            editedValue={isEdit}
            variant="standard"
            isShrink
          />
        </FormControl>
        <FormControl sx={{ width: 200, m: 2 }}>
          <EmailFieldValidate
            name="firstEmailAddress"
            label={t("Email")}
            control={ContactDetailsControl}
            editedValue={isEdit}
            variant="standard"
            isShrink
          />
        </FormControl>
        <FormControl sx={{ width: 180, m: 2 }}>
          <EmailFieldValidate
            name="secEmailAddress"
            label={t("Additional Email")}
            control={ContactDetailsControl}
            editedValue={isEdit}
            variant="standard"
            isShrink
          />
        </FormControl>
        <FormControl sx={{ width: 200, m: 2 }}>
          <PhoneFieldValidate
            control={ContactDetailsControl}
            name="mobilePhoneNumber"
            variant="standard"
            label={t("Mobile Phone Number")}
            onFocusSetState={setAdditionalPhoneValidation}
            editedValue={isEdit}
            phoneTypePrefix={mobilePhonePrefix}
            isShrink
          />
        </FormControl>
        <FormControl sx={{ width: onlySmallScreen ? 200 : 150, m: 2 }}>
          <PhoneFieldValidate
            control={ContactDetailsControl}
            name="landlinePhoneNumber"
            variant="standard"
            label={t("Landline Phone Number")}
            onFocusSetState={setAdditionalPhoneValidation}
            editedValue={isEdit}
            phoneTypePrefix={landlinePhonePrefix}
            isShrink
          />
        </FormControl>
        <FormControl sx={{ width: 200, m: 2 }}>
          <PhoneFieldValidate
            control={ContactDetailsControl}
            name="additionalPhoneNumber"
            variant="standard"
            label={t("Additional Phone Number")}
            onFocusState={additionalPhoneValidation}
            editedValue={isEdit}
            isShrink
          />
        </FormControl>
        <Box sx={{ mt: "30px" }}>
          <CustomButton
            text={!isEdit ? t("Edit") : t("Cancel")}
            style={{ m: 2 }}
            onClickFunction={() => {
              setIsEdit(!isEdit);
              if (isEdit && isDirty) reset();
            }}
          />
          {isEdit && (
            <CustomButton text={t("You Save")} style={{ m: 2 }} type="submit" />
          )}
        </Box>
      </form>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Edit User Parameters")}
        successMessage={t("Message Success Edit User Parameters")}
      />
    </Box>
  );
}

export default ContactDetails;
