import { AxiosError } from "axios";
import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { components } from "schema/schema";
import { UserIdType } from "types/types";

const path = "/api/v1/users/";

type Payload = components["schemas"]["User.jsonld"];
type Response = components["schemas"]["User"];
type UserId = UserIdType;

type Params = {
  payload: Payload;
  userId: UserId;
};

const putUserInformation = async ({ userId, payload }: Params) => {
  const { data } = await getApiClient().put(`${path}${userId}`, payload);
  return data;
};

export default function useUserInformationMutation(
  options: UseMutationOptions<Response, AxiosError, Params> = {}
) {
  return useMutation<Response, AxiosError, Params>(putUserInformation, options);
}
