import { useMutation, UseMutationOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { components } from "schema/schema";

const path = "api/v1/users/mass_profile_verification";

type Response =
  components["schemas"]["User.UsersMassProfileVerificationOutputDto.jsonld-mass_profile_verification"];
type Payload =
  components["schemas"]["User.UsersMassProfileVerificationInputDto.jsonld"];

const GetCheckingProfiles = async (payload: Payload) => {
  const { data } = await getApiClient().post(path, payload);
  return data;
};

export default function useGetCheckingProfilesQuery(
  options: UseMutationOptions<Response, AxiosError, Payload> = {}
) {
  return useMutation<Response, AxiosError, Payload>(
    GetCheckingProfiles,
    options
  );
}
