import React from "react";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import IconButton from "@mui/material/IconButton";

function FontSizeToggler() {
  const root = document.documentElement;
  const handleTextDecreaseToggle = () => {
    root?.style.setProperty("--global-font-size", "inherit");
  };
  const handleTextIncreaseToggle = () => {
    root?.style.setProperty("--global-font-size", "18px");
  };

  return (
    <div style={{ marginRight: "15px" }}>
      <IconButton
        color="inherit"
        aria-haspopup="true"
        onClick={handleTextDecreaseToggle}
      >
        <TextDecreaseIcon />
      </IconButton>
      <IconButton
        color="inherit"
        aria-haspopup="true"
        onClick={handleTextIncreaseToggle}
      >
        <TextIncreaseIcon />
      </IconButton>
    </div>
  );
}

export default FontSizeToggler;
