import AccessDenied from "pages/AccessDenied/AccessDenied";
import UseHasAccess from "hooks/useHasAccess";

interface routeTypes {
  children: JSX.Element;
  roles: string[];
}
const PrivateRoute = ({ children, roles }: routeTypes) =>
  UseHasAccess(roles).length > 0 ? children : <AccessDenied />;
export default PrivateRoute;
