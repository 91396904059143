import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import useAuthenticationUser from "hooks/useAuthenticationUser";
import useBalancesMutation from "hooks/useBalancesMutation";
import { operations } from "schema/schema";
import { Loader } from "components/Loader/Loader";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import usePaymentTypesQuery from "hooks/usePaymentTypesQuery";
import dayjs from "dayjs";
import usePostPaymentTypesMutation from "hooks/usePostPaymentTypesMutation";
import { map, uniqBy } from "lodash";
import { useQuery } from "react-query";
import { formatValueDateTime } from "helpers/Global";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "components/Buttons/CustomButton";

interface FormInputs {
  points: string;
}
interface AddPointsTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
}

function AddPointsTableSubPage({ userInformationData }: AddPointsTypes) {
  const { t } = useTranslation();
  const { data } = useAuthenticationUser();
  const userName = data?.user_fullName;
  let date = dayjs(new Date()).format(formatValueDateTime);
  const userId = String(userInformationData?.id);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { refetch } = useQuery({
    queryKey: ["totalPoints", parseInt(userId)],
  });
  const {
    mutate: postPaymentTypesMutate,
    isLoading: isChangePaymentTypesLoading,
  } = usePostPaymentTypesMutation();
  const { data: paymentTypesData } = usePaymentTypesQuery(
    {
      query: {
        name: inputValue,
      },
    },
    {
      enabled: inputValue.length > 1,
    }
  );
  const { mutate: addPointsMutate, isLoading: isChangePointsLoading } =
    useBalancesMutation();
  const validationSchema = yup.object().shape({
    points: yup.string().required(t("This Field is Required") as string),
  });
  const {
    control: AddPointsControl,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      points: undefined,
    },
    resolver: yupResolver(validationSchema),
  });
  const addPoints = (id: number | undefined, points: number) => {
    addPointsMutate(
      {
        user: `/api/v1/users/${userId}`,
        points,
        paymentType: `/api/v1/payment_types/${id}`,
        addedBy: `/api/v1/users/${data?.user_id}`,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          refetch();
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
        },
      }
    );
  };
  const onSubmit = ({ points }: FormInputs) => {
    let paymentTypeIdInt = paymentTypesData?.["hydra:member"]?.[0]?.id;
    if (paymentTypeIdInt === undefined) {
      postPaymentTypesMutate(
        {
          name: inputValue,
          type: 2,
        },
        {
          onError: () => {
            setOpenSuccess(false);
            setOpenError(true);
          },
          onSuccess: (response) => {
            addPoints(response.id, Number(points));
          },
        }
      );
    } else {
      addPoints(paymentTypeIdInt, Number(points));
    }
  };

  return (
    <Grid item xs={12}>
      <Card>
        {(isChangePointsLoading || isChangePaymentTypesLoading) && <Loader />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={{ m: 5, fontSize: "20px" }}>
            {t("Add Points")}
          </Typography>
          <Box sx={{ m: 5 }}>
            <FormControl>
              <Autocomplete
                noOptionsText={t("None")}
                sx={{ width: 450, my: 2, mx: 5 }}
                onInputChange={(_, value) => setInputValue(value)}
                options={
                  map(
                    uniqBy(paymentTypesData?.["hydra:member"], "name"),
                    (paymentType: any) => paymentType.name
                  ) || []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Paid Title / Name Of Withdrawal")}
                    variant="outlined"
                  />
                )}
                value={inputValue}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
              />
            </FormControl>
            <FormControl>
              <Controller
                control={AddPointsControl}
                name="points"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    variant="standard"
                    sx={{ width: 70, my: 2, mx: 5 }}
                    label={t("Points")}
                    type="number"
                    error={Boolean(errors?.points)}
                    helperText={errors?.points?.message}
                    inputProps={{ minlength: 1, maxLength: 9999 }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        min: 1,
                        step: 1,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </FormControl>
            <TextField
              value={userName}
              variant="standard"
              type="string"
              sx={{
                width: 200,
                my: 2,
                mx: 5,
                borderBottom: "1px solid #bebdc7",
              }}
              label={t("Introduced")}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              value={date}
              variant="standard"
              type={date}
              sx={{
                my: 2,
                mx: 5,
                width: "150px",
                borderBottom: "1px solid #bebdc7",
              }}
              label={t("Date")}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: { cursor: "default" } }}
            />
            <CustomButton
              text={t("You Save")}
              style={{ my: 2, mx: 5 }}
              type="submit"
            />
          </Box>
        </form>
        <ChangeParametersModal
          openSuccess={openSuccess}
          openError={openError}
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
          errorMessage={t("Message Error Added Points")}
          successMessage={t("Message Success Added Points")}
        />
      </Card>
    </Grid>
  );
}
export default AddPointsTableSubPage;
