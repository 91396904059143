import React from "react";
import { FormControlLabel, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import {
  JunctionProfilesType,
  TableCellCustomLong,
  TableCellCustomShort,
  TableCellCustomTitle,
} from "components/PageJunctionProfiles/JunctionsProfilesTable";
import { Controller } from "react-hook-form";
import { concat, filter, includes, map } from "lodash";
import { formattedDate, formatValueDateTimeOther } from "helpers/Global";
import CheckboxSetAllItems from "components/PageJunctionProfiles/CheckboxSetAllItems";
import useGetFormHistoryQuery from "hooks/useGetFormHistoryQuery";
import Checkbox from "@mui/material/Checkbox";

function JunctionProfilesFormDetails({
  control,
  duplicateUserData,
  mainUserData,
  resetAllGroupCheckboxes,
  setResetAllGroupCheckboxes,
  isLoading,
}: JunctionProfilesType) {
  const { t } = useTranslation();
  const { data: formValuesMainUser, isLoading: formValuesMainUserLoading } =
    useGetFormHistoryQuery({
      query: {
        user: mainUserData?.id as unknown as string,
      },
    });
  const {
    data: formValuesDuplicateUser,
    isLoading: formValuesDuplicateUserLoading,
  } = useGetFormHistoryQuery({
    query: {
      user: duplicateUserData?.id as unknown as string,
    },
  });
  isLoading = formValuesMainUserLoading || formValuesDuplicateUserLoading;
  return (
    <Controller
      control={control}
      name="formHistory"
      render={({ field: { value, onChange } }) => (
        <TableBody>
          <TableRow sx={{ position: "relative" }}>
            <TableCellCustomTitle>
              {t("Forms")}
              {(formValuesDuplicateUser?.["hydra:totalItems"] ?? 0 > 0) && (
                <CheckboxSetAllItems
                  resetAllGroupCheckboxes={resetAllGroupCheckboxes}
                  setResetAllGroupCheckboxes={setResetAllGroupCheckboxes}
                  value={formValuesDuplicateUser?.["hydra:member"]}
                  onChange={onChange}
                />
              )}
            </TableCellCustomTitle>
          </TableRow>
          {map(formValuesMainUser?.["hydra:member"], (item) => {
            return (
              <TableRow key={item.id}>
                <TableCellCustomLong />
                <TableCellCustomShort />
                <TableCellCustomLong>
                  <p>{item.text}</p>
                  <p>
                    {formattedDate(
                      item.modificationDate,
                      formatValueDateTimeOther
                    )}{" "}
                  </p>
                </TableCellCustomLong>
              </TableRow>
            );
          })}

          {map(formValuesDuplicateUser?.["hydra:member"], (item) => {
            return (
              <TableRow key={item.id}>
                <TableCellCustomLong>
                  <p>{item.text}</p>
                  <p>
                    {formattedDate(
                      item.modificationDate,
                      formatValueDateTimeOther
                    )}{" "}
                  </p>
                </TableCellCustomLong>
                <TableCellCustomShort>
                  <p>{">> >> >>"}</p>
                </TableCellCustomShort>
                <TableCellCustomLong>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={item["@id"]}
                        checked={includes(value, item["@id"])}
                        onChange={() => {
                          onChange(
                            includes(value, item["@id"])
                              ? filter(value, (val) => val !== item["@id"])
                              : concat(value, item["@id"])
                          );
                        }}
                      />
                    }
                    label={t("Get From Left")}
                  />
                </TableCellCustomLong>
              </TableRow>
            );
          })}
        </TableBody>
      )}
    />
  );
}

export default JunctionProfilesFormDetails;
