import Layout from "layout/Layout/Layout";
import { SettingsConsumer, SettingsProvider } from "config/settingsTheme";
import Modal from "components/ErrorModal/ErrorModal";
import React from "react";
import useAuthenticationUser from "hooks/useAuthenticationUser";
import { logout } from "helpers/Global";

interface LayoutWrapperType {
  isError: boolean;
}

const LayoutWrapper = ({ isError }: LayoutWrapperType) => {
  const { data, isLoading } = useAuthenticationUser();
  const amILogged = localStorage.getItem("amILogged");
  if (!amILogged) {
    logout();
  }
  return (
    <SettingsProvider>
      {isError && <Modal />}
      {!isLoading && data && (
        <SettingsConsumer>
          {() => {
            return <Layout />;
          }}
        </SettingsConsumer>
      )}
    </SettingsProvider>
  );
};
export default LayoutWrapper;
