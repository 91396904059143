import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { FormInputs } from "pages/EditPermissions/EditPermissions";
import { GridSearchIcon } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";

type Props = {
  setIsTreeOpen: (isTreeOpen: boolean) => void;
  control: Control<FormInputs>;
};

function EditPermissionsFilter({ control, setIsTreeOpen }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        marginBottom: "10px",
        textAlign: onlySmallScreen ? "center" : "unset",
        display: onlySmallScreen ? "flex" : "unset",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <FormControl sx={{ width: 200 }}>
        <Controller
          control={control}
          name="filterByNamePermissionsValue"
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              onChange={onChange}
              name="filterByNamePermissions"
              type="text"
              label={t("Search")}
            />
          )}
        />
      </FormControl>
      <Typography
        sx={{
          display: "inline-block",
          lineHeight: onlySmallScreen ? "32px" : "56px",
          mx: 5,
        }}
      >
        {t("In")}
      </Typography>
      <FormControl sx={{ width: 100 }}>
        <Controller
          control={control}
          name="filterByLevelPermissions"
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              onChange={onChange}
              id="permissions-filters-select"
            >
              <MenuItem value="MODULE">{t("Modules")}</MenuItem>
              <MenuItem value="PARAGRAPH">{t("Paragraphs")}</MenuItem>
            </Select>
          )}
        />
      </FormControl>
      <Button
        sx={{
          width: onlySmallScreen ? "200px" : 40,
          height: 40,
          ml: onlySmallScreen ? 0 : 10,
          my: onlySmallScreen ? 2 : 0,
        }}
        onClick={() => setIsTreeOpen(false)}
        size="large"
        type="submit"
        variant="contained"
        color="secondary"
      >
        <GridSearchIcon />
      </Button>
    </div>
  );
}

export default EditPermissionsFilter;
