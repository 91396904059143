import { useQuery, UseQueryOptions } from "react-query";
import { API_PREFIX, getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = `${API_PREFIX}/newsletter_subscriber_configs`;
const queryKey = "filteredNewsletterSubscriberConfig";

type Response = operations["api_apiv1newsletter_subscriber_configs_get_collection"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter =
  operations["api_apiv1newsletter_subscriber_configs_get_collection"]["parameters"];

const getFilteredNewslettersSubscriberConfig = async (
  parameters: Parameter
) => {
  const { data } = await getApiClient().get(path, {
    params: parameters?.query,
  });
  return data;
};

export default function useGetNewsletterSubscriberConfigQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getFilteredNewslettersSubscriberConfig(parameters),
    options
  );
}
