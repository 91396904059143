import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { FormInputs, LoggedState } from "pages/Users/Users";
import { useTheme } from "@mui/material/styles";

type Props = { control: Control<FormInputs> };

function UserActivity({ control }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        my: 5,
        display: onlySmallScreen ? "flex" : "unset",
        alignItems: onlySmallScreen ? "center" : "unset",
        flexDirection: onlySmallScreen ? "column" : "row",
      }}
    >
      <div style={{ display: "inline-block" }}>
        <Controller
          control={control}
          name="activityLoggedOrNotLoggedChecked"
          render={({ field: { value, onChange } }) => (
            <Checkbox checked={value} onChange={onChange} />
          )}
        />
        <Typography className="users-checkbox-text">{t("User")}</Typography>
      </div>
      <div
        style={{
          marginBottom: onlySmallScreen ? 0 : "15px",
          display: "inline-block",
        }}
      >
        <FormControl sx={{ width: 150, mb: onlySmallScreen ? "15px" : 0 }}>
          <Controller
            control={control}
            name="activityLoggedOrNotLoggedProperties"
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                onChange={onChange}
                id="activity-logged-select"
              >
                <MenuItem value={LoggedState.Logged}>{t("Logged")}</MenuItem>
                <MenuItem value={LoggedState.NotLogged}>
                  {t("Not Logged")}
                </MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <Typography
          sx={{
            display: "inline-block",
            lineHeight: "56px",
            ml: 5,
            mr: onlySmallScreen ? 0 : 5,
          }}
        >
          {t("Within")}
        </Typography>
      </div>
      <FormControl sx={{ width: 70, mt: onlySmallScreen ? "15px" : 0 }}>
        <Controller
          control={control}
          name="activityLoggedOrNotLoggedDaysField"
          render={({ field: { value, onChange } }) => (
            <TextField
              id="outlined-basic"
              value={value}
              onChange={onChange}
              name="users-register-within-number-field"
              type="number"
            />
          )}
        />
      </FormControl>
      <Typography className="users-checkbox-text">{t("Days")}</Typography>
    </Box>
  );
}

export default UserActivity;
