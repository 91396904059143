import React, { useState, MouseEvent } from "react";
import { PencilOutline } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DotsVertical from "mdi-material-ui/DotsVertical";
import DeleteOutline from "mdi-material-ui/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import TwoStepsAcceptModal from "components/TwoStepsAcceptModal/TwoStepsAcceptModal";
import usePutContactTypeMutation from "hooks/usePutContactTypeMutation";

interface ActionsButtonType {
  id: number | undefined;
  setIsEdit: (value: boolean) => void;
  setSuccessDelete: (value: boolean) => void;
}

const TableButton = ({
  id,
  setIsEdit,
  setSuccessDelete,
}: ActionsButtonType) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openTwoStepsAcceptModal, setOpenTwoStepsAcceptModal] = useState(false);
  const [openError, setOpenError] = useState(false);
  const { t } = useTranslation();
  const { mutate } = usePutContactTypeMutation();
  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    mutate(
      {
        id: id,
        payload: {
          statusValue: 2,
        },
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setSuccessDelete(true);
        },
        onError: () => {
          setOpenSuccess(false);
          setOpenError(true);
        },
      }
    );
    handleRowOptionsClose();
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <DotsVertical />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem sx={{ p: 0 }}>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setIsEdit(true);
            }}
          >
            <PencilOutline fontSize="small" sx={{ mr: 2 }} />
            {t("Edit")}
          </MenuItem>
        </MenuItem>
        <MenuItem onClick={() => setOpenTwoStepsAcceptModal(true)}>
          <DeleteOutline fontSize="small" sx={{ mr: 2 }} />
          {t("Delete")}
        </MenuItem>
      </Menu>
      <TwoStepsAcceptModal
        message={t("Do You Want To Delete Type?")}
        executeButtonName={t("Delete")}
        executeFunction={handleDelete}
        isOpen={openTwoStepsAcceptModal}
        setIsOpen={setOpenTwoStepsAcceptModal}
      />
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={t("Message Error Delete Type")}
        successMessage={t("Message Success Delete Type")}
      />
    </>
  );
};

export default TableButton;
